import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import ContentContainer from "~/components/common/ContentContainer";
import LogoFullIcon from "~/components/svgs/LogoFullIcon";
import FormInputTextField from "~/components/common/form/FormInputTextField";
import SnowThemeProvider from "~/themes/SnowThemeProvider";
import CssBaseline from "@mui/material/CssBaseline";
import { getSSOLoginRoute } from "~/api/UserApi";
import { HttpError } from "~/utils/ApiUtils";
import Loading from "~/components/common/Loading";
import Typography from "@mui/material/Typography/Typography";
import Box from "@mui/material/Box/Box";
import Button from "@mui/material/Button/Button";
import VpnKeyIcon from "@mui/icons-material/VpnKey";

interface LoginForm {
  domain: string;
}

const NOT_FOUND_ERROR_TITLE = "Organization not found";
const NOT_FOUND_ERROR_TEXT = "";

const LOGIN_ERROR_TITLE = "Login Error";
const LOGIN_ERROR_TEXT =
  "An unknown error occurred, please contact your support team for assistance";

const SSOLoginView = () => {
  const [showLoadingDialog, setShowLoadingDialog] = useState(false);
  const [showResultDialog, setShowResultDialog] = useState(false);
  const [resultTitle, setResultTitle] = useState(NOT_FOUND_ERROR_TITLE);
  const [resultText, setResultText] = useState(NOT_FOUND_ERROR_TEXT);

  const { control, handleSubmit, watch, formState, setValue } =
    useForm<LoginForm>();

  const [partialCurrentValues, setPartialCurrentValues] =
    useState<Record<string, any>>();

  useEffect(() => {
    const subscription = watch((data) => {
      setPartialCurrentValues(data);
    });

    return () => subscription.unsubscribe();
  }, [watch, setPartialCurrentValues, setValue]);

  const handleBack = async () => {
    window.location.href = "/signin";
    return;
  };

  const handleLaunch = async () => {
    const domainInput = partialCurrentValues?.domain;
    setShowLoadingDialog(true);

    let response = null;
    try {
      response = await getSSOLoginRoute(domainInput);
    } catch (e) {
      const httpError = e as HttpError;
      console.log(
        `Failed to get login route: ${httpError.statusCode} | message: ${httpError.message}`
      );

      setResultTitle(LOGIN_ERROR_TITLE);
      setResultText(LOGIN_ERROR_TEXT);
      setShowResultDialog(true);
      setShowLoadingDialog(false);
      return;
    } finally {
      setShowLoadingDialog(false);
    }

    if (response?.login_url) {
      window.location.href = response.login_url;
      return;
    }

    setResultTitle(NOT_FOUND_ERROR_TITLE);
    setResultText(
      `Sorry we were unable to find the organization associated with "${partialCurrentValues?.domain}". Please contact your administrator for assitance.`
    );
    setShowResultDialog(true);
    setShowLoadingDialog(false);
  };

  const skipLink = (
    <Typography
      sx={{
        width: "100%",
        fontSize: 14,
        mt: 4,
        color: "#7c7c7c",
      }}
    >
      Login with{" "}
      <span
        onClick={handleBack}
        style={{
          cursor: "pointer",
          color: "rgb(39, 169, 102)",
          fontWeight: 500,
          // stextDecoration: "underline",
        }}
      >
        Password
      </span>
    </Typography>
  );

  const resultDialog = (
    <Box>
      <Typography
        sx={{
          width: "100%",
          pb: 3,
          fontSize: 20,
          fontWeight: "bold",
        }}
      >
        {resultTitle}
      </Typography>
      {resultTitle === NOT_FOUND_ERROR_TITLE ? (
        <>
          <Typography
            sx={{
              width: "100%",
              pb: 2,
              fontSize: 16,
              textAlign: "left",
            }}
          >
            Sorry we were unable to find the organization associated with "
            {partialCurrentValues?.domain}"
          </Typography>
          <Typography
            sx={{
              width: "100%",
              pb: 2,
              fontSize: 16,
              textAlign: "left",
            }}
          >
            Please contact your administrator for assitance.
          </Typography>
          {skipLink}
        </>
      ) : (
        <Typography
          sx={{
            width: "100%",
            pb: 2,
            fontSize: 16,
            textAlign: "center",
          }}
        >
          {resultText}
        </Typography>
      )}
    </Box>
  );

  let formActionContent = (
    <Button
      type="submit"
      form={"login-form"}
      variant="contained"
      color="primary"
      disabled={!formState.isValid}
      sx={{
        width: "250px",
        height: 50,
      }}
    >
      Continue
    </Button>
  );

  if (showLoadingDialog) {
    formActionContent = <Loading />;
  }

  const loginForm = (
    <Box>
      <form id={"login-form"} onSubmit={handleSubmit(handleLaunch)}>
        <Typography
          sx={{
            width: "100%",
            pb: 1,
            fontSize: 16,
          }}
        >
          Enter your organization's domain to continue
        </Typography>
        <FormInputTextField
          label="Organization Domain"
          name="domain"
          type="domain"
          control={control}
          rules={{
            required: "Required",
          }}
          textfieldProps={{
            placeholder: "yourdomain.com",
          }}
          formControlProps={{
            sx: {
              width: "100%",
              mt: 2,
            },
          }}
        />
        <Box
          sx={{
            mt: 3,
          }}
        >
          {formActionContent}
        </Box>
      </form>
      {skipLink}
    </Box>
  );

  return (
    <SnowThemeProvider>
      <CssBaseline />
      <ContentContainer
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: "500px",
            maxWidth: "90%",
            flexShrink: 0,
            border: "1px solid #ededed",
            borderRadius: "15px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            backgroundColor: "#FFF",
            py: 3,
            mt: "-60px",
          }}
        >
          <Box
            sx={{
              my: 2,
            }}
          >
            <LogoFullIcon
              sx={{ width: "300px", height: "60px" }}
              aria-label={"Hippocratic AI"}
            />
          </Box>
          <Box
            sx={{
              borderTop: "1px solid #ededed",
              width: "100%",
              height: "1px",
            }}
          />
          <Box
            sx={{
              p: 2,
              pt: 4,
              width: "360px",
              maxWidth: "95%",
              textAlign: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                mb: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  pr: 1,
                  mb: 2,
                }}
              >
                <VpnKeyIcon
                  sx={{
                    // color: "#a0a0a0",
                    color: "black",
                    mt: "2px",
                    pt: "0px",
                  }}
                />
                <Typography
                  sx={{
                    pl: "8px",
                    fontWeight: 500,
                    fontSize: 20,
                    // color: "#8c8c8c",
                    color: "black",
                  }}
                >
                  SSO Login
                </Typography>
              </Box>
            </Box>
            {showResultDialog ? resultDialog : loginForm}
          </Box>
        </Box>
      </ContentContainer>
    </SnowThemeProvider>
  );
};

export default SSOLoginView;
