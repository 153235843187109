import { authenticatedFetch, getApiUrl, getRootUrl } from "~/utils/ApiUtils";
import { useQuery } from "@tanstack/react-query";
import { parseISO } from "~/utils/DateUtils";

// ============== HEALTH CHECK ==============

export const healthCheck = async () => {
  const response = await authenticatedFetch(`${getApiUrl()}/health_check`);
  const health = (await response.json()) as { status: string };
  if (!health) {
    throw new Error("Invalid response from healthCheck.");
  }
  return health;
};

// ============== GET VERSION ==============

export const getVersion = async () => {
  const response = await authenticatedFetch(`${getApiUrl()}/version`);
  const payload = (await response.json()) as {
    version: string;
  };
  if (!payload) {
    throw new Error("Invalid response from version.");
  }
  return payload;
};

export const useGetVersionQuery = (canCall: boolean) => {
  return useQuery({
    queryKey: ["version"],
    queryFn: () => {
      return getVersion();
    },
    enabled: canCall,
  });
};

// ============== PING ==============

export const ping = async () => {
  const url = `${getApiUrl()}/ping`;
  const searchParams = new URLSearchParams();
  searchParams.set("start_timestamp", new Date().toISOString());
  const urlStr = `${url}?${searchParams.toString()}`;
  const response = await authenticatedFetch(urlStr);
  const { start_timestamp } = (await response.json()) as {
    start_timestamp: string;
  };
  const endDate = new Date();
  const startDate = parseISO(start_timestamp);
  if (!startDate) {
    throw new Error("Invalid start_timestamp from ping.");
  }
  const milliseconds = endDate.getTime() - startDate.getTime();
  return {
    milliseconds,
    endDate,
    type: "api",
  } satisfies {
    milliseconds: number;
    endDate: Date;
    type: "socket" | "api";
  };
};

// ============== EXCHANGE LOGIN CODE ==============

interface ExchangeTokenType {
  code: string;
  state: string;
}

export const exchangeToken = async (params: ExchangeTokenType) => {
  const response = await fetch(`${getRootUrl()}/login_exchange_code`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(params),
  });
  const payload = (await response.json()) as {
    access_token: string;
    csrf: string;
  };
  if (!payload || !payload.access_token) {
    throw new Error("Invalid response from exchangeToken.");
  }
  return {
    accessToken: payload.access_token,
    csrf: payload.csrf,
  };
};

export const useExchangeTokenQuery = (params?: ExchangeTokenType) => {
  return useQuery({
    queryKey: ["exchangeToken"],
    queryFn: () => {
      return exchangeToken(params!);
    },
    enabled: !!params,
    retry: false,
  });
};

// ============== LOGOUT ==============

export const logout = async () => {
  const response = await authenticatedFetch(`${getApiUrl()}/logout_user`);
  if (!response.ok) {
    throw new Error("Invalid response from logout.");
  }
};
