import { isNil } from "~/utils/GeneralUtils";
import { ListFilterType } from "~/types/ApiTypes";
import { snakeCase } from "~/utils/StringUtils";
import { formatDateRangeForParams, format } from "~/utils/DateUtils";
import { DateRangeEnum } from "~/constants/DateConstants";

export const buildFilterSearchParams = <T extends ListFilterType>(
  params?: T,
  overrideFn?: (
    searchParams: URLSearchParams,
    key: string,
    value: any
  ) => boolean
) => {
  const searchParams = new URLSearchParams();
  if (!params) {
    return searchParams;
  }
  let hasDateRange = false;
  Object.entries(params).forEach(([key, value]) => {
    if (isNil(value)) {
      return;
    }
    // convert the param to snake case
    const snakeKey = snakeCase(key);
    const overrideChanged = overrideFn
      ? overrideFn(searchParams, key, value)
      : false;
    if (overrideChanged) {
      return;
    }
    // special case for date range
    // TODO: (kevin) refactor this to the date range filter
    if (key === "dateRange" || key === "startDate" || key === "endDate") {
      if (key === "dateRange") {
        hasDateRange = true;
        // special case for date range
        const { startDate, endDate } = formatDateRangeForParams(
          value as DateRangeEnum | [Date, Date]
        );
        if (startDate) {
          console.log("setting start date", startDate);
          searchParams.set("start_date", startDate);
        }
        if (endDate) {
          console.log("setting end date", endDate);
          searchParams.set("end_date", endDate);
        }
      } else if (
        !hasDateRange &&
        value instanceof Date &&
        (key === "startDate" || key === "endDate")
      ) {
        searchParams.set(snakeKey, format(value, "yyyy-MM-dd"));
      }
    } else if (Array.isArray(value)) {
      const valueStrs = value.map((entry) => {
        if (entry instanceof Date) {
          return format(entry, "yyyy-MM-dd");
        }
        return entry ? entry.toString() : "";
      });
      searchParams.set(snakeKey, valueStrs.join(","));
    } else if (value instanceof Date) {
      searchParams.set(snakeKey, format(value, "yyyy-MM-dd"));
    } else {
      searchParams.set(snakeKey, value.toString());
    }
  });
  return searchParams;
};

export const convertValueFilterToArray = (value?: any | any[] | null) => {
  if (isNil(value)) {
    return null;
  }
  if (Array.isArray(value)) {
    return value;
  }
  return [value];
};

export const encodeOrderByParam = (
  orderBy: string,
  orderDir: string = "asc"
) => {
  return `${orderBy}=${orderDir}`;
};
