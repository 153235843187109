import { createContext, useContext } from "react";
import { useGetMarketplaceProfilesQuery } from "~/api/MarketplaceApi";
import { MarketPlaceUserType } from "~/types/MarketplaceTypes";
import Loading from "~/components/common/Loading";

interface MarketplaceContextType {
  profiles: MarketPlaceUserType[];
  isLoading: boolean;
  error: Error | null;
}

export const MarketplaceContext = createContext<MarketplaceContextType>({
  profiles: [],
  isLoading: false,
  error: null,
});

export const useMarketplace = () => {
  const context = useContext(MarketplaceContext);
  if (!context) {
    throw new Error("useMarketplace must be used within a MarketplaceProvider");
  }
  return context;
};

const MarketplaceProvider = ({ children }: React.PropsWithChildren) => {
  const { data: profiles, isLoading, error } = useGetMarketplaceProfilesQuery();

  const value = {
    profiles: profiles || [],
    isLoading,
    error: error as Error | null,
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <MarketplaceContext.Provider value={value}>
      {children}
    </MarketplaceContext.Provider>
  );
};

export default MarketplaceProvider;
