import { cloneDeep } from "~/utils/GeneralUtils";
import { utcToZonedTime, getTimeZoneValue } from "~/utils/DateUtils";

export const AUTH_TOKEN_KEY = "AccessToken";
export const AUTH_CSRF_KEY = "CsrfToken";
export const LOGIN_URL_KEY = "LoginURL";
const ROOT_URL = import.meta.env.VITE_ROOT_URL ?? "";
const ROOT_URL_API = ROOT_URL + API_URL;
const CALL_ROOT_URL = import.meta.env.VITE_CALL_SERVER_ROOT_URL ?? ROOT_URL;
const CALL_URL_API = CALL_ROOT_URL + API_URL;

export const UNSET = "UNSET";

export const getRootUrl = (): string => {
  return ROOT_URL;
};

export const getApiUrl = (): string => {
  return ROOT_URL_API;
};

export const getCallRootUrl = (): string => {
  return CALL_ROOT_URL;
};

export const getCallApiUrl = (): string => {
  return CALL_URL_API;
};

export const convertISODateParamsToDates = (
  object: Record<string, any>,
  params: string[]
) => {
  const clone = cloneDeep(object);
  params.forEach((param) => {
    if (clone[param]) {
      clone[param] = utcToZonedTime(clone[param], getTimeZoneValue());
    }
  });
  return clone;
};

export const getAccessToken = (): string | null => {
  return localStorage.getItem(AUTH_TOKEN_KEY);
};

export const getCSRFToken = (): string | null => {
  return localStorage.getItem(AUTH_CSRF_KEY);
};

export const authenticatedFetch = async (
  url: string,
  options?: RequestInit,
  isMultiPart?: boolean
): Promise<Response> => {
  const token = localStorage.getItem(AUTH_TOKEN_KEY);
  if (!token) {
    console.warn("No token found in localStorage.");
    return Promise.reject(new HttpError(401, "Unauthorized"));
  }
  const headers: HeadersInit = {
    Authorization: `Bearer ${token}`,
    "X-CSRF-Token": localStorage.getItem(AUTH_CSRF_KEY) ?? "",
  };

  if (isMultiPart !== true) {
    headers["Content-Type"] = "application/json";
  }

  try {
    const response = await fetch(url, {
      credentials: "include",
      ...options,
      headers,
    });
    if (!response.ok) {
      if (response.status === 401) {
        localStorage.removeItem(AUTH_TOKEN_KEY);
        return Promise.reject(new HttpError(401, "Unauthorized"));
      } else {
        let message = await response.text();
        const error = new HttpError(
          response.status,
          message ?? response.statusText
        );
        console.error("Error in authenticatedFetch", error);
        throw error;
      }
    }
    return response;
  } catch (e) {
    console.error("Error in authenticatedFetch", e);
    throw e;
  }
};

export const getExistingPath = (withBaseName?: boolean) => {
  const location = window.location.pathname;
  if (withBaseName) {
    return location;
  }
  const locationWithoutBaseName = window.location.pathname.replace(
    BASENAME,
    ""
  );
  return locationWithoutBaseName;
};

export class HttpError extends Error {
  _status: number;
  constructor(status: number, message: string) {
    super(message);
    this._status = status;
  }
  get statusCode() {
    return this._status;
  }
}

export const parseFilenameFromS3Key = (s3Key?: string | null) => {
  if (!s3Key) {
    return "";
  }

  const paths = s3Key.split("/");
  return paths[paths.length - 1];
};

export const parseLocationHashParams = (
  locationVal: string
): Record<string, string> => {
  let hashVal = locationVal;
  if (hashVal.charAt(0) === "#") {
    hashVal = locationVal.substring(1);
  }
  const keyPairs = hashVal.split("&");
  const args: Record<string, string> = {};
  keyPairs.forEach((keyPair) => {
    const components = keyPair.split("=");
    if (components.length < 2) {
      return;
    }
    args[components[0]] = components[1];
  });
  return args;
};
