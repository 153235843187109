import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { MarketPlaceUserType } from "~/types/MarketplaceTypes";
import { authenticatedFetch, getApiUrl } from "~/utils/ApiUtils";

// ============== GET MARKETPLACE PROFILES ==============

interface MarketplaceProfileResponse {
  profiles: MarketPlaceUserType[];
  total_count: number;
}

export const getMarketplaceProfiles = async (): Promise<
  MarketPlaceUserType[]
> => {
  const url = `${getApiUrl()}/static/marketplace-profiles`;
  const response = await authenticatedFetch(url, {
    method: "GET",
  });
  const profiles = (await response.json()) as MarketplaceProfileResponse;
  if (!profiles?.profiles) {
    throw new Error("Invalid response from getMarketplaceProfiles.");
  }
  return profiles?.profiles;
};

// React Query hook for fetching marketplace profiles
export const useGetMarketplaceProfilesQuery = (
  options?: UseQueryOptions<MarketPlaceUserType[]>
) => {
  return useQuery({
    queryKey: ["marketplaceProfiles"],
    queryFn: () => {
      return getMarketplaceProfiles();
    },
    ...options,
  });
};
