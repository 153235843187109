import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const LogoFullIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="1008"
        height="300"
        viewBox="0 0 1008 300"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M440 227.561H342V223.561H440V227.561Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M998 227.561H900V223.561H998V227.561Z"
          fill="black"
        />
        <path
          d="M385.079 81.4036V150.297H376.084V119.063H340.995V150.297H332V81.4036H340.995V111.65H376.084V81.4036H385.079Z"
          fill="black"
        />
        <path
          d="M405.083 87.3342C403.37 87.3342 401.92 86.7411 400.734 85.555C399.548 84.3689 398.955 82.9192 398.955 81.2059C398.955 79.4926 399.548 78.0429 400.734 76.8568C401.92 75.6707 403.37 75.0776 405.083 75.0776C406.73 75.0776 408.114 75.6707 409.234 76.8568C410.42 78.0429 411.013 79.4926 411.013 81.2059C411.013 82.9192 410.42 84.3689 409.234 85.555C408.114 86.7411 406.73 87.3342 405.083 87.3342ZM409.432 96.1312V150.297H400.437V96.1312H409.432Z"
          fill="black"
        />
        <path
          d="M433.757 106.114C435.536 103.017 438.172 100.447 441.664 98.4046C445.222 96.2959 449.341 95.2416 454.019 95.2416C458.83 95.2416 463.179 96.3948 467.067 98.7011C471.02 101.007 474.117 104.269 476.358 108.487C478.598 112.638 479.719 117.481 479.719 123.016C479.719 128.486 478.598 133.362 476.358 137.645C474.117 141.928 471.02 145.256 467.067 147.628C463.179 150.001 458.83 151.187 454.019 151.187C449.407 151.187 445.321 150.165 441.763 148.123C438.27 146.014 435.602 143.411 433.757 140.314V175.996H424.762V96.1312H433.757V106.114ZM470.526 123.016C470.526 118.931 469.702 115.373 468.055 112.341C466.408 109.31 464.167 107.004 461.334 105.422C458.566 103.841 455.502 103.05 452.141 103.05C448.847 103.05 445.782 103.874 442.949 105.521C440.181 107.103 437.941 109.442 436.228 112.539C434.58 115.57 433.757 119.096 433.757 123.115C433.757 127.201 434.58 130.792 436.228 133.889C437.941 136.92 440.181 139.26 442.949 140.907C445.782 142.489 448.847 143.279 452.141 143.279C455.502 143.279 458.566 142.489 461.334 140.907C464.167 139.26 466.408 136.92 468.055 133.889C469.702 130.792 470.526 127.168 470.526 123.016Z"
          fill="black"
        />
        <path
          d="M500.553 106.114C502.332 103.017 504.968 100.447 508.46 98.4046C512.019 96.2959 516.137 95.2416 520.816 95.2416C525.626 95.2416 529.975 96.3948 533.863 98.7011C537.817 101.007 540.914 104.269 543.154 108.487C545.395 112.638 546.515 117.481 546.515 123.016C546.515 128.486 545.395 133.362 543.154 137.645C540.914 141.928 537.817 145.256 533.863 147.628C529.975 150.001 525.626 151.187 520.816 151.187C516.203 151.187 512.117 150.165 508.559 148.123C505.067 146.014 502.398 143.411 500.553 140.314V175.996H491.558V96.1312H500.553V106.114ZM537.322 123.016C537.322 118.931 536.499 115.373 534.851 112.341C533.204 109.31 530.963 107.004 528.13 105.422C525.362 103.841 522.298 103.05 518.938 103.05C515.643 103.05 512.579 103.874 509.745 105.521C506.978 107.103 504.737 109.442 503.024 112.539C501.376 115.57 500.553 119.096 500.553 123.115C500.553 127.201 501.376 130.792 503.024 133.889C504.737 136.92 506.978 139.26 509.745 140.907C512.579 142.489 515.643 143.279 518.938 143.279C522.298 143.279 525.362 142.489 528.13 140.907C530.963 139.26 533.204 136.92 534.851 133.889C536.499 130.792 537.322 127.168 537.322 123.016Z"
          fill="black"
        />
        <path
          d="M581.978 151.187C576.904 151.187 572.291 150.034 568.14 147.727C564.054 145.421 560.825 142.159 558.453 137.942C556.147 133.659 554.994 128.716 554.994 123.115C554.994 117.58 556.18 112.704 558.552 108.487C560.99 104.203 564.285 100.942 568.436 98.7011C572.588 96.3948 577.233 95.2416 582.373 95.2416C587.513 95.2416 592.159 96.3948 596.31 98.7011C600.461 100.942 603.723 104.17 606.095 108.388C608.534 112.605 609.753 117.514 609.753 123.115C609.753 128.716 608.501 133.659 605.997 137.942C603.558 142.159 600.231 145.421 596.013 147.727C591.796 150.034 587.118 151.187 581.978 151.187ZM581.978 143.279C585.207 143.279 588.238 142.522 591.071 141.006C593.905 139.49 596.178 137.217 597.891 134.186C599.671 131.155 600.56 127.464 600.56 123.115C600.56 118.766 599.704 115.076 597.99 112.045C596.277 109.014 594.037 106.773 591.269 105.324C588.501 103.808 585.503 103.05 582.274 103.05C578.98 103.05 575.948 103.808 573.181 105.324C570.479 106.773 568.304 109.014 566.657 112.045C565.01 115.076 564.186 118.766 564.186 123.115C564.186 127.53 564.977 131.253 566.558 134.285C568.206 137.316 570.38 139.589 573.082 141.105C575.784 142.554 578.749 143.279 581.978 143.279Z"
          fill="black"
        />
        <path
          d="M618.218 123.115C618.218 117.514 619.339 112.638 621.579 108.487C623.819 104.269 626.917 101.007 630.87 98.7011C634.89 96.3948 639.47 95.2416 644.609 95.2416C651.265 95.2416 656.734 96.856 661.017 100.085C665.366 103.314 668.233 107.795 669.617 113.528H659.93C659.008 110.233 657.195 107.63 654.494 105.719C651.858 103.808 648.563 102.853 644.609 102.853C639.47 102.853 635.318 104.632 632.155 108.19C628.992 111.682 627.411 116.658 627.411 123.115C627.411 129.639 628.992 134.68 632.155 138.238C635.318 141.797 639.47 143.576 644.609 143.576C648.563 143.576 651.858 142.653 654.494 140.808C657.13 138.963 658.942 136.327 659.93 132.901H669.617C668.167 138.436 665.268 142.884 660.919 146.245C656.569 149.539 651.133 151.187 644.609 151.187C639.47 151.187 634.89 150.034 630.87 147.727C626.917 145.421 623.819 142.159 621.579 137.942C619.339 133.724 618.218 128.782 618.218 123.115Z"
          fill="black"
        />
        <path
          d="M690.613 104.928C692.195 101.831 694.435 99.426 697.334 97.7127C700.3 95.9994 703.891 95.1428 708.108 95.1428V104.434H705.736C695.654 104.434 690.613 109.903 690.613 120.842V150.297H681.618V96.1312H690.613V104.928Z"
          fill="black"
        />
        <path
          d="M715.131 123.016C715.131 117.481 716.251 112.638 718.491 108.487C720.732 104.269 723.796 101.007 727.684 98.7011C731.638 96.3948 736.02 95.2416 740.83 95.2416C745.574 95.2416 749.693 96.263 753.185 98.3057C756.678 100.348 759.281 102.918 760.994 106.015V96.1312H770.088V150.297H760.994V140.215C759.215 143.378 756.546 146.014 752.988 148.123C749.495 150.165 745.41 151.187 740.731 151.187C735.921 151.187 731.572 150.001 727.684 147.628C723.796 145.256 720.732 141.928 718.491 137.645C716.251 133.362 715.131 128.486 715.131 123.016ZM760.994 123.115C760.994 119.03 760.17 115.471 758.523 112.44C756.875 109.409 754.635 107.103 751.802 105.521C749.034 103.874 745.97 103.05 742.609 103.05C739.248 103.05 736.184 103.841 733.417 105.422C730.649 107.004 728.442 109.31 726.794 112.341C725.147 115.373 724.323 118.931 724.323 123.016C724.323 127.168 725.147 130.792 726.794 133.889C728.442 136.92 730.649 139.26 733.417 140.907C736.184 142.489 739.248 143.279 742.609 143.279C745.97 143.279 749.034 142.489 751.802 140.907C754.635 139.26 756.875 136.92 758.523 133.889C760.17 130.792 760.994 127.201 760.994 123.115Z"
          fill="black"
        />
        <path
          d="M796.259 103.544V135.471C796.259 138.107 796.819 139.985 797.94 141.105C799.06 142.159 801.004 142.686 803.771 142.686H810.394V150.297H802.289C797.281 150.297 793.525 149.144 791.021 146.838C788.517 144.531 787.265 140.742 787.265 135.471V103.544H780.247V96.1312H787.265V82.4909H796.259V96.1312H810.394V103.544H796.259Z"
          fill="black"
        />
        <path
          d="M825.938 87.3342C824.224 87.3342 822.775 86.7411 821.589 85.555C820.402 84.3689 819.809 82.9192 819.809 81.2059C819.809 79.4926 820.402 78.0429 821.589 76.8568C822.775 75.6707 824.224 75.0776 825.938 75.0776C827.585 75.0776 828.969 75.6707 830.089 76.8568C831.275 78.0429 831.868 79.4926 831.868 81.2059C831.868 82.9192 831.275 84.3689 830.089 85.555C828.969 86.7411 827.585 87.3342 825.938 87.3342ZM830.287 96.1312V150.297H821.292V96.1312H830.287Z"
          fill="black"
        />
        <path
          d="M842.256 123.115C842.256 117.514 843.376 112.638 845.617 108.487C847.857 104.269 850.954 101.007 854.908 98.7011C858.927 96.3948 863.507 95.2416 868.647 95.2416C875.302 95.2416 880.772 96.856 885.055 100.085C889.404 103.314 892.271 107.795 893.654 113.528H883.968C883.045 110.233 881.233 107.63 878.531 105.719C875.896 103.808 872.601 102.853 868.647 102.853C863.507 102.853 859.356 104.632 856.193 108.19C853.03 111.682 851.448 116.658 851.448 123.115C851.448 129.639 853.03 134.68 856.193 138.238C859.356 141.797 863.507 143.576 868.647 143.576C872.601 143.576 875.896 142.653 878.531 140.808C881.167 138.963 882.979 136.327 883.968 132.901H893.654C892.205 138.436 889.305 142.884 884.956 146.245C880.607 149.539 875.171 151.187 868.647 151.187C863.507 151.187 858.927 150.034 854.908 147.727C850.954 145.421 847.857 142.159 845.617 137.942C843.376 133.724 842.256 128.782 842.256 123.115Z"
          fill="black"
        />
        <path
          d="M972.731 134.976H942.683L937.148 150.297H927.659L952.567 81.799H962.946L987.755 150.297H978.266L972.731 134.976ZM970.161 127.662L957.707 92.8694L945.253 127.662H970.161Z"
          fill="black"
        />
        <path
          d="M1007.61 81.4036V150.297H998.611V81.4036H1007.61Z"
          fill="black"
        />
        <path
          d="M485.746 201.629C490.911 201.629 495.373 202.603 499.133 204.551C502.939 206.454 505.838 209.195 507.831 212.774C509.87 216.353 510.889 220.566 510.889 225.413C510.889 230.261 509.87 234.474 507.831 238.053C505.838 241.586 502.939 244.305 499.133 246.207C495.373 248.065 490.911 248.993 485.746 248.993H471V201.629H485.746ZM485.746 243.897C491.862 243.897 496.528 242.289 499.745 239.072C502.961 235.81 504.57 231.257 504.57 225.413C504.57 219.524 502.939 214.926 499.677 211.618C496.46 208.311 491.817 206.658 485.746 206.658H477.184V243.897H485.746Z"
          fill="black"
        />
        <path
          d="M535.287 249.605C531.799 249.605 528.628 248.812 525.773 247.227C522.965 245.641 520.745 243.399 519.114 240.499C517.528 237.554 516.736 234.157 516.736 230.306C516.736 226.5 517.551 223.148 519.182 220.249C520.858 217.304 523.123 215.061 525.977 213.521C528.831 211.936 532.025 211.143 535.559 211.143C539.093 211.143 542.286 211.936 545.141 213.521C547.995 215.061 550.237 217.281 551.868 220.181C553.544 223.08 554.382 226.455 554.382 230.306C554.382 234.157 553.522 237.554 551.8 240.499C550.124 243.399 547.836 245.641 544.937 247.227C542.037 248.812 538.821 249.605 535.287 249.605ZM535.287 244.169C537.507 244.169 539.591 243.648 541.539 242.606C543.487 241.564 545.05 240.001 546.228 237.917C547.451 235.833 548.063 233.296 548.063 230.306C548.063 227.316 547.474 224.779 546.296 222.695C545.118 220.611 543.578 219.071 541.675 218.074C539.772 217.032 537.711 216.511 535.491 216.511C533.226 216.511 531.142 217.032 529.239 218.074C527.382 219.071 525.887 220.611 524.754 222.695C523.622 224.779 523.055 227.316 523.055 230.306C523.055 233.341 523.599 235.901 524.686 237.985C525.819 240.069 527.314 241.632 529.171 242.674C531.029 243.67 533.067 244.169 535.287 244.169Z"
          fill="black"
        />
        <path
          d="M617.869 248.993H611.685L586.814 211.279V248.993H580.63V201.561H586.814L611.685 239.208V201.561H617.869V248.993Z"
          fill="black"
        />
        <path
          d="M644.651 249.605C641.163 249.605 637.992 248.812 635.138 247.227C632.329 245.641 630.109 243.399 628.478 240.499C626.893 237.554 626.1 234.157 626.1 230.306C626.1 226.5 626.915 223.148 628.546 220.249C630.222 217.304 632.488 215.061 635.342 213.521C638.196 211.936 641.39 211.143 644.923 211.143C648.457 211.143 651.651 211.936 654.505 213.521C657.359 215.061 659.601 217.281 661.232 220.181C662.909 223.08 663.747 226.455 663.747 230.306C663.747 234.157 662.886 237.554 661.164 240.499C659.488 243.399 657.2 245.641 654.301 247.227C651.402 248.812 648.185 249.605 644.651 249.605ZM644.651 244.169C646.871 244.169 648.955 243.648 650.903 242.606C652.851 241.564 654.414 240.001 655.592 237.917C656.815 235.833 657.427 233.296 657.427 230.306C657.427 227.316 656.838 224.779 655.66 222.695C654.482 220.611 652.942 219.071 651.039 218.074C649.136 217.032 647.075 216.511 644.855 216.511C642.59 216.511 640.506 217.032 638.604 218.074C636.746 219.071 635.251 220.611 634.119 222.695C632.986 224.779 632.42 227.316 632.42 230.306C632.42 233.341 632.963 235.901 634.051 237.985C635.183 240.069 636.678 241.632 638.536 242.674C640.393 243.67 642.432 244.169 644.651 244.169Z"
          fill="black"
        />
        <path
          d="M726.486 201.629V248.993H720.302V227.52H696.178V248.993H689.994V201.629H696.178V222.423H720.302V201.629H726.486Z"
          fill="black"
        />
        <path
          d="M734.734 230.238C734.734 226.433 735.504 223.103 737.045 220.249C738.585 217.349 740.692 215.107 743.365 213.521C746.083 211.936 749.095 211.143 752.402 211.143C755.664 211.143 758.496 211.845 760.897 213.249C763.298 214.654 765.087 216.421 766.265 218.55V211.754H772.517V248.993H766.265V242.062C765.042 244.237 763.207 246.049 760.761 247.498C758.36 248.903 755.551 249.605 752.335 249.605C749.027 249.605 746.037 248.79 743.365 247.159C740.692 245.528 738.585 243.24 737.045 240.295C735.504 237.351 734.734 233.998 734.734 230.238ZM766.265 230.306C766.265 227.497 765.699 225.051 764.566 222.967C763.434 220.883 761.893 219.297 759.945 218.21C758.043 217.077 755.936 216.511 753.626 216.511C751.315 216.511 749.209 217.055 747.306 218.142C745.403 219.229 743.885 220.815 742.753 222.899C741.62 224.983 741.054 227.429 741.054 230.238C741.054 233.092 741.62 235.584 742.753 237.713C743.885 239.797 745.403 241.405 747.306 242.538C749.209 243.625 751.315 244.169 753.626 244.169C755.936 244.169 758.043 243.625 759.945 242.538C761.893 241.405 763.434 239.797 764.566 237.713C765.699 235.584 766.265 233.115 766.265 230.306Z"
          fill="black"
        />
        <path
          d="M789.151 217.802C790.238 215.673 791.779 214.019 793.772 212.842C795.811 211.664 798.28 211.075 801.179 211.075V217.463H799.548C792.617 217.463 789.151 221.223 789.151 228.743V248.993H782.967V211.754H789.151V217.802Z"
          fill="black"
        />
        <path
          d="M853.099 211.075C855.999 211.075 858.581 211.686 860.846 212.91C863.111 214.087 864.901 215.877 866.215 218.278C867.528 220.679 868.185 223.601 868.185 227.044V248.993H862.069V227.928C862.069 224.213 861.141 221.381 859.283 219.433C857.471 217.44 855.002 216.443 851.876 216.443C848.66 216.443 846.1 217.485 844.197 219.569C842.295 221.608 841.343 224.575 841.343 228.471V248.993H835.227V227.928C835.227 224.213 834.299 221.381 832.441 219.433C830.629 217.44 828.16 216.443 825.034 216.443C821.818 216.443 819.258 217.485 817.355 219.569C815.453 221.608 814.501 224.575 814.501 228.471V248.993H808.317V211.754H814.501V217.123C815.724 215.175 817.355 213.68 819.394 212.638C821.478 211.596 823.766 211.075 826.257 211.075C829.383 211.075 832.147 211.777 834.548 213.181C836.949 214.586 838.738 216.647 839.916 219.365C840.958 216.738 842.68 214.699 845.081 213.249C847.482 211.8 850.155 211.075 853.099 211.075Z"
          fill="black"
        />
        <path
          d="M71.99 254.05C69.04 254.05 66.16 254.21 63.34 254.44C60.45 254.68 57.62 255.02 54.88 255.35L57.38 257C59.39 256.76 61.44 256.54 63.52 256.37C66.29 256.14 69.12 255.98 71.99 255.98C78.64 255.98 85.55 256.8 92.68 259.29C99.31 261.61 103.74 264.82 106.92 268.64C108.34 269.94 109.57 271.33 110.65 272.8C110.46 272.37 110.26 271.94 110.06 271.51C108.72 268.72 107.07 266.11 104.86 263.76C101.9 261.28 98.18 259.15 93.39 257.47C85.98 254.87 78.82 254.03 71.99 254.03"
          fill="url(#paint0_linear_573_35549)"
        />
        <path
          d="M73.06 258.06C70.11 258.06 67.22 258.22 64.41 258.45C62.99 258.57 61.59 258.71 60.2 258.86L62.75 260.54C63.36 260.48 63.97 260.42 64.58 260.37C67.35 260.14 70.18 259.98 73.05 259.98C79.7 259.98 86.61 260.8 93.74 263.29C98.76 265.05 102.52 267.32 105.43 269.97C108.33 272.13 110.57 274.59 112.36 277.27C111.84 275.73 111.27 274.23 110.64 272.78C109.56 271.32 108.33 269.93 106.91 268.62C103.8 265.77 99.79 263.33 94.45 261.46C87.04 258.86 79.88 258.03 73.05 258.03"
          fill="url(#paint1_linear_573_35549)"
        />
        <path
          d="M73.94 261.35C70.99 261.35 68.11 261.51 65.29 261.74C65.08 261.76 64.86 261.78 64.65 261.8L67.28 263.54C69.47 263.39 71.69 263.29 73.94 263.29C80.59 263.29 87.5 264.11 94.63 266.6C101.29 268.93 105.74 272.17 108.92 276.01C110.87 277.81 112.47 279.78 113.8 281.88C113.34 280.32 112.88 278.79 112.37 277.3C110.58 274.62 108.35 272.16 105.44 270C102.72 267.98 99.41 266.22 95.34 264.79C87.93 262.19 80.76 261.35 73.94 261.35Z"
          fill="url(#paint2_linear_573_35549)"
        />
        <path
          d="M75 265.37C73.4 265.37 71.81 265.42 70.25 265.5L73.03 267.34C73.68 267.32 74.34 267.32 75 267.32C81.65 267.32 88.56 268.14 95.69 270.63C101.39 272.63 105.47 275.28 108.52 278.42C111.17 280.58 113.22 283.02 114.88 285.66C114.81 285.41 114.74 285.17 114.67 284.93C114.43 284.08 114.19 283.24 113.95 282.41C113.9 282.24 113.85 282.07 113.8 281.9C112.46 279.8 110.87 277.83 108.92 276.03C105.8 273.15 101.77 270.7 96.4 268.82C88.99 266.22 81.83 265.38 75 265.38"
          fill="url(#paint3_linear_573_35549)"
        />
        <path
          d="M75.95 268.93C75.79 268.93 75.62 268.93 75.46 268.93L78.46 270.91C84.33 271.1 90.4 272 96.64 274.18C103.27 276.5 107.7 279.71 110.88 283.53C113.02 285.49 114.73 287.66 116.15 289.97C115.71 288.52 115.29 287.07 114.89 285.65C113.24 283.01 111.19 280.57 108.53 278.41C105.62 276.03 101.98 273.99 97.36 272.37C89.95 269.77 82.79 268.94 75.96 268.94"
          fill="url(#paint4_linear_573_35549)"
        />
        <path
          d="M81.76 273.08L85.17 275.33C89.27 275.81 93.45 276.7 97.72 278.19C102.77 279.96 106.55 282.25 109.46 284.92C112.97 287.55 115.51 290.61 117.45 293.98C116.98 292.64 116.55 291.3 116.14 289.96C114.73 287.65 113.02 285.48 110.87 283.52C107.76 280.67 103.75 278.23 98.41 276.37C92.69 274.37 87.13 273.41 81.74 273.08"
          fill="url(#paint5_linear_573_35549)"
        />
        <path
          d="M87.48 276.86L91.52 279.52C93.85 280.01 96.21 280.66 98.6 281.49C105.24 283.82 109.68 287.04 112.86 290.87C114.85 292.7 116.46 294.7 117.81 296.83C118.11 297 118.41 297.17 118.71 297.32C118.29 296.29 117.9 295.27 117.54 294.24L117.45 293.98C115.5 290.61 112.97 287.54 109.46 284.92C106.73 282.88 103.41 281.11 99.31 279.67C95.29 278.26 91.34 277.37 87.48 276.85"
          fill="url(#paint6_linear_573_35549)"
        />
        <path
          d="M95.67 282.26L101.86 286.35C106.52 288.27 109.98 290.68 112.64 293.46L116.85 296.24C117.16 296.45 117.48 296.65 117.8 296.84C116.45 294.71 114.83 292.71 112.85 290.88C109.74 288.02 105.71 285.57 100.36 283.69C98.78 283.14 97.22 282.66 95.66 282.26"
          fill="url(#paint7_linear_573_35549)"
        />
        <path
          d="M114.93 274.96C116.74 279.28 119.11 283.53 122.61 287.53C123.9 288.77 125.31 289.98 126.85 291.16C129.38 293.09 132.28 294.93 135.61 296.65C135.81 296.52 136.02 296.39 136.22 296.26H136.24L137.53 295.42C133.9 293.62 130.8 291.68 128.14 289.65C126.26 288.22 124.6 286.74 123.11 285.22C119.61 281.96 116.99 278.5 114.93 274.96ZM15.35 223.48C16.27 224.69 17.23 225.87 18.23 227.04C21.89 228.42 25.8 228.93 29.91 228.95C30.01 228.95 30.11 228.95 30.21 228.95C34.05 228.95 38.09 228.54 42.25 228.04C43.3 227.91 44.36 227.78 45.42 227.65C48.71 227.24 52.09 226.82 55.57 226.52C58.34 226.29 61.17 226.13 64.04 226.13C70.69 226.13 77.6 226.95 84.73 229.44C91.36 231.76 95.79 234.97 98.97 238.79C101.11 240.75 102.82 242.91 104.23 245.22C103.86 244.28 103.46 243.37 103.03 242.47C101.68 239.65 100.01 237.02 97.77 234.66C94.68 231.86 90.7 229.47 85.45 227.63C78.04 225.03 70.88 224.19 64.05 224.19C61.1 224.19 58.22 224.35 55.4 224.58C51.87 224.88 48.44 225.31 45.13 225.72C44.07 225.85 43.03 225.98 42 226.11C37.84 226.61 33.92 227 30.21 227C30.11 227 30.02 227 29.93 227C24.57 226.97 19.71 226.09 15.36 223.47"
          fill="url(#paint8_linear_573_35549)"
        />
        <path
          d="M113.86 273.03C114.05 273.6 114.24 274.17 114.44 274.74C116.92 281.75 120.48 288.67 127.44 294.81C127.6 294.93 127.76 295.06 127.92 295.18C129.28 296.22 130.76 297.23 132.34 298.21C133.1 297.94 133.84 297.62 134.56 297.25C132.6 296.1 130.82 294.9 129.2 293.67C126.63 291.71 124.46 289.66 122.6 287.54C119.1 283.54 116.73 279.29 114.92 274.97C114.55 274.33 114.19 273.68 113.86 273.04M65.12 228.21C62.17 228.21 59.29 228.37 56.47 228.6C52.94 228.9 49.51 229.33 46.2 229.74C45.14 229.87 44.1 230 43.07 230.13C38.91 230.63 34.99 231.02 31.28 231.02C31.18 231.02 31.09 231.02 31 231.02C27.15 231 23.56 230.54 20.24 229.31C21.1 230.26 21.98 231.19 22.88 232.11C25.48 232.7 28.19 232.94 30.99 232.95C31.09 232.95 31.19 232.95 31.29 232.95C35.13 232.95 39.17 232.54 43.33 232.04C44.38 231.91 45.44 231.78 46.5 231.65C49.79 231.24 53.17 230.82 56.65 230.52C59.42 230.29 62.25 230.13 65.12 230.13C71.77 230.13 78.68 230.95 85.81 233.44C90.88 235.22 94.66 237.51 97.58 240.2C101.27 242.97 103.87 246.22 105.86 249.8C105.36 248.23 104.83 246.7 104.24 245.21C102.83 242.9 101.12 240.74 98.98 238.78C95.87 235.93 91.86 233.49 86.52 231.62C79.11 229.02 71.95 228.18 65.12 228.18"
          fill="url(#paint9_linear_573_35549)"
        />
        <path
          d="M113.46 272.23C114.09 274.39 114.75 276.57 115.52 278.75C117.48 284.28 120.11 289.77 124.56 294.85C125.85 296.09 127.25 297.3 128.8 298.48C129.01 298.64 129.22 298.8 129.44 298.96C130.3 298.82 131.14 298.61 131.97 298.34C131.32 297.89 130.68 297.43 130.08 296.97C129.15 296.26 128.27 295.54 127.45 294.81C120.49 288.67 116.93 281.75 114.45 274.74C114.25 274.17 114.05 273.6 113.87 273.03C113.73 272.77 113.6 272.5 113.46 272.23ZM65.99 231.51C63.04 231.51 60.15 231.67 57.34 231.9C53.81 232.2 50.38 232.63 47.07 233.04C46.01 233.17 44.97 233.3 43.94 233.43C39.79 233.93 35.87 234.32 32.17 234.32C32.07 234.32 31.97 234.32 31.87 234.32C29.23 234.3 26.7 234.08 24.31 233.55C25.14 234.36 25.98 235.16 26.83 235.95C28.47 236.16 30.14 236.25 31.86 236.26C31.96 236.26 32.07 236.26 32.17 236.26C36.01 236.26 40.04 235.85 44.2 235.35C45.25 235.22 46.31 235.09 47.37 234.96C50.66 234.55 54.04 234.13 57.52 233.83C60.29 233.6 63.12 233.44 65.99 233.44C72.64 233.44 79.55 234.26 86.68 236.75C93.31 239.07 97.74 242.28 100.91 246.1C103.6 248.57 105.61 251.35 107.21 254.34C107.11 254 107.02 253.67 106.92 253.34C106.57 252.14 106.22 250.97 105.85 249.81C103.87 246.23 101.26 242.98 97.57 240.21C94.83 238.16 91.5 236.38 87.38 234.93C79.97 232.33 72.81 231.5 65.98 231.5"
          fill="url(#paint10_linear_573_35549)"
        />
        <path
          d="M113.29 271.9C113.41 272.31 113.52 272.71 113.64 273.12C114.51 276.16 115.38 279.23 116.47 282.31C118.53 288.13 121.33 293.89 126.21 299.19C126.33 299.19 126.45 299.19 126.56 299.19C127.3 299.19 128.05 299.14 128.79 299.05C127.22 297.69 125.82 296.28 124.56 294.85C120.11 289.77 117.48 284.28 115.52 278.75C114.75 276.57 114.09 274.4 113.46 272.23C113.41 272.12 113.35 272.01 113.3 271.9M67.06 235.52C64.11 235.52 61.23 235.68 58.41 235.91C54.88 236.21 51.45 236.64 48.14 237.05C47.08 237.18 46.04 237.31 45.01 237.44C40.86 237.94 36.94 238.33 33.24 238.33C33.14 238.33 33.04 238.33 32.94 238.33C31.7 238.33 30.48 238.27 29.3 238.16C29.83 238.62 30.37 239.09 30.92 239.54L32 240.25C32.31 240.25 32.62 240.26 32.94 240.26C33.04 240.26 33.15 240.26 33.25 240.26C37.09 240.26 41.12 239.85 45.28 239.35C46.33 239.22 47.39 239.09 48.45 238.96C51.74 238.55 55.12 238.13 58.6 237.83C61.37 237.6 64.2 237.44 67.07 237.44C73.72 237.44 80.63 238.26 87.76 240.75C93.46 242.75 97.54 245.4 100.59 248.54C104.03 251.35 106.46 254.61 108.31 258.17C108.09 257.39 107.86 256.62 107.65 255.85C107.51 255.34 107.36 254.83 107.22 254.33C105.62 251.33 103.61 248.55 100.92 246.09C97.81 243.24 93.8 240.8 88.47 238.94C81.06 236.34 73.89 235.5 67.07 235.5"
          fill="url(#paint11_linear_573_35549)"
        />
        <path
          d="M112.98 271.24C113.33 272.34 113.66 273.46 113.98 274.57C114.23 275.42 114.47 276.28 114.71 277.14C115.58 280.18 116.45 283.25 117.53 286.33C119.05 290.64 120.98 294.91 123.86 298.99C124.63 299.11 125.41 299.18 126.19 299.19C121.31 293.89 118.51 288.13 116.45 282.31C115.36 279.24 114.49 276.17 113.62 273.12C113.5 272.71 113.39 272.3 113.27 271.9C113.16 271.68 113.06 271.46 112.95 271.24M68.01 239.08C65.06 239.08 62.18 239.24 59.36 239.47C55.83 239.77 52.4 240.2 49.09 240.61C48.03 240.74 46.99 240.87 45.96 241C41.91 241.49 38.09 241.87 34.47 241.89L37.29 243.75C40.18 243.61 43.16 243.29 46.22 242.92C47.27 242.79 48.33 242.66 49.39 242.53C52.68 242.12 56.06 241.7 59.54 241.4C62.31 241.17 65.14 241.01 68.01 241.01C74.66 241.01 81.57 241.83 88.7 244.32C95.34 246.65 99.78 249.87 102.96 253.7C105.93 256.43 108.08 259.55 109.75 262.92C109.24 261.33 108.76 259.75 108.31 258.18C106.45 254.62 104.02 251.35 100.59 248.55C97.68 246.17 94.04 244.13 89.42 242.51C82.01 239.91 74.85 239.07 68.02 239.07"
          fill="url(#paint12_linear_573_35549)"
        />
        <path
          d="M69.08 243.1C66.13 243.1 63.24 243.26 60.43 243.49C56.9 243.79 53.47 244.22 50.16 244.63C49.1 244.76 48.06 244.89 47.03 245.02C44.7 245.3 42.44 245.55 40.26 245.71L42.87 247.43C44.32 247.29 45.8 247.12 47.29 246.94C48.34 246.81 49.4 246.68 50.46 246.55C53.75 246.14 57.13 245.72 60.61 245.42C63.38 245.19 66.2 245.03 69.08 245.03C75.73 245.03 82.64 245.85 89.77 248.34C94.81 250.11 98.58 252.39 101.5 255.06C105.89 258.34 108.75 262.31 110.85 266.69C112.54 270.21 113.73 273.99 114.85 277.85C115.1 278.7 115.34 279.56 115.59 280.42C116.45 283.46 117.33 286.53 118.42 289.61C119.5 292.68 120.8 295.73 122.49 298.7C122.95 298.81 123.41 298.9 123.88 298.97C121 294.89 119.07 290.62 117.55 286.31C116.46 283.24 115.59 280.17 114.73 277.12C114.49 276.26 114.24 275.41 114 274.55C113.68 273.43 113.34 272.32 113 271.22C112.05 269.22 111.25 267.2 110.54 265.19C110.27 264.43 110.02 263.68 109.77 262.92C108.1 259.55 105.96 256.43 102.98 253.7C99.87 250.84 95.85 248.4 90.5 246.52C83.09 243.92 75.93 243.09 69.1 243.09"
          fill="url(#paint13_linear_573_35549)"
        />
        <path
          d="M69.95 246.4C67 246.4 64.11 246.56 61.3 246.79C57.77 247.09 54.34 247.52 51.03 247.93C49.97 248.06 48.93 248.19 47.9 248.32C46.83 248.45 45.78 248.57 44.74 248.68L47.27 250.35C47.56 250.32 47.86 250.28 48.15 250.25C49.2 250.12 50.26 249.99 51.32 249.86C54.61 249.45 57.99 249.03 61.47 248.73C64.24 248.5 67.07 248.34 69.94 248.34C76.59 248.34 83.5 249.16 90.63 251.65C97.26 253.97 101.69 257.18 104.87 261C107.99 263.87 110.2 267.16 111.91 270.72C113.6 274.24 114.79 278.02 115.91 281.88C116.16 282.73 116.4 283.59 116.65 284.45C117.51 287.49 118.39 290.56 119.47 293.63C120.04 295.23 120.66 296.83 121.37 298.41C121.74 298.52 122.1 298.62 122.48 298.71C120.79 295.73 119.49 292.68 118.41 289.62C117.32 286.55 116.45 283.48 115.58 280.43C115.34 279.57 115.09 278.72 114.84 277.86C113.72 274.01 112.53 270.23 110.84 266.7C108.74 262.32 105.88 258.35 101.49 255.07C98.76 253.03 95.44 251.26 91.34 249.83C83.93 247.23 76.77 246.4 69.94 246.4"
          fill="url(#paint14_linear_573_35549)"
        />
        <path
          d="M71.02 250.41C68.07 250.41 65.19 250.57 62.37 250.8C58.84 251.1 55.41 251.53 52.1 251.94C51.42 252.02 50.74 252.11 50.07 252.19L52.56 253.84C55.8 253.44 59.12 253.02 62.54 252.74C65.31 252.51 68.14 252.35 71.01 252.35C77.66 252.35 84.57 253.17 91.7 255.66C97.6 257.73 101.76 260.5 104.84 263.77C107.05 266.12 108.7 268.73 110.04 271.52C110.24 271.94 110.44 272.37 110.63 272.81C111.27 274.26 111.83 275.76 112.35 277.3C112.86 278.79 113.32 280.32 113.78 281.88C113.83 282.05 113.88 282.22 113.93 282.39C114.17 283.22 114.41 284.07 114.65 284.91C114.72 285.15 114.79 285.4 114.86 285.64C115.27 287.07 115.68 288.51 116.12 289.96C116.53 291.29 116.96 292.64 117.43 293.98L117.52 294.23C117.88 295.26 118.27 296.29 118.69 297.31C119.55 297.75 120.44 298.11 121.36 298.39C120.65 296.81 120.03 295.21 119.46 293.61C118.37 290.54 117.5 287.47 116.64 284.43C116.4 283.57 116.15 282.72 115.9 281.86C114.78 278 113.59 274.22 111.9 270.7C110.19 267.14 107.99 263.85 104.86 260.98C101.75 258.13 97.74 255.69 92.4 253.82C84.99 251.22 77.82 250.38 71 250.39"
          fill="url(#paint15_linear_573_35549)"
        />
        <path
          d="M108.83 248.06C110.49 251.61 112.61 255.1 115.49 258.41C116.8 259.68 118.24 260.92 119.83 262.13C128.86 269.01 142.52 274.69 163.95 278.18L166.38 276.6C165.88 276.52 165.38 276.45 164.88 276.37C143.32 272.93 129.86 267.29 121.12 260.63C119.35 259.29 117.78 257.9 116.36 256.47C113.29 253.79 110.84 250.97 108.84 248.07M1.57996 188.81C1.61996 189.61 1.67996 190.41 1.75996 191.2C2.21996 191.85 2.68996 192.46 3.16996 193.03C8.89996 198.31 15.57 199.87 22.88 199.92C22.98 199.92 23.08 199.92 23.18 199.92C27.02 199.92 31.06 199.51 35.22 199.01C36.27 198.88 37.33 198.75 38.39 198.62C41.68 198.21 45.06 197.79 48.54 197.49C51.31 197.26 54.14 197.1 57.01 197.1C63.66 197.1 70.57 197.92 77.7 200.41C84.36 202.75 88.81 205.98 91.99 209.82C93.38 211.11 94.6 212.48 95.66 213.92C95.47 213.49 95.27 213.06 95.07 212.63C93.72 209.81 92.04 207.18 89.8 204.81C86.85 202.36 83.15 200.25 78.41 198.59C71 195.99 63.84 195.15 57.01 195.15C54.06 195.15 51.18 195.31 48.36 195.54C44.83 195.84 41.4 196.27 38.09 196.68C37.03 196.81 35.99 196.94 34.96 197.07C30.8 197.57 26.88 197.96 23.18 197.96C23.08 197.96 22.99 197.96 22.89 197.96C15.36 197.92 8.82996 196.2 3.29996 190.34C2.71996 189.86 2.13996 189.35 1.57996 188.79"
          fill="url(#paint16_linear_573_35549)"
        />
        <path
          d="M107.57 246.11C110.06 253.02 113.65 259.84 120.56 265.89C120.67 265.98 120.78 266.06 120.89 266.15C129.1 272.4 141.13 277.67 159.33 281.19L161.68 279.65C142.58 276.17 130.32 270.85 122.17 264.64C119.56 262.65 117.37 260.57 115.49 258.42C112.61 255.11 110.49 251.62 108.83 248.07C108.39 247.42 107.97 246.78 107.56 246.12M1.75998 191.2C1.83998 192.02 1.93998 192.84 2.05998 193.65C3.07998 195.17 4.14998 196.5 5.23998 197.66C5.64998 198.1 6.06998 198.51 6.49998 198.91C11.7 202.71 17.58 203.9 23.94 203.93C24.04 203.93 24.15 203.93 24.25 203.93C28.09 203.93 32.12 203.52 36.28 203.02C37.33 202.89 38.39 202.76 39.45 202.63C42.74 202.22 46.12 201.8 49.6 201.5C52.37 201.27 55.2 201.11 58.07 201.11C64.72 201.11 71.63 201.93 78.76 204.42C83.78 206.18 87.54 208.45 90.45 211.1C93.35 213.26 95.59 215.72 97.38 218.4C96.86 216.86 96.29 215.36 95.66 213.91C94.6 212.47 93.38 211.1 91.99 209.81C88.87 206.93 84.84 204.48 79.47 202.6C72.06 200 64.9 199.17 58.07 199.17C55.12 199.17 52.23 199.33 49.42 199.56C45.89 199.86 42.46 200.29 39.15 200.7C38.09 200.83 37.05 200.96 36.02 201.09C31.87 201.59 27.95 201.98 24.25 201.98C24.15 201.98 24.05 201.98 23.95 201.98C16.42 201.94 9.88998 200.22 4.35998 194.36C3.95998 193.94 3.55998 193.49 3.16998 193.02C2.68998 192.44 2.21998 191.83 1.75998 191.19"
          fill="url(#paint17_linear_573_35549)"
        />
        <path
          d="M107.03 245.21C107.48 246.71 107.96 248.21 108.49 249.71C110.45 255.24 113.08 260.73 117.53 265.81C118.82 267.05 120.23 268.26 121.77 269.44C129.28 275.16 140 280.06 155.72 283.55L158.01 282.06C141.49 278.59 130.54 273.64 123.05 267.94C122.17 267.27 121.34 266.59 120.55 265.9C113.64 259.85 110.05 253.03 107.56 246.12C107.38 245.82 107.2 245.52 107.02 245.22M2.05997 193.65C2.21997 194.74 2.40997 195.83 2.62997 196.9C3.45997 198.19 4.30997 199.35 5.17997 200.4C10.9 205.63 17.55 207.18 24.82 207.23C24.92 207.23 25.02 207.23 25.12 207.23C28.96 207.23 33 206.82 37.16 206.32C38.21 206.19 39.27 206.06 40.33 205.93C43.62 205.52 47 205.1 50.48 204.8C53.25 204.57 56.08 204.41 58.95 204.41C65.6 204.41 72.51 205.23 79.64 207.72C86.26 210.04 90.7 213.25 93.87 217.06C95.84 218.87 97.45 220.85 98.8 222.95C98.35 221.4 97.88 219.89 97.38 218.41C95.59 215.73 93.36 213.27 90.45 211.11C87.73 209.09 84.42 207.33 80.35 205.9C72.94 203.3 65.77 202.46 58.95 202.47C56 202.47 53.12 202.63 50.3 202.86C46.77 203.16 43.34 203.59 40.03 204C38.97 204.13 37.93 204.26 36.9 204.39C32.74 204.89 28.82 205.28 25.11 205.28C25.01 205.28 24.92 205.28 24.83 205.28C17.86 205.24 11.75 203.77 6.49997 198.91C6.06997 198.52 5.64997 198.1 5.23997 197.66C4.13997 196.5 3.07997 195.17 2.05997 193.65Z"
          fill="url(#paint18_linear_573_35549)"
        />
        <path
          d="M106.83 244.87C107.62 247.66 108.44 250.46 109.43 253.27C111.69 259.65 114.84 265.95 120.63 271.66C121.33 272.26 122.07 272.86 122.84 273.44C129.48 278.5 138.63 282.91 151.52 286.28L153.73 284.84C140.14 281.47 130.77 277 124.12 271.94C121.55 269.98 119.38 267.93 117.53 265.81C113.08 260.73 110.45 255.24 108.49 249.71C107.96 248.21 107.48 246.71 107.03 245.21C106.96 245.1 106.9 244.98 106.83 244.87ZM2.62997 196.91C2.85997 198.04 3.12997 199.15 3.43997 200.26C4.65997 202.19 5.92997 203.84 7.25997 205.24L7.45997 205.45C12.9 209.86 19.13 211.2 25.9 211.24C26 211.24 26.1 211.24 26.2 211.24C30.04 211.24 34.08 210.83 38.24 210.33C39.29 210.2 40.35 210.07 41.41 209.94C44.7 209.53 48.08 209.11 51.56 208.81C54.33 208.58 57.16 208.42 60.03 208.42C66.68 208.42 73.59 209.24 80.72 211.73C86.44 213.74 90.53 216.4 93.59 219.55C96.22 221.7 98.25 224.13 99.9 226.75C99.83 226.51 99.76 226.27 99.7 226.03C99.46 225.18 99.22 224.34 98.98 223.5C98.93 223.32 98.87 223.13 98.82 222.95C97.47 220.84 95.87 218.86 93.89 217.06C90.78 214.21 86.77 211.78 81.44 209.91C74.03 207.31 66.87 206.48 60.04 206.48C57.09 206.48 54.2 206.64 51.39 206.87C47.86 207.17 44.43 207.6 41.12 208.01C40.06 208.14 39.02 208.27 37.99 208.4C33.83 208.9 29.91 209.29 26.2 209.29C26.11 209.29 26.01 209.29 25.92 209.29C18.39 209.25 11.86 207.53 6.32997 201.67C5.94997 201.27 5.56997 200.84 5.19997 200.4C4.31997 199.35 3.46997 198.19 2.64997 196.9"
          fill="url(#paint19_linear_573_35549)"
        />
        <path
          d="M106.66 244.57C106.76 244.89 106.85 245.22 106.94 245.54C107.19 246.39 107.43 247.25 107.67 248.11C108.53 251.15 109.41 254.22 110.49 257.3C112.44 262.82 115.06 268.28 119.48 273.35C120.78 274.6 122.2 275.83 123.77 277.02C129.62 281.47 137.4 285.42 147.97 288.6L150.12 287.2C138.95 284 130.93 279.99 125.05 275.51C123.42 274.27 121.95 272.99 120.62 271.68C114.83 265.97 111.68 259.66 109.42 253.29C108.43 250.48 107.61 247.67 106.82 244.89C106.76 244.79 106.71 244.69 106.65 244.59M3.43996 200.26C3.86996 201.84 4.36996 203.4 4.93996 204.94C5.65996 206.04 6.40996 207.04 7.16996 207.95C12.89 213.2 19.55 214.76 26.84 214.8C26.94 214.8 27.04 214.8 27.14 214.8C30.98 214.8 35.02 214.39 39.18 213.89C40.23 213.76 41.29 213.63 42.35 213.5C45.64 213.09 49.02 212.67 52.5 212.37C55.27 212.14 58.1 211.98 60.97 211.98C67.62 211.98 74.53 212.8 81.66 215.29C88.31 217.62 92.75 220.84 95.92 224.67C98.05 226.63 99.75 228.78 101.16 231.08C100.72 229.62 100.3 228.17 99.89 226.74C98.25 224.12 96.21 221.7 93.58 219.54C90.66 217.15 87.01 215.09 82.36 213.46C74.95 210.86 67.78 210.02 60.96 210.02C58.01 210.02 55.13 210.18 52.31 210.41C48.78 210.71 45.35 211.14 42.04 211.55C40.98 211.68 39.94 211.81 38.91 211.94C34.75 212.44 30.83 212.83 27.12 212.83C27.02 212.83 26.93 212.83 26.84 212.83C19.4 212.79 12.93 211.11 7.44996 205.42L7.24996 205.21C5.91996 203.8 4.64996 202.16 3.42996 200.23"
          fill="url(#paint20_linear_573_35549)"
        />
        <path
          d="M106.32 243.95C106.86 245.55 107.35 247.18 107.82 248.82C108.07 249.67 108.31 250.53 108.55 251.39C109.42 254.43 110.29 257.5 111.37 260.58C113.87 267.64 117.46 274.61 124.52 280.78C124.63 280.86 124.73 280.94 124.84 281.03C129.76 284.78 136.05 288.17 144.22 291.04L146.29 289.69C137.62 286.78 131.11 283.31 126.12 279.52C123.53 277.55 121.35 275.48 119.48 273.34C115.06 268.27 112.44 262.81 110.49 257.29C109.4 254.22 108.53 251.15 107.67 248.1C107.43 247.24 107.18 246.39 106.94 245.53C106.85 245.2 106.75 244.88 106.66 244.56C106.55 244.36 106.43 244.15 106.32 243.95ZM4.93996 204.94C5.55996 206.64 6.26996 208.32 7.03996 209.97C7.74996 210.91 8.46996 211.77 9.20996 212.55C9.62996 212.99 10.05 213.41 10.48 213.81C15.68 217.6 21.55 218.79 27.91 218.82C28.01 218.82 28.12 218.82 28.22 218.82C32.06 218.82 36.09 218.41 40.25 217.91C41.3 217.78 42.36 217.65 43.42 217.52C46.71 217.11 50.09 216.69 53.57 216.39C56.34 216.16 59.17 216 62.04 216C68.69 216 75.6 216.82 82.73 219.31C87.75 221.07 91.51 223.33 94.41 225.99C97.95 228.62 100.5 231.7 102.45 235.08C101.98 233.75 101.56 232.41 101.15 231.09C99.74 228.79 98.04 226.63 95.91 224.68C92.8 221.82 88.78 219.37 83.43 217.5C76.02 214.9 68.86 214.07 62.03 214.07C59.08 214.07 56.19 214.23 53.38 214.46C49.85 214.76 46.42 215.19 43.11 215.6C42.05 215.73 41.01 215.86 39.98 215.99C35.83 216.49 31.91 216.88 28.21 216.88C28.11 216.88 28.01 216.88 27.91 216.88C20.38 216.84 13.85 215.12 8.31996 209.26C7.92996 208.84 7.53996 208.41 7.15996 207.95C6.39996 207.04 5.65996 206.04 4.92996 204.94"
          fill="url(#paint21_linear_573_35549)"
        />
        <path
          d="M7.03998 209.96C8.07998 212.18 9.24998 214.35 10.55 216.48C15.94 220.76 22.1 222.07 28.79 222.11C28.89 222.11 28.99 222.11 29.09 222.11C32.93 222.11 36.96 221.7 41.13 221.2C42.18 221.07 43.24 220.94 44.3 220.81C47.59 220.4 50.97 219.98 54.45 219.68C57.22 219.45 60.05 219.29 62.92 219.29C69.57 219.29 76.48 220.11 83.61 222.6C90.24 224.92 94.67 228.13 97.84 231.95C100.97 234.82 103.18 238.11 104.88 241.67C106.57 245.19 107.76 248.97 108.88 252.83C109.13 253.68 109.37 254.54 109.61 255.4C110.47 258.44 111.35 261.51 112.43 264.59C114.39 270.13 117.02 275.61 121.47 280.7C122.75 281.94 124.16 283.15 125.71 284.32C129.85 287.47 134.95 290.37 141.33 292.92L143.34 291.61C136.54 289 131.22 286.03 126.99 282.81C126.12 282.15 125.29 281.47 124.51 280.78C117.45 274.61 113.86 267.64 111.36 260.58C110.27 257.51 109.4 254.44 108.54 251.39C108.3 250.53 108.05 249.68 107.81 248.82C107.33 247.18 106.84 245.55 106.31 243.95C104.77 241.12 103.57 238.24 102.55 235.37C102.52 235.28 102.48 235.18 102.45 235.09C100.49 231.71 97.95 228.63 94.41 226C91.69 223.98 88.39 222.22 84.32 220.8C76.91 218.2 69.75 217.36 62.92 217.36C59.97 217.36 57.09 217.52 54.27 217.75C50.74 218.05 47.31 218.48 44 218.89C42.94 219.02 41.9 219.15 40.87 219.28C36.71 219.78 32.79 220.17 29.08 220.17C28.99 220.17 28.89 220.17 28.8 220.17C21.84 220.13 15.73 218.66 10.48 213.8C10.05 213.4 9.62998 212.98 9.20998 212.54C8.46998 211.76 7.74998 210.9 7.03998 209.96Z"
          fill="url(#paint22_linear_573_35549)"
        />
        <path
          d="M11.13 217.42C12.22 219.16 13.4 220.87 14.65 222.54C19.28 225.2 24.4 226.08 29.86 226.11C29.96 226.11 30.07 226.11 30.17 226.11C34.01 226.11 38.04 225.7 42.2 225.2C43.25 225.07 44.31 224.94 45.37 224.81C48.66 224.4 52.04 223.98 55.52 223.68C58.29 223.45 61.12 223.29 63.99 223.29C70.64 223.29 77.55 224.1 84.68 226.6C90.54 228.65 94.69 231.4 97.76 234.65C100 237.01 101.67 239.64 103.02 242.46C103.45 243.36 103.85 244.28 104.22 245.21C104.81 246.7 105.34 248.23 105.84 249.8C106.21 250.96 106.56 252.13 106.91 253.33C107.01 253.66 107.1 254 107.2 254.33C107.34 254.83 107.49 255.34 107.63 255.85C107.85 256.62 108.07 257.39 108.29 258.17C108.74 259.74 109.21 261.32 109.73 262.91C109.98 263.67 110.23 264.42 110.5 265.18C111.21 267.19 112.02 269.21 112.96 271.21C113.06 271.43 113.17 271.65 113.28 271.87C113.33 271.98 113.39 272.09 113.44 272.2C113.57 272.47 113.71 272.73 113.85 273C114.19 273.65 114.54 274.29 114.91 274.93C116.97 278.47 119.59 281.93 123.09 285.19C124.23 286.25 125.45 287.29 126.78 288.3C129.93 290.7 133.65 292.96 138.06 295.02L140 293.75C135.25 291.61 131.33 289.27 128.06 286.78C125.49 284.83 123.32 282.78 121.47 280.66C117.02 275.57 114.39 270.09 112.43 264.55C111.34 261.48 110.47 258.41 109.61 255.36C109.37 254.5 109.12 253.65 108.88 252.79C107.76 248.93 106.57 245.15 104.88 241.63C103.17 238.07 100.96 234.78 97.84 231.91C94.73 229.06 90.72 226.62 85.39 224.76C77.98 222.16 70.82 221.33 63.99 221.33C61.04 221.33 58.15 221.49 55.34 221.72C51.81 222.02 48.38 222.45 45.07 222.86C44.01 222.99 42.97 223.12 41.94 223.25C37.79 223.75 33.87 224.14 30.17 224.14C30.07 224.14 29.97 224.14 29.87 224.14C22.72 224.1 16.48 222.55 11.13 217.38"
          fill="url(#paint23_linear_573_35549)"
        />
        <path
          d="M98.71 212.87C100.66 218.3 103.27 223.68 107.64 228.67C108.93 229.91 110.33 231.12 111.88 232.29C120.99 239.23 134.82 244.96 156.59 248.43C166.32 249.99 175.11 250.68 183.06 250.68C193.89 250.68 203.14 249.39 211.03 247.26H210.89C209.94 247.26 207.47 247.22 203.88 246.91C197.72 248.08 190.81 248.75 183.06 248.75C175.31 248.75 166.56 248.07 156.93 246.53C135.37 243.09 121.91 237.45 113.17 230.79C109.36 227.89 106.43 224.78 104.1 221.55C101.88 218.74 100.14 215.83 98.72 212.89M1.50998 165.25V167.44C5.65998 169.38 10.16 170.05 14.93 170.08C15.03 170.08 15.13 170.08 15.23 170.08C19.07 170.08 23.1 169.67 27.27 169.17C28.32 169.04 29.38 168.91 30.44 168.78C33.73 168.37 37.11 167.95 40.59 167.65C43.36 167.42 46.19 167.26 49.06 167.26C55.71 167.26 62.62 168.08 69.75 170.57C76.37 172.89 80.81 176.1 83.98 179.91C86.92 182.61 89.05 185.68 90.71 189C90.08 187.19 89.39 185.43 88.58 183.74C87.79 182.1 86.9 180.53 85.85 179.03C82.44 174.82 77.65 171.27 70.46 168.75C63.05 166.15 55.88 165.31 49.06 165.31C46.11 165.31 43.23 165.47 40.41 165.7C36.88 166 33.45 166.43 30.14 166.84C29.08 166.97 28.04 167.1 27.01 167.23C22.85 167.73 18.93 168.12 15.22 168.12C15.12 168.12 15.03 168.12 14.94 168.12C10.04 168.09 5.55998 167.36 1.50998 165.23"
          fill="url(#paint24_linear_573_35549)"
        />
        <path
          d="M98.03 211.41C98.48 212.89 98.95 214.37 99.48 215.85C101.97 222.9 105.57 229.87 112.61 236.04C112.72 236.13 112.83 236.21 112.95 236.3C122.06 243.24 135.89 248.97 157.65 252.44C167.38 254 176.17 254.69 184.12 254.69C190.59 254.69 196.5 254.23 201.89 253.41L206.04 250.7C199.61 252 192.34 252.75 184.13 252.75C175.92 252.75 167.63 252.07 158 250.53C136.44 247.09 122.98 241.45 114.24 234.79C111.67 232.83 109.5 230.78 107.65 228.67C103.28 223.68 100.67 218.3 98.72 212.87C98.49 212.39 98.26 211.9 98.04 211.41M1.50998 168.66V170.91C5.94998 173.26 10.81 174.06 16 174.09C16.1 174.09 16.2 174.09 16.3 174.09C20.14 174.09 24.18 173.68 28.34 173.18C29.39 173.05 30.45 172.92 31.51 172.79C34.8 172.38 38.18 171.96 41.66 171.66C44.43 171.43 47.26 171.27 50.13 171.27C56.78 171.27 63.69 172.09 70.82 174.58C75.84 176.34 79.6 178.61 82.51 181.26C86.93 184.55 89.8 188.53 91.91 192.93C91.97 193.06 92.04 193.19 92.1 193.33C91.66 191.86 91.21 190.41 90.72 189.01C89.06 185.69 86.93 182.62 83.99 179.92C80.88 177.07 76.87 174.64 71.54 172.77C64.13 170.17 56.97 169.33 50.14 169.33C47.19 169.33 44.31 169.49 41.49 169.72C37.96 170.02 34.53 170.45 31.22 170.86C30.16 170.99 29.12 171.12 28.09 171.25C23.93 171.75 20.01 172.14 16.3 172.14C16.2 172.14 16.11 172.14 16.01 172.14C10.67 172.11 5.83998 171.24 1.50998 168.65"
          fill="url(#paint25_linear_573_35549)"
        />
        <path
          d="M97.72 210.7C98.58 213.74 99.46 216.81 100.54 219.87C102.48 225.37 105.09 230.81 109.48 235.86C110.79 237.13 112.24 238.38 113.82 239.59C122.93 246.53 136.76 252.26 158.52 255.73C168.25 257.29 177.04 257.98 184.99 257.98C188.68 257.98 192.2 257.83 195.53 257.55L199.07 255.24C194.72 255.76 190.03 256.04 184.99 256.04C177.17 256.04 168.49 255.36 158.86 253.82C137.3 250.38 123.84 244.74 115.1 238.08C114.22 237.41 113.39 236.73 112.6 236.04C105.55 229.87 101.96 222.9 99.47 215.85C98.95 214.37 98.47 212.89 98.02 211.41C97.91 211.17 97.81 210.94 97.71 210.7M1.50997 171.4V173.72C6.17997 176.45 11.35 177.35 16.88 177.38C16.98 177.38 17.08 177.38 17.18 177.38C21.02 177.38 25.06 176.97 29.22 176.47C30.27 176.34 31.33 176.21 32.39 176.08C35.68 175.67 39.06 175.25 42.54 174.95C45.31 174.72 48.14 174.56 51.01 174.56C57.66 174.56 64.57 175.38 71.7 177.87C78.37 180.21 82.82 183.44 85.99 187.29C89.08 190.14 91.27 193.41 92.97 196.95C93.12 197.27 93.27 197.59 93.41 197.91C93.34 197.65 93.26 197.4 93.19 197.14C92.95 196.29 92.71 195.45 92.47 194.62C92.34 194.19 92.22 193.76 92.09 193.33C92.03 193.2 91.97 193.07 91.9 192.93C89.79 188.53 86.92 184.55 82.5 181.26C79.78 179.24 76.47 177.48 72.4 176.05C64.99 173.45 57.83 172.61 51 172.61C48.05 172.61 45.17 172.77 42.35 173C38.82 173.3 35.39 173.73 32.08 174.14C31.02 174.27 29.98 174.4 28.95 174.53C24.79 175.03 20.87 175.42 17.16 175.42C17.06 175.42 16.97 175.42 16.88 175.42C11.18 175.39 6.04997 174.4 1.49997 171.37"
          fill="url(#paint26_linear_573_35549)"
        />
        <path
          d="M97.47 210.11C97.63 210.63 97.78 211.16 97.93 211.68C98.18 212.53 98.42 213.39 98.66 214.25C99.52 217.29 100.4 220.36 101.49 223.43C103.75 229.83 106.92 236.17 112.76 241.89C113.44 242.47 114.15 243.04 114.89 243.61C124 250.55 137.83 256.28 159.59 259.75C169.32 261.31 178.12 262 186.06 262C186.97 262 187.87 262 188.76 261.97L191.89 259.93C190 260.02 188.06 260.06 186.07 260.06C178.25 260.06 169.56 259.38 159.94 257.84C138.38 254.4 124.92 248.76 116.18 242.1C113.57 240.11 111.37 238.02 109.49 235.87C105.1 230.82 102.49 225.37 100.55 219.88C99.46 216.81 98.59 213.74 97.73 210.71C97.65 210.51 97.56 210.32 97.48 210.12M1.50997 174.67V177.08C6.45997 180.32 11.99 181.37 17.94 181.4C18.04 181.4 18.14 181.4 18.24 181.4C22.08 181.4 26.12 180.99 30.28 180.49C31.33 180.36 32.39 180.23 33.45 180.1C36.74 179.69 40.12 179.27 43.6 178.97C46.37 178.74 49.2 178.58 52.07 178.58C58.72 178.58 65.63 179.4 72.76 181.89C78.46 183.89 82.54 186.54 85.58 189.67C89.4 192.79 91.98 196.47 93.91 200.51C94.16 201.03 94.39 201.55 94.62 202.07C94.19 200.67 93.79 199.28 93.4 197.9C93.26 197.58 93.11 197.26 92.96 196.94C91.26 193.4 89.07 190.13 85.98 187.28C82.86 184.4 78.83 181.95 73.46 180.07C66.05 177.47 58.88 176.63 52.06 176.63C49.11 176.63 46.23 176.79 43.41 177.02C39.88 177.32 36.45 177.75 33.14 178.16C32.08 178.29 31.04 178.42 30.01 178.55C25.86 179.05 21.94 179.44 18.24 179.44C18.14 179.44 18.04 179.44 17.94 179.44C11.79 179.4 6.30997 178.26 1.49997 174.64"
          fill="url(#paint27_linear_573_35549)"
        />
        <path
          d="M1.50998 177.48V179.99C6.68998 183.75 12.55 184.93 18.89 184.97C18.99 184.97 19.09 184.97 19.19 184.97C23.03 184.97 27.07 184.56 31.23 184.06C32.28 183.93 33.34 183.8 34.4 183.67C37.69 183.26 41.07 182.84 44.55 182.54C47.32 182.31 50.15 182.15 53.02 182.15C59.67 182.15 66.58 182.97 73.71 185.46C80.34 187.78 84.77 190.99 87.95 194.81C91.08 197.68 93.28 200.97 94.99 204.53C96.68 208.05 97.87 211.83 98.99 215.69C99.24 216.54 99.48 217.4 99.72 218.26C100.58 221.3 101.46 224.37 102.54 227.45C104.5 232.98 107.13 238.46 111.58 243.55C112.87 244.79 114.27 246 115.82 247.18C124.93 254.12 138.76 259.85 160.53 263.32C168.79 264.64 176.37 265.34 183.33 265.52L186.23 263.63C178.61 263.59 170.18 262.9 160.87 261.41C139.31 257.97 125.85 252.33 117.11 245.67C115.51 244.45 114.06 243.19 112.75 241.91C106.91 236.18 103.75 229.85 101.48 223.45C100.39 220.38 99.52 217.31 98.65 214.27C98.41 213.41 98.16 212.56 97.92 211.7C97.77 211.17 97.61 210.65 97.46 210.13C96.96 208.92 96.5 207.71 96.07 206.5C95.55 205.03 95.08 203.56 94.64 202.1C94.41 201.57 94.18 201.05 93.93 200.54C92 196.51 89.42 192.82 85.6 189.7C82.69 187.33 79.06 185.28 74.43 183.66C67.02 181.06 59.86 180.22 53.03 180.22C50.08 180.22 47.2 180.38 44.38 180.61C40.85 180.91 37.42 181.34 34.11 181.75C33.05 181.88 32.01 182.01 30.98 182.14C26.82 182.64 22.9 183.03 19.19 183.03C19.1 183.03 19 183.03 18.91 183.03C12.36 182.99 6.55998 181.69 1.51998 177.48"
          fill="url(#paint28_linear_573_35549)"
        />
        <path
          d="M1.50998 180.54V182.96C1.84998 183.31 2.17998 183.64 2.52998 183.95C7.72998 187.74 13.61 188.93 19.97 188.97C20.07 188.97 20.17 188.97 20.27 188.97C24.11 188.97 28.15 188.56 32.31 188.06C33.36 187.93 34.42 187.8 35.48 187.67C38.77 187.26 42.15 186.84 45.63 186.54C48.4 186.31 51.23 186.15 54.1 186.15C60.75 186.15 67.66 186.97 74.79 189.46C79.81 191.22 83.57 193.49 86.48 196.14C90.9 199.43 93.77 203.41 95.88 207.81C97.57 211.33 98.76 215.11 99.88 218.97C100.13 219.82 100.37 220.68 100.61 221.54C101.47 224.58 102.35 227.65 103.44 230.72C105.93 237.78 109.53 244.75 116.58 250.91C116.69 250.99 116.8 251.08 116.91 251.16C126.02 258.1 139.85 263.83 161.61 267.3C167.29 268.21 172.64 268.82 177.69 269.17L180.41 267.4C174.68 267.09 168.54 266.44 161.95 265.39C140.39 261.95 126.93 256.31 118.19 249.65C115.62 247.69 113.45 245.64 111.6 243.52C107.15 238.44 104.52 232.95 102.56 227.42C101.47 224.35 100.6 221.28 99.74 218.23C99.5 217.37 99.25 216.52 99.01 215.66C97.89 211.81 96.7 208.02 95.01 204.5C93.3 200.94 91.09 197.65 87.97 194.78C84.86 191.93 80.85 189.49 75.51 187.63C68.1 185.03 60.94 184.2 54.11 184.2C51.16 184.2 48.27 184.36 45.46 184.59C41.93 184.89 38.5 185.32 35.19 185.73C34.13 185.86 33.09 185.99 32.06 186.12C27.9 186.62 23.98 187.01 20.27 187.01C20.17 187.01 20.08 187.01 19.99 187.01C12.97 186.97 6.80998 185.47 1.52998 180.51"
          fill="url(#paint29_linear_573_35549)"
        />
        <path
          d="M1.50997 182.96V185.72C7.15997 190.73 13.7 192.23 20.84 192.27C20.94 192.27 21.05 192.27 21.15 192.27C24.99 192.27 29.02 191.86 33.18 191.36C34.23 191.23 35.29 191.1 36.35 190.97C39.64 190.56 43.02 190.14 46.5 189.84C49.27 189.61 52.1 189.45 54.97 189.45C61.62 189.45 68.53 190.27 75.66 192.76C82.33 195.1 86.78 198.33 89.95 202.18C93.04 205.03 95.23 208.3 96.92 211.84C98.61 215.36 99.8 219.14 100.92 223C101.17 223.85 101.41 224.71 101.66 225.57C102.52 228.61 103.4 231.68 104.48 234.76C106.42 240.25 109.03 245.7 113.42 250.75C114.73 252.02 116.18 253.27 117.77 254.48C126.88 261.42 140.71 267.15 162.48 270.62C166.24 271.22 169.85 271.69 173.33 272.04L175.94 270.34C171.78 269.98 167.41 269.44 162.82 268.71C141.26 265.27 127.8 259.63 119.06 252.97C118.18 252.3 117.35 251.62 116.57 250.94C109.52 244.77 105.93 237.8 103.43 230.75C102.34 227.68 101.47 224.61 100.6 221.57C100.36 220.71 100.11 219.86 99.87 219C98.75 215.15 97.56 211.37 95.87 207.84C93.76 203.44 90.89 199.46 86.47 196.17C83.75 194.15 80.45 192.39 76.37 190.96C68.96 188.36 61.8 187.53 54.97 187.53C52.02 187.53 49.13 187.69 46.32 187.92C42.79 188.22 39.36 188.65 36.05 189.06C34.99 189.19 33.95 189.32 32.92 189.45C28.77 189.95 24.85 190.34 21.15 190.34C21.05 190.34 20.95 190.34 20.85 190.34C13.89 190.3 7.77997 188.83 2.51997 183.97C2.17997 183.65 1.83997 183.32 1.49997 182.98"
          fill="url(#paint30_linear_573_35549)"
        />
        <path
          d="M1.50998 185.82V186.08C1.50998 186.99 1.52998 187.9 1.57998 188.81C2.13998 189.36 2.71998 189.88 3.29998 190.36C8.77998 194.87 15.06 196.25 21.91 196.29C22.01 196.29 22.11 196.29 22.21 196.29C26.05 196.29 30.09 195.88 34.25 195.38C35.3 195.25 36.36 195.12 37.42 194.99C40.71 194.58 44.09 194.16 47.57 193.86C50.34 193.63 53.17 193.47 56.04 193.47C62.69 193.47 69.6 194.29 76.73 196.78C82.58 198.83 86.73 201.58 89.81 204.82C92.05 207.19 93.73 209.82 95.08 212.64C95.28 213.06 95.48 213.49 95.67 213.93C96.31 215.38 96.87 216.88 97.39 218.42C97.89 219.9 98.36 221.42 98.81 222.96C98.86 223.14 98.92 223.32 98.97 223.51C99.21 224.34 99.45 225.18 99.69 226.04C99.76 226.28 99.83 226.52 99.89 226.76C100.3 228.19 100.71 229.64 101.16 231.1C101.56 232.43 101.99 233.76 102.46 235.09C102.49 235.18 102.53 235.28 102.56 235.37C103.58 238.24 104.78 241.12 106.32 243.95C106.43 244.15 106.54 244.36 106.66 244.56C106.72 244.66 106.77 244.76 106.83 244.86C106.9 244.97 106.96 245.09 107.03 245.2C107.21 245.5 107.39 245.8 107.57 246.1C107.97 246.75 108.39 247.4 108.84 248.05C110.83 250.95 113.28 253.77 116.36 256.45C117.15 257.14 117.98 257.82 118.86 258.49C127.97 265.43 141.8 271.16 163.56 274.63C165.17 274.89 166.74 275.12 168.3 275.33L170.8 273.7C168.56 273.42 166.25 273.1 163.9 272.72C142.34 269.28 128.88 263.64 120.14 256.98C117.53 254.99 115.33 252.9 113.45 250.74C109.06 245.69 106.45 240.24 104.51 234.75C103.42 231.68 102.55 228.61 101.69 225.56C101.45 224.7 101.2 223.85 100.95 222.99C99.83 219.14 98.64 215.36 96.95 211.83C95.25 208.29 93.07 205.02 89.98 202.17C86.86 199.29 82.83 196.84 77.46 194.95C70.05 192.35 62.89 191.51 56.06 191.51C53.11 191.51 50.23 191.67 47.41 191.9C43.88 192.2 40.45 192.63 37.14 193.04C36.08 193.17 35.04 193.3 34.01 193.43C29.85 193.93 25.93 194.32 22.23 194.32C22.13 194.32 22.04 194.32 21.94 194.32C14.41 194.28 7.87998 192.56 2.34998 186.7C2.07998 186.41 1.80998 186.11 1.53998 185.8"
          fill="url(#paint31_linear_573_35549)"
        />
        <path
          d="M94.4 189.32C96.08 192.91 98.22 196.43 101.15 199.77C102.44 201.01 103.84 202.22 105.39 203.4C112.1 208.51 121.37 212.96 134.48 216.34C133.3 215.34 132.16 214.35 131.07 213.39C120.24 210.23 112.43 206.28 106.67 201.89C104.86 200.51 103.24 199.08 101.79 197.61C98.77 194.97 96.37 192.18 94.4 189.32ZM42.58 136.44C39.63 136.44 36.74 136.6 33.93 136.83C30.4 137.13 26.97 137.56 23.66 137.97C22.6 138.1 21.56 138.23 20.53 138.36C20.22 138.4 19.9 138.44 19.59 138.47C17.1 139.25 14.81 140.12 12.76 141.06C15.37 140.9 18.05 140.61 20.79 140.28C21.84 140.15 22.9 140.02 23.96 139.89C27.25 139.48 30.63 139.06 34.11 138.76C36.88 138.53 39.71 138.37 42.58 138.37C49.23 138.37 56.14 139.19 63.27 141.68C69.89 144 74.33 147.21 77.5 151.02C78.92 152.32 80.15 153.72 81.24 155.18C81.05 154.75 80.85 154.32 80.65 153.89C79.55 151.6 78.25 149.44 76.6 147.45C74.51 145.68 71.86 143.68 68.68 141.81C67.24 141.11 65.68 140.45 63.98 139.86C56.57 137.26 49.41 136.43 42.58 136.43"
          fill="url(#paint32_linear_573_35549)"
        />
        <path
          d="M93.13 187.37C95.62 194.28 99.2 201.1 106.11 207.14C106.22 207.23 106.34 207.32 106.46 207.41C114.07 213.21 124.97 218.15 141.03 221.65C139.81 220.71 138.63 219.77 137.48 218.83C123.82 215.46 114.41 210.98 107.74 205.89C105.17 203.93 103 201.88 101.15 199.76C98.22 196.42 96.08 192.9 94.4 189.31C93.96 188.66 93.53 188.01 93.13 187.36M43.64 140.45C40.69 140.45 37.81 140.61 34.99 140.84C31.46 141.14 28.03 141.57 24.72 141.98C23.66 142.11 22.62 142.24 21.59 142.37C17.44 142.87 13.52 143.26 9.81997 143.26C9.71997 143.26 9.61997 143.26 9.51997 143.26C9.20997 143.26 8.89997 143.26 8.58997 143.25C7.62997 143.84 6.74997 144.44 5.96997 145.05C7.12997 145.15 8.30997 145.2 9.50997 145.2C9.60997 145.2 9.70997 145.2 9.80997 145.2C13.65 145.2 17.69 144.79 21.85 144.29C22.9 144.16 23.96 144.03 25.02 143.9C28.31 143.49 31.69 143.07 35.17 142.77C37.94 142.54 40.77 142.38 43.64 142.38C50.29 142.38 57.2 143.2 64.33 145.69C69.35 147.45 73.12 149.72 76.02 152.38C78.45 154.19 80.41 156.21 82.03 158.39V157.16C81.77 156.49 81.5 155.84 81.22 155.19C80.14 153.73 78.91 152.33 77.48 151.03C74.37 148.18 70.36 145.75 65.03 143.88C57.62 141.28 50.45 140.44 43.63 140.44"
          fill="url(#paint33_linear_573_35549)"
        />
        <path
          d="M92.57 186.43C93.03 187.94 93.51 189.46 94.05 190.98C96 196.48 98.61 201.93 103 206.98C104.31 208.25 105.75 209.49 107.33 210.7C115.69 217.07 128.03 222.41 146.82 225.94C145.54 225.04 144.29 224.13 143.07 223.21C126.83 219.75 116.03 214.84 108.62 209.19C107.74 208.52 106.91 207.84 106.11 207.14C99.21 201.09 95.62 194.28 93.13 187.37C92.94 187.06 92.75 186.74 92.57 186.43ZM44.53 143.74C41.58 143.74 38.69 143.9 35.88 144.13C32.35 144.43 28.92 144.86 25.61 145.27C24.55 145.4 23.51 145.53 22.48 145.66C18.32 146.16 14.4 146.55 10.69 146.55C10.6 146.55 10.5 146.55 10.41 146.55C8.43997 146.54 6.53997 146.41 4.69997 146.13C4.08997 146.69 3.56997 147.26 3.12997 147.82C5.46997 148.28 7.89997 148.47 10.4 148.49C10.5 148.49 10.6 148.49 10.7 148.49C14.54 148.49 18.58 148.08 22.74 147.58C23.79 147.45 24.85 147.32 25.91 147.19C29.2 146.78 32.58 146.36 36.06 146.06C38.83 145.83 41.65 145.67 44.53 145.67C51.18 145.67 58.09 146.49 65.22 148.98C71.88 151.32 76.33 154.55 79.51 158.39C80.43 159.24 81.28 160.13 82.05 161.06V158.39C80.43 156.21 78.46 154.19 76.04 152.38C73.32 150.36 70.01 148.6 65.93 147.17C58.52 144.57 51.36 143.74 44.53 143.74Z"
          fill="url(#paint34_linear_573_35549)"
        />
        <path
          d="M92.39 186.12C93.18 188.91 94 191.72 95 194.54C97.26 200.94 100.43 207.27 106.27 213C106.95 213.58 107.66 214.15 108.4 214.72C117.51 221.66 131.34 227.39 153.11 230.86C153.64 230.95 154.17 231.03 154.7 231.11C153.29 230.25 151.89 229.37 150.52 228.46C130.66 224.99 118.03 219.57 109.69 213.21C107.08 211.22 104.89 209.14 103.01 206.99C98.61 201.93 96 196.49 94.06 190.99C93.52 189.47 93.04 187.95 92.58 186.44C92.52 186.34 92.46 186.23 92.4 186.13M45.59 147.76C42.64 147.76 39.75 147.92 36.94 148.15C33.41 148.45 29.98 148.88 26.67 149.29C25.61 149.42 24.57 149.55 23.54 149.68C19.39 150.18 15.47 150.57 11.77 150.57C11.67 150.57 11.57 150.57 11.47 150.57C8.16996 150.55 5.06996 150.21 2.14996 149.34C1.83996 149.96 1.63996 150.58 1.54996 151.2C4.68996 152.13 7.99996 152.49 11.45 152.51C11.55 152.51 11.66 152.51 11.76 152.51C15.6 152.51 19.63 152.1 23.79 151.6C24.84 151.47 25.9 151.34 26.96 151.21C30.25 150.8 33.63 150.38 37.11 150.08C39.88 149.85 42.71 149.69 45.58 149.69C52.23 149.69 59.14 150.51 66.27 153C71.97 155 76.05 157.65 79.1 160.79C80.21 161.69 81.21 162.65 82.13 163.64C82.07 162.81 82.04 161.95 82.04 161.06C81.27 160.14 80.42 159.25 79.5 158.4C76.38 155.52 72.35 153.07 66.98 151.19C59.57 148.59 52.41 147.76 45.58 147.76"
          fill="url(#paint35_linear_573_35549)"
        />
        <path
          d="M92.23 185.83C92.33 186.15 92.42 186.48 92.51 186.8C92.76 187.65 93 188.51 93.24 189.37C94.1 192.41 94.98 195.48 96.06 198.56C98.02 204.09 100.65 209.57 105.09 214.66C106.38 215.9 107.79 217.11 109.33 218.29C118.44 225.23 132.27 230.96 154.03 234.43C157.08 234.92 160.04 235.32 162.91 235.64C161.26 234.83 159.63 233.97 158.01 233.06C156.81 232.89 155.6 232.71 154.38 232.51C132.82 229.07 119.36 223.43 110.62 216.77C109.02 215.55 107.57 214.29 106.26 213C100.43 207.27 97.26 200.94 94.99 194.54C93.99 191.72 93.18 188.91 92.38 186.12C92.32 186.02 92.27 185.93 92.21 185.83M46.54 151.32C43.59 151.32 40.71 151.48 37.89 151.71C34.36 152.01 30.93 152.44 27.62 152.85C26.56 152.98 25.52 153.11 24.49 153.24C20.34 153.74 16.42 154.13 12.72 154.13C12.62 154.13 12.52 154.13 12.42 154.13C8.51996 154.11 4.87996 153.64 1.51996 152.37V154.44C4.94996 155.61 8.58996 156.04 12.41 156.07C12.51 156.07 12.62 156.07 12.72 156.07C16.56 156.07 20.59 155.66 24.75 155.16C25.8 155.03 26.86 154.9 27.92 154.77C31.21 154.36 34.59 153.94 38.07 153.64C40.84 153.41 43.67 153.25 46.54 153.25C53.19 153.25 60.1 154.07 67.23 156.56C73.86 158.88 78.29 162.09 81.47 165.91C81.84 166.25 82.2 166.6 82.55 166.95C82.36 165.89 82.22 164.79 82.14 163.64C81.23 162.65 80.22 161.69 79.11 160.79C76.2 158.41 72.56 156.37 67.94 154.75C60.53 152.15 53.36 151.31 46.54 151.31"
          fill="url(#paint36_linear_573_35549)"
        />
        <path
          d="M91.88 185.2C92.42 186.81 92.91 188.45 93.39 190.1C93.64 190.95 93.88 191.81 94.12 192.67C94.98 195.71 95.86 198.78 96.94 201.85C99.43 208.88 103 215.83 110.01 221.98C110.14 222.08 110.28 222.19 110.41 222.29C119.52 229.23 133.35 234.95 155.11 238.43C162.06 239.54 168.53 240.21 174.56 240.5C172.55 239.79 170.51 239.01 168.45 238.14C164.32 237.78 159.99 237.24 155.45 236.52C133.89 233.08 120.43 227.44 111.69 220.78C109.12 218.82 106.95 216.77 105.09 214.65C100.65 209.57 98.01 204.09 96.06 198.55C94.97 195.48 94.1 192.41 93.24 189.36C93 188.5 92.75 187.65 92.51 186.79C92.42 186.47 92.32 186.14 92.23 185.82C92.11 185.61 92 185.4 91.88 185.19M47.61 155.33C44.66 155.33 41.78 155.49 38.96 155.72C35.43 156.02 32 156.45 28.69 156.86C27.63 156.99 26.59 157.12 25.56 157.25C21.4 157.75 17.48 158.14 13.77 158.14C13.67 158.14 13.58 158.14 13.48 158.14C9.15997 158.12 5.16997 157.54 1.49997 155.95V158.06C5.23997 159.52 9.23997 160.05 13.46 160.08C13.56 160.08 13.66 160.08 13.76 160.08C17.6 160.08 21.63 159.67 25.8 159.17C26.85 159.04 27.91 158.91 28.97 158.78C32.26 158.37 35.64 157.95 39.12 157.65C41.89 157.42 44.72 157.26 47.59 157.26C54.24 157.26 61.15 158.08 68.28 160.57C73.32 162.34 77.1 164.62 80.01 167.29C81.21 168.18 82.29 169.13 83.27 170.12C82.97 169.11 82.72 168.06 82.52 166.95C82.17 166.6 81.81 166.25 81.44 165.91C78.33 163.06 74.32 160.62 68.98 158.75C61.57 156.15 54.41 155.31 47.58 155.31"
          fill="url(#paint37_linear_573_35549)"
        />
        <path
          d="M89.04 180.28C89.55 181.15 90.02 182.04 90.45 182.95C92.14 186.47 93.33 190.25 94.45 194.11C94.7 194.96 94.94 195.82 95.18 196.68C96.04 199.72 96.92 202.79 98 205.87C99.95 211.38 102.57 216.85 106.99 221.92C108.29 223.18 109.72 224.41 111.29 225.6C120.4 232.54 134.23 238.27 155.99 241.74C165.72 243.3 174.51 243.99 182.46 243.99C183.68 243.99 184.88 243.97 186.06 243.94C183.81 243.39 181.47 242.75 179.06 242.01C172.14 241.84 164.58 241.15 156.33 239.83C134.77 236.39 121.31 230.75 112.57 224.09C111.67 223.4 110.82 222.7 110.01 222C103.01 215.85 99.43 208.9 96.94 201.87C95.85 198.8 94.98 195.73 94.12 192.69C93.88 191.83 93.63 190.98 93.39 190.12C92.91 188.47 92.42 186.83 91.88 185.22C91.17 183.91 90.53 182.59 89.95 181.26C89.64 180.95 89.34 180.63 89.05 180.3M48.49 158.63C45.54 158.63 42.66 158.79 39.84 159.02C36.31 159.32 32.88 159.75 29.57 160.16C28.51 160.29 27.47 160.42 26.44 160.55C22.28 161.05 18.36 161.44 14.65 161.44C14.56 161.44 14.46 161.44 14.37 161.44C9.69998 161.41 5.40998 160.74 1.50998 158.84V161C5.49998 162.74 9.79998 163.36 14.35 163.38C14.45 163.38 14.55 163.38 14.65 163.38C18.49 163.38 22.53 162.97 26.69 162.47C27.74 162.34 28.8 162.21 29.86 162.08C33.15 161.67 36.53 161.25 40.01 160.95C42.78 160.72 45.61 160.56 48.48 160.56C55.13 160.56 62.04 161.38 69.17 163.87C75.82 166.2 80.26 169.42 83.44 173.25C84.17 173.92 84.85 174.62 85.49 175.34C84.61 173.76 83.86 172.02 83.29 170.11C82.31 169.12 81.22 168.18 80.03 167.28C77.3 165.24 73.98 163.48 69.89 162.04C62.48 159.44 55.32 158.6 48.49 158.6"
          fill="url(#paint38_linear_573_35549)"
        />
        <path
          d="M1.50998 162.3V164.52C5.78998 166.64 10.46 167.37 15.42 167.4C15.52 167.4 15.63 167.4 15.73 167.4C19.57 167.4 23.6 166.99 27.76 166.49C28.81 166.36 29.87 166.23 30.93 166.1C34.22 165.69 37.6 165.27 41.08 164.97C43.85 164.74 46.68 164.58 49.55 164.58C56.2 164.58 63.11 165.4 70.24 167.89C77.88 170.57 82.61 174.43 85.85 179.04C86.9 180.54 87.8 182.11 88.58 183.75C89.39 185.44 90.08 187.19 90.71 189.01C91.2 190.42 91.65 191.86 92.09 193.33C92.22 193.76 92.34 194.19 92.47 194.62C92.71 195.45 92.95 196.29 93.19 197.14C93.26 197.4 93.34 197.65 93.41 197.91C93.8 199.28 94.2 200.68 94.63 202.08C95.07 203.54 95.55 205.01 96.06 206.48C96.49 207.69 96.95 208.9 97.45 210.11C97.53 210.31 97.62 210.5 97.7 210.7C97.8 210.94 97.91 211.18 98.01 211.41C98.23 211.9 98.45 212.38 98.69 212.87C100.11 215.81 101.85 218.72 104.07 221.53C106.29 224.34 108.99 227.05 112.35 229.6C121.46 236.54 135.29 242.26 157.05 245.74C166.78 247.3 175.58 247.99 183.52 247.99C190.09 247.99 196.08 247.52 201.54 246.67C199.35 246.43 196.84 246.09 194.1 245.61C190.76 245.9 187.24 246.05 183.52 246.05C175.7 246.05 167.01 245.37 157.39 243.83C135.83 240.39 122.37 234.75 113.63 228.09C111.04 226.12 108.86 224.05 106.99 221.91C102.57 216.84 99.95 211.38 98 205.86C96.91 202.79 96.04 199.72 95.18 196.67C94.94 195.81 94.69 194.96 94.45 194.1C93.33 190.24 92.14 186.46 90.45 182.94C90.01 182.03 89.55 181.14 89.04 180.27C87.72 178.8 86.52 177.17 85.5 175.35C84.86 174.63 84.18 173.93 83.45 173.26C80.34 170.39 76.31 167.95 70.96 166.07C63.55 163.47 56.39 162.64 49.56 162.64C46.61 162.64 43.72 162.8 40.91 163.03C37.38 163.33 33.95 163.76 30.64 164.17C29.58 164.3 28.54 164.43 27.51 164.56C23.36 165.06 19.44 165.45 15.74 165.45C15.64 165.45 15.54 165.45 15.44 165.45C10.34 165.42 5.69998 164.63 1.51998 162.3"
          fill="url(#paint39_linear_573_35549)"
        />
        <path
          d="M97 186.87C98.47 188.68 100.14 190.45 102.09 192.15C102.22 192.25 102.35 192.35 102.48 192.45C106.67 195.64 111.87 198.58 118.37 201.16C117.39 200.13 116.86 199.54 116.86 199.54C116.86 199.54 115.92 198.6 113.46 196.86C109.69 195.01 106.49 193.03 103.76 190.94C103.61 190.83 103.47 190.72 103.32 190.6C101.31 189.47 99.15 188.26 97 186.87Z"
          fill="url(#paint40_linear_573_35549)"
        />
        <path
          d="M93.92 184.72C95.33 187.25 97.01 189.72 99.1 192.11C100.39 193.35 101.8 194.56 103.35 195.74C108.32 199.52 114.69 202.95 122.98 205.84C121.69 204.57 120.58 203.45 119.67 202.52C113.49 200.04 108.58 197.24 104.63 194.23C103.74 193.55 102.89 192.86 102.09 192.15C100.15 190.45 98.47 188.68 97 186.87C95.96 186.2 94.93 185.48 93.92 184.72Z"
          fill="url(#paint41_linear_573_35549)"
        />
        <path
          d="M89.95 181.25C90.53 182.58 91.17 183.9 91.88 185.21C91.99 185.42 92.11 185.63 92.23 185.84C92.28 185.94 92.34 186.03 92.4 186.13C92.46 186.23 92.52 186.34 92.58 186.44C92.76 186.75 92.95 187.07 93.14 187.38C93.54 188.03 93.96 188.68 94.41 189.33C96.38 192.19 98.79 194.97 101.8 197.62C102.63 198.35 103.5 199.06 104.43 199.77C110.32 204.26 118.18 208.23 128.88 211.42C127.69 210.34 126.57 209.3 125.54 208.31C117.04 205.42 110.64 202 105.72 198.25C103.15 196.29 100.98 194.24 99.12 192.12C97.03 189.73 95.34 187.26 93.94 184.73C92.56 183.68 91.22 182.53 89.97 181.26M76.6 147.47C78.25 149.47 79.56 151.63 80.65 153.91C80.85 154.33 81.05 154.76 81.24 155.2C81.52 155.85 81.79 156.51 82.05 157.17V152.86C82.05 152.86 80.15 150.49 76.6 147.46"
          fill="url(#paint42_linear_573_35549)"
        />
        <path
          d="M19.53 23.47L17.02 24C16.75 24.33 16.48 24.66 16.23 24.98C12.25 31.85 12.25 38.69 13.95 45.76C14.87 49.58 16.28 53.49 17.83 57.51C18.21 58.5 18.6 59.49 18.99 60.49C20.2 63.59 21.44 66.76 22.57 70.06C25.56 78.79 27.77 88.3 26.55 99.12C25.76 106.13 23.53 111.25 20.39 115.29C19.4 117 18.27 118.55 17.03 119.96C17.42 119.67 17.81 119.38 18.19 119.07C20.74 117.06 23.01 114.79 24.89 112.05C26.72 108.55 28.01 104.4 28.58 99.34C29.85 88.1 27.55 78.26 24.51 69.39C23.36 66.04 22.1 62.81 20.89 59.71C20.5 58.72 20.12 57.74 19.75 56.77C18.2 52.76 16.83 48.96 15.95 45.28C14.19 37.96 14.39 31.18 19.07 24.33C19.08 24.31 19.1 24.29 19.11 24.28C19.25 24.01 19.4 23.74 19.55 23.47"
          fill="url(#paint43_linear_573_35549)"
        />
        <path
          d="M17.02 24L14.93 24.44C13.81 25.66 12.85 26.86 12.03 28.07C11.7 28.55 11.39 29.03 11.11 29.51C8.41998 35.55 8.59998 41.59 10.09 47.81C11.01 51.63 12.42 55.54 13.97 59.56C14.35 60.55 14.74 61.54 15.13 62.54C16.34 65.64 17.58 68.81 18.71 72.11C21.7 80.84 23.91 90.35 22.69 101.17C22.08 106.54 20.64 110.8 18.58 114.32C17.06 117.64 15.09 120.41 12.78 122.8C14.25 121.89 15.67 120.96 17.02 119.96C18.26 118.54 19.39 116.99 20.38 115.29C22.55 111.56 24.08 107.05 24.72 101.4C25.99 90.16 23.69 80.32 20.65 71.45C19.5 68.1 18.24 64.87 17.03 61.77C16.64 60.78 16.26 59.8 15.89 58.83C14.34 54.82 12.97 51.02 12.09 47.34C10.33 40.02 10.53 33.24 15.21 26.39C15.53 25.92 15.87 25.45 16.24 24.98C16.49 24.65 16.76 24.33 17.03 24"
          fill="url(#paint44_linear_573_35549)"
        />
        <path
          d="M14.94 24.44L13.25 24.8C13.02 24.85 12.79 24.91 12.57 24.98C11.29 26.24 10.17 27.48 9.21998 28.72C5.23998 35.58 5.23998 42.43 6.93998 49.5C7.85998 53.32 9.26998 57.23 10.82 61.25C11.2 62.24 11.59 63.23 11.98 64.23C13.19 67.33 14.43 70.5 15.56 73.8C18.55 82.53 20.76 92.04 19.53 102.85C18.74 109.86 16.52 114.97 13.37 119.02C11.98 121.41 10.33 123.47 8.48998 125.32C9.95998 124.49 11.4 123.66 12.79 122.8C15.09 120.41 17.07 117.65 18.59 114.32C20.04 111.14 21.07 107.45 21.56 103.08C22.83 91.84 20.53 82 17.49 73.13C16.34 69.78 15.08 66.55 13.87 63.45C13.48 62.46 13.1 61.48 12.73 60.51C11.18 56.5 9.80998 52.7 8.92998 49.02C7.29998 42.21 7.34998 35.88 11.13 29.52C11.42 29.04 11.72 28.56 12.05 28.08C12.87 26.88 13.83 25.67 14.95 24.45"
          fill="url(#paint45_linear_573_35549)"
        />
        <path
          d="M12.56 24.98C11.68 25.23 10.84 25.55 10.06 25.92C7.85999 27.95 6.11999 29.96 4.75999 31.94C4.70999 32.02 4.64999 32.1 4.59999 32.18C1.38999 38.57 1.49999 44.96 3.07999 51.54C3.99999 55.36 5.40999 59.27 6.95999 63.29C7.33999 64.28 7.72999 65.27 8.11999 66.27C9.32999 69.37 10.57 72.54 11.7 75.84C14.69 84.57 16.9 94.08 15.68 104.89C14.99 110.96 13.24 115.6 10.74 119.37C9.15999 122.47 7.16999 125.06 4.87999 127.31C5.10999 127.18 5.34999 127.05 5.57999 126.92C6.38999 126.47 7.19999 126.03 7.98999 125.58C8.15999 125.49 8.31999 125.39 8.48999 125.3C10.34 123.45 11.99 121.38 13.37 119C15.54 115.27 17.07 110.76 17.71 105.12C18.98 93.88 16.68 84.04 13.64 75.17C12.49 71.82 11.23 68.59 10.02 65.49C9.62999 64.5 9.24999 63.52 8.87999 62.55C7.32999 58.54 5.95999 54.74 5.07999 51.06C3.31999 43.74 3.51999 36.96 8.19999 30.11C8.51999 29.64 8.85999 29.17 9.21999 28.7C10.18 27.46 11.29 26.22 12.57 24.96"
          fill="url(#paint46_linear_573_35549)"
        />
        <path
          d="M0.999969 49.73V58.11C1.74997 60.41 2.61997 62.75 3.52997 65.12C3.90997 66.11 4.29997 67.1 4.68997 68.1C5.89997 71.2 7.13997 74.37 8.26997 77.67C11.26 86.4 13.47 95.91 12.25 106.73C11.46 113.75 9.22997 118.87 6.07997 122.92C4.64997 125.38 2.92997 127.51 1.00997 129.4V129.51C2.30997 128.77 3.59997 128.05 4.87997 127.34C7.16997 125.08 9.16997 122.49 10.74 119.4C12.49 115.96 13.73 111.9 14.28 106.98C15.55 95.74 13.25 85.9 10.21 77.03C9.05997 73.68 7.79997 70.45 6.58997 67.35C6.19997 66.36 5.81997 65.38 5.44997 64.41C3.89997 60.4 2.52997 56.6 1.64997 52.92C1.38997 51.85 1.17997 50.8 1.00997 49.76M10.06 25.92C4.50997 28.58 1.28997 34.25 1.01997 40.71C1.56997 37.85 2.69997 35.03 4.58997 32.19C4.63997 32.11 4.69997 32.03 4.74997 31.95C6.10997 29.96 7.84997 27.96 10.05 25.93"
          fill="url(#paint47_linear_573_35549)"
        />
        <path
          d="M0.999969 64.9V70.57C2.15997 73.54 3.33997 76.57 4.41997 79.73C7.40997 88.46 9.61997 97.97 8.38997 108.79C7.78997 114.11 6.35997 118.34 4.33997 121.83C3.39997 123.92 2.26997 125.79 0.999969 127.49V129.4C2.91997 127.51 4.63997 125.38 6.06997 122.92C8.24997 119.18 9.78997 114.66 10.43 109.01C11.7 97.77 9.39997 87.93 6.35997 79.06C5.20997 75.71 3.94997 72.48 2.73997 69.38C2.34997 68.39 1.96997 67.41 1.59997 66.44C1.39997 65.92 1.19997 65.41 1.00997 64.9"
          fill="url(#paint48_linear_573_35549)"
        />
        <path
          d="M0.99998 74.74V80.66C1.08998 80.91 1.16998 81.16 1.25998 81.41C4.24998 90.14 6.45998 99.65 5.22998 110.47C4.60998 115.95 3.11998 120.27 0.98998 123.83V127.49C2.25998 125.79 3.38998 123.92 4.32998 121.83C5.74998 118.68 6.75998 115.02 7.24998 110.7C8.51998 99.46 6.21998 89.62 3.17998 80.75C2.47998 78.7 1.72998 76.7 0.97998 74.74"
          fill="url(#paint49_linear_573_35549)"
        />
        <path
          d="M0.999969 88.06V97.39C1.74997 102.16 1.97997 107.18 1.37997 112.52C1.27997 113.42 1.14997 114.3 0.999969 115.14V122.68C2.14997 119.81 2.97997 116.54 3.40997 112.76C4.43997 103.7 3.13997 95.54 0.999969 88.08"
          fill="url(#paint50_linear_573_35549)"
        />
        <path
          d="M15.83 124.67C11.86 127.5 8.08997 130.85 4.84997 135.22C3.86997 136.81 2.93997 138.51 2.08997 140.35C1.70997 141.16 1.34997 142 0.999969 142.86V144.65C1.69997 143.95 2.47997 143.25 3.33997 142.56C3.53997 142.1 3.73997 141.66 3.93997 141.21C4.92997 139.08 6.01997 137.14 7.18997 135.35C9.70997 131.06 12.66 127.6 15.83 124.67ZM43.9 18.33L41.76 18.78C41.24 22.65 41.66 26.56 42.61 30.53C43.53 34.35 44.94 38.26 46.49 42.28C46.87 43.27 47.26 44.26 47.65 45.26C48.86 48.36 50.1 51.53 51.23 54.83C54.22 63.56 56.43 73.07 55.2 83.89C54.4 90.94 52.16 96.08 48.99 100.13C47.5 102.68 45.71 104.86 43.69 106.8C44.56 106.2 45.41 105.58 46.23 104.93C48.82 102.89 51.13 100.57 53.03 97.76C55.13 94.07 56.61 89.63 57.24 84.11C58.51 72.87 56.21 63.03 53.17 54.16C52.02 50.81 50.76 47.58 49.55 44.48C49.16 43.49 48.78 42.51 48.41 41.54C46.86 37.53 45.49 33.73 44.61 30.05C43.64 26.01 43.26 22.14 43.91 18.33"
          fill="url(#paint51_linear_573_35549)"
        />
        <path
          d="M17.52 123.19C17 123.51 16.47 123.83 15.95 124.16C10.49 127.62 5.25997 131.8 0.999969 137.86V141.4C2.16997 139.13 3.46997 137.09 4.84997 135.23C8.09997 130.86 11.86 127.51 15.83 124.68C16.39 124.17 16.95 123.67 17.52 123.19ZM40.3 19.09L38.11 19.55C37.29 23.84 37.69 28.17 38.75 32.58C39.67 36.4 41.08 40.31 42.63 44.33C43.01 45.32 43.4 46.31 43.79 47.31C45 50.41 46.24 53.58 47.37 56.88C50.36 65.61 52.57 75.12 51.35 85.94C50.75 91.26 49.32 95.49 47.3 98.99C45.35 103.3 42.64 106.67 39.43 109.52C40.9 108.65 42.33 107.75 43.7 106.81C45.71 104.87 47.51 102.68 49 100.14C51.2 96.39 52.74 91.86 53.39 86.18C54.66 74.94 52.36 65.1 49.32 56.23C48.17 52.88 46.91 49.65 45.7 46.55C45.31 45.56 44.93 44.58 44.56 43.61C43.01 39.6 41.64 35.8 40.76 32.12C39.68 27.62 39.34 23.34 40.32 19.11"
          fill="url(#paint52_linear_573_35549)"
        />
        <path
          d="M18.28 122.56C16.21 123.72 14.14 124.91 12.09 126.21C8.17997 128.69 4.39997 131.53 0.999969 135.16V137.86C5.25997 131.8 10.49 127.62 15.95 124.16C16.47 123.83 17 123.5 17.52 123.19C17.77 122.98 18.02 122.77 18.28 122.56ZM37.42 19.7L35.18 20.17C34.08 24.81 34.45 29.48 35.59 34.26C36.51 38.08 37.92 41.99 39.47 46.01C39.85 47 40.24 47.99 40.63 48.99C41.84 52.09 43.08 55.26 44.21 58.56C47.2 67.29 49.41 76.8 48.18 87.62C47.38 94.67 45.14 99.8 41.97 103.86C40.09 107.07 37.73 109.71 35.06 112C35.38 111.82 35.71 111.64 36.03 111.46C37.18 110.82 38.32 110.17 39.42 109.51C42.62 106.67 45.34 103.3 47.29 98.98C48.71 95.83 49.73 92.17 50.22 87.84C51.49 76.6 49.19 66.76 46.15 57.89C45 54.54 43.74 51.31 42.53 48.21C42.14 47.22 41.76 46.24 41.39 45.27C39.84 41.26 38.47 37.46 37.59 33.78C36.42 28.9 36.12 24.27 37.42 19.7Z"
          fill="url(#paint53_linear_573_35549)"
        />
        <path
          d="M18.57 122.32C18.18 122.54 17.79 122.75 17.4 122.97C14.49 124.58 11.56 126.2 8.66997 128.03C6.02997 129.7 3.44997 131.54 0.999969 133.68V135.16C4.39997 131.53 8.17997 128.69 12.09 126.21C14.14 124.91 16.21 123.72 18.28 122.56C18.38 122.48 18.47 122.4 18.57 122.32ZM33.98 20.42L31.67 20.91C30.15 25.98 30.48 31.08 31.73 36.3C32.65 40.12 34.06 44.03 35.61 48.05C35.99 49.04 36.38 50.03 36.77 51.03C37.98 54.13 39.22 57.3 40.35 60.6C43.34 69.33 45.55 78.84 44.33 89.66C43.65 95.7 41.91 100.33 39.43 104.08C37.38 108.13 34.62 111.31 31.41 114.02C32.15 113.61 32.89 113.2 33.63 112.79C34.12 112.52 34.6 112.25 35.08 111.99C37.75 109.7 40.11 107.06 41.99 103.85C44.19 100.1 45.73 95.57 46.37 89.89C47.64 78.65 45.34 68.81 42.3 59.94C41.15 56.59 39.89 53.36 38.68 50.26C38.29 49.27 37.91 48.29 37.54 47.32C35.99 43.31 34.62 39.51 33.74 35.83C32.46 30.48 32.21 25.43 34 20.42"
          fill="url(#paint54_linear_573_35549)"
        />
        <path
          d="M19.14 121.87C18.11 122.48 17.06 123.07 16 123.66C15.19 124.12 14.37 124.57 13.55 125.02C10.64 126.63 7.70997 128.25 4.81997 130.08C3.52997 130.9 2.24997 131.75 0.999969 132.67V133.68C3.44997 131.54 6.02997 129.7 8.66997 128.03C11.56 126.2 14.49 124.58 17.4 122.97C17.79 122.75 18.18 122.54 18.57 122.32C18.76 122.17 18.95 122.02 19.14 121.87ZM31.03 21.05L28.64 21.56C26.69 27.03 26.97 32.51 28.32 38.14C29.24 41.96 30.65 45.87 32.2 49.89C32.58 50.88 32.97 51.87 33.36 52.87C34.57 55.97 35.81 59.14 36.94 62.44C39.93 71.17 42.14 80.68 40.92 91.5C40.13 98.51 37.9 103.63 34.76 107.67C32.67 111.26 29.99 114.13 26.94 116.61C28.43 115.72 29.93 114.87 31.42 114.04C34.63 111.33 37.39 108.15 39.44 104.1C41.18 100.68 42.4 96.63 42.96 91.73C44.23 80.49 41.93 70.65 38.89 61.78C37.74 58.43 36.48 55.2 35.27 52.1C34.88 51.11 34.5 50.13 34.13 49.16C32.58 45.15 31.21 41.35 30.33 37.67C28.94 31.9 28.77 26.46 31.06 21.07"
          fill="url(#paint55_linear_573_35549)"
        />
        <path
          d="M27.81 21.72L25.31 22.25C22.79 28.16 23 34.09 24.46 40.18C25.38 44 26.79 47.91 28.34 51.93C28.72 52.92 29.11 53.91 29.5 54.91C30.71 58.01 31.95 61.18 33.08 64.48C36.07 73.21 38.28 82.72 37.05 93.54C36.44 98.89 35.01 103.14 32.96 106.65C30.65 111.73 27.27 115.5 23.29 118.64C20.09 121.17 16.5 123.28 12.82 125.34C12.01 125.79 11.19 126.25 10.37 126.7C7.45999 128.31 4.52999 129.93 1.63999 131.76C1.41999 131.9 1.20999 132.04 0.98999 132.18V132.67C2.23999 131.76 3.51999 130.9 4.80999 130.08C7.69999 128.25 10.63 126.63 13.54 125.02C14.36 124.57 15.18 124.11 15.99 123.66C17.05 123.07 18.09 122.47 19.13 121.87C20.96 120.44 22.85 119.14 24.75 117.93C25.47 117.47 26.2 117.03 26.93 116.59C29.98 114.11 32.66 111.24 34.75 107.65C36.92 103.92 38.45 99.41 39.09 93.76C40.36 82.52 38.06 72.68 35.02 63.81C33.87 60.46 32.61 57.23 31.4 54.13C31.01 53.14 30.63 52.16 30.26 51.19C28.71 47.18 27.34 43.38 26.46 39.7C24.96 33.44 24.88 27.57 27.82 21.72"
          fill="url(#paint56_linear_573_35549)"
        />
        <path
          d="M25.26 22.26L22.66 22.81C19.61 29.1 19.74 35.38 21.3 41.86C22.22 45.68 23.63 49.59 25.18 53.61C25.56 54.6 25.95 55.59 26.34 56.59C27.55 59.69 28.79 62.86 29.92 66.16C32.91 74.89 35.12 84.4 33.9 95.22C33.11 102.25 30.88 107.37 27.72 111.41C25.53 115.16 22.69 118.13 19.46 120.68C16.26 123.21 12.67 125.32 8.98998 127.38C8.17998 127.84 7.35998 128.29 6.53998 128.74C4.69998 129.76 2.84998 130.78 1.00998 131.85V132.17C1.22998 132.03 1.43998 131.89 1.65998 131.75C4.54998 129.92 7.47998 128.3 10.39 126.69C11.21 126.24 12.03 125.78 12.84 125.33C16.52 123.27 20.11 121.16 23.31 118.63C27.29 115.49 30.67 111.72 32.98 106.64C34.42 103.47 35.44 99.8 35.93 95.44C37.2 84.2 34.9 74.36 31.86 65.49C30.71 62.14 29.45 58.91 28.24 55.81C27.85 54.82 27.47 53.84 27.1 52.87C25.55 48.86 24.18 45.06 23.3 41.38C21.7 34.71 21.72 28.49 25.28 22.26"
          fill="url(#paint57_linear_573_35549)"
        />
        <path
          d="M22.28 22.89L19.53 23.47C19.38 23.74 19.23 24.01 19.09 24.28C15.75 30.76 15.84 37.23 17.44 43.9C18.36 47.72 19.77 51.63 21.32 55.65C21.7 56.64 22.09 57.63 22.48 58.63C23.69 61.73 24.93 64.9 26.06 68.2C29.05 76.93 31.26 86.44 30.03 97.26C29.32 103.5 27.49 108.24 24.88 112.05C23 114.8 20.72 117.06 18.18 119.07C17.8 119.37 17.41 119.67 17.02 119.96C15.67 120.96 14.25 121.89 12.78 122.8C11.39 123.66 9.94997 124.49 8.47997 125.32C8.30997 125.41 8.14997 125.51 7.97997 125.6C7.17997 126.05 6.37997 126.49 5.56997 126.94C5.33997 127.07 5.09997 127.2 4.86997 127.33C3.58997 128.04 2.29997 128.76 0.999969 129.5V131.86C2.83997 130.78 4.68997 129.76 6.52997 128.75C7.34997 128.3 8.16997 127.84 8.97997 127.39C12.66 125.33 16.25 123.22 19.45 120.69C22.69 118.14 25.52 115.17 27.71 111.42C29.89 107.68 31.43 103.16 32.07 97.5C33.34 86.26 31.04 76.42 28 67.55C26.85 64.2 25.59 60.97 24.38 57.87C23.99 56.88 23.61 55.9 23.24 54.93C21.69 50.92 20.32 47.12 19.44 43.44C17.72 36.26 17.87 29.61 22.29 22.9"
          fill="url(#paint58_linear_573_35549)"
        />
        <path
          d="M92.83 87.34C91.72 88.52 90.66 89.79 89.64 91.15C88.66 92.74 87.73 94.45 86.88 96.29C81.95 106.89 79.4 121.76 81.04 143.77C81.09 144.5 81.15 145.23 81.22 145.95C81.5 146.2 81.77 146.45 82.05 146.71V117.47C81.54 106.7 86.55 96.95 89.74 92.33C90.98 90.53 91.97 88.89 92.83 87.34Z"
          fill="url(#paint59_linear_573_35549)"
        />
        <path
          d="M94.15 84.83C90.04 88.23 86.27 92.4 83.21 97.93C83.15 98.06 83.08 98.2 83.02 98.33C78.35 108.38 75.81 122.27 76.96 142.44C77.68 142.98 78.4 143.55 79.11 144.13C77.71 123.17 80.25 109.12 84.87 99.19C86.28 96.16 87.88 93.5 89.64 91.14C90.66 89.77 91.72 88.51 92.83 87.33C93.3 86.47 93.73 85.64 94.14 84.82"
          fill="url(#paint60_linear_573_35549)"
        />
        <path
          d="M94.79 83.52C90.36 86.53 86.15 90.13 82.57 94.96C81.6 96.53 80.69 98.21 79.85 100.02C75.49 109.39 72.99 122.09 73.61 140.1C74.31 140.56 75.02 141.04 75.71 141.53C74.91 122.92 77.41 110.12 81.71 100.88C82.19 99.86 82.68 98.88 83.2 97.93C86.25 92.39 90.02 88.23 94.14 84.83C94.36 84.39 94.58 83.95 94.79 83.51"
          fill="url(#paint61_linear_573_35549)"
        />
        <path
          d="M95.06 82.97C94.53 83.29 93.99 83.62 93.46 83.96C87.47 87.76 81.76 92.41 77.3 99.48C76.85 100.31 76.42 101.17 76.01 102.06C72.02 110.65 69.58 122.03 69.68 137.75C70.37 138.12 71.06 138.52 71.74 138.93C71.51 122.81 73.94 111.38 77.87 102.93C79.26 99.93 80.85 97.3 82.58 94.96C86.16 90.13 90.37 86.53 94.8 83.52C94.89 83.34 94.98 83.16 95.06 82.98"
          fill="url(#paint62_linear_573_35549)"
        />
        <path
          d="M95.19 82.7C93.32 83.75 91.46 84.84 89.61 86.01C84.42 89.3 79.44 93.24 75.3 98.83C74.33 100.4 73.42 102.08 72.58 103.89C68.89 111.82 66.53 122.14 66.27 136.04C66.95 136.35 67.63 136.68 68.3 137.03C68.47 122.85 70.82 112.54 74.44 104.75C75.32 102.86 76.28 101.11 77.3 99.49C81.76 92.42 87.47 87.77 93.46 83.97C93.99 83.63 94.52 83.3 95.06 82.98C95.1 82.89 95.15 82.8 95.19 82.71"
          fill="url(#paint63_linear_573_35549)"
        />
        <path
          d="M95.24 82.6C95.24 82.6 95.19 82.63 95.17 82.64C92.26 84.25 89.33 85.87 86.44 87.7C79.84 91.88 73.59 97.1 68.97 105.43C68.89 105.6 68.81 105.76 68.73 105.93C65.37 113.16 63.11 122.37 62.54 134.47C63.21 134.73 63.88 134.99 64.55 135.28C65.06 123.03 67.3 113.87 70.58 106.8C71.97 103.8 73.56 101.17 75.29 98.83C79.43 93.24 84.41 89.31 89.6 86.01C91.45 84.84 93.31 83.75 95.18 82.7C95.2 82.66 95.21 82.63 95.23 82.59"
          fill="url(#paint64_linear_573_35549)"
        />
        <path
          d="M95.3 82.47C94.79 82.76 94.28 83.05 93.77 83.33C92.96 83.79 92.14 84.24 91.32 84.69C88.41 86.3 85.48 87.92 82.59 89.75C77.39 93.05 72.39 97 68.25 102.61C67.29 104.16 66.4 105.83 65.56 107.62C62.46 114.29 60.3 122.66 59.53 133.43C60.2 133.64 60.87 133.87 61.53 134.11C62.25 123.28 64.38 115 67.41 108.49C67.9 107.43 68.42 106.41 68.96 105.44C73.58 97.11 79.84 91.89 86.43 87.71C89.32 85.88 92.25 84.26 95.16 82.65C95.18 82.64 95.21 82.62 95.23 82.61C95.25 82.57 95.27 82.52 95.29 82.48"
          fill="url(#paint65_linear_573_35549)"
        />
        <path
          d="M95.59 81.87C94.05 82.81 92.47 83.72 90.87 84.61C90.06 85.07 89.24 85.52 88.42 85.97C85.51 87.58 82.58 89.2 79.69 91.03C72.79 95.41 66.27 100.91 61.59 109.93C58.85 115.91 56.86 123.23 55.94 132.4C56.61 132.57 57.27 132.75 57.94 132.94C58.84 123.66 60.84 116.38 63.56 110.53C64.95 107.55 66.52 104.93 68.24 102.6C72.39 96.99 77.38 93.04 82.58 89.74C85.47 87.91 88.4 86.29 91.31 84.68C92.13 84.23 92.95 83.77 93.76 83.32C94.27 83.03 94.78 82.75 95.29 82.46C95.39 82.26 95.48 82.06 95.58 81.86"
          fill="url(#paint66_linear_573_35549)"
        />
        <path
          d="M41.94 111.97C38.69 114.49 35.62 117.42 32.91 121.07C31.93 122.66 31 124.37 30.15 126.21C29.32 127.99 28.56 129.89 27.88 131.93C28.64 131.78 29.41 131.64 30.19 131.51C30.75 129.95 31.36 128.47 32.01 127.08C32.98 125 34.04 123.09 35.18 121.34C37.18 117.71 39.47 114.64 41.95 111.98M71.97 12.4L69.97 12.82C70.15 14 70.39 15.19 70.67 16.38C71.59 20.2 73 24.11 74.55 28.13C74.93 29.12 75.32 30.11 75.71 31.11C76.92 34.21 78.16 37.38 79.29 40.68C82.28 49.41 84.49 58.92 83.26 69.74C82.46 76.8 80.22 81.93 77.04 85.98C76.05 87.66 74.93 89.19 73.71 90.59C74.11 90.29 74.51 89.99 74.9 89.68C77.45 87.67 79.74 85.39 81.62 82.63C83.44 79.14 84.72 75 85.29 69.96C86.56 58.72 84.26 48.88 81.22 40.01C80.07 36.66 78.81 33.43 77.6 30.33C77.21 29.34 76.83 28.36 76.46 27.39C74.91 23.38 73.54 19.58 72.66 15.9C72.38 14.72 72.14 13.55 71.97 12.4Z"
          fill="url(#paint67_linear_573_35549)"
        />
        <path
          d="M43.63 110.25C37.14 114.41 31.01 119.61 26.47 127.85C26.41 127.98 26.34 128.12 26.28 128.25C25.63 129.65 25.02 131.13 24.45 132.69C25.24 132.49 26.04 132.3 26.85 132.13C27.26 131.08 27.69 130.08 28.14 129.11C29.55 126.08 31.15 123.42 32.91 121.06C35.62 117.42 38.69 114.48 41.94 111.96C42.5 111.37 43.06 110.79 43.63 110.24M67.95 13.24L65.94 13.66C66.12 15.24 66.42 16.82 66.8 18.41C67.72 22.23 69.13 26.14 70.68 30.16C71.06 31.15 71.45 32.14 71.84 33.14C73.05 36.24 74.29 39.41 75.42 42.71C78.41 51.44 80.62 60.95 79.4 71.77C78.8 77.09 77.37 81.32 75.35 84.82C73.83 88.18 71.84 90.98 69.52 93.39C70.97 92.49 72.37 91.57 73.71 90.58C74.94 89.18 76.06 87.65 77.04 85.97C79.24 82.22 80.78 77.69 81.43 72C82.7 60.76 80.4 50.92 77.36 42.05C76.21 38.7 74.95 35.47 73.74 32.37C73.35 31.38 72.97 30.4 72.6 29.43C71.05 25.42 69.68 21.62 68.8 17.94C68.42 16.35 68.13 14.78 67.96 13.24"
          fill="url(#paint68_linear_573_35549)"
        />
        <path
          d="M44.44 109.48C43 110.3 41.57 111.15 40.15 112.05C34.96 115.34 29.98 119.28 25.84 124.87C24.87 126.44 23.96 128.12 23.12 129.93C22.59 131.06 22.1 132.24 21.63 133.47C22.44 133.23 23.27 133 24.11 132.78C24.39 132.1 24.68 131.44 24.98 130.79C25.46 129.77 25.95 128.79 26.47 127.84C31.01 119.6 37.14 114.4 43.63 110.24C43.9 109.98 44.17 109.73 44.44 109.48ZM64.7 13.93L62.68 14.36C62.84 16.26 63.18 18.18 63.64 20.11C64.56 23.93 65.97 27.84 67.52 31.86C67.9 32.85 68.29 33.84 68.68 34.84C69.89 37.94 71.13 41.11 72.26 44.41C75.25 53.14 77.46 62.65 76.24 73.47C75.45 80.5 73.22 85.62 70.06 89.66C68.68 92.03 67.03 94.09 65.2 95.93C66.68 95.1 68.13 94.26 69.52 93.4C71.84 90.99 73.83 88.19 75.35 84.83C76.77 81.68 77.79 78.02 78.27 73.7C79.54 62.46 77.24 52.62 74.2 43.75C73.05 40.4 71.79 37.17 70.58 34.07C70.19 33.08 69.81 32.1 69.44 31.13C67.89 27.12 66.52 23.32 65.64 19.64C65.18 17.71 64.85 15.81 64.7 13.94"
          fill="url(#paint69_linear_573_35549)"
        />
        <path
          d="M44.72 109.22C42.05 110.7 39.37 112.2 36.72 113.88C30.73 117.68 25.02 122.33 20.56 129.4C20.11 130.23 19.68 131.09 19.27 131.98C18.87 132.83 18.49 133.71 18.13 134.62C18.97 134.32 19.84 134.03 20.71 133.75C20.85 133.44 20.98 133.14 21.12 132.84C22.51 129.84 24.1 127.21 25.83 124.87C29.97 119.28 34.95 115.35 40.14 112.05C41.56 111.15 42.99 110.3 44.43 109.48C44.52 109.39 44.62 109.31 44.71 109.22M60.78 14.76L58.75 15.19C58.86 17.49 59.23 19.81 59.8 22.15C60.72 25.97 62.13 29.88 63.68 33.9C64.06 34.89 64.45 35.88 64.84 36.88C66.05 39.98 67.29 43.15 68.42 46.45C71.41 55.18 73.62 64.69 72.39 75.51C71.7 81.57 69.95 86.22 67.46 89.98C65.88 93.08 63.89 95.67 61.59 97.93C61.82 97.8 62.06 97.67 62.29 97.54C63.1 97.09 63.91 96.65 64.7 96.2C64.87 96.11 65.03 96.01 65.2 95.92C67.04 94.08 68.68 92.02 70.06 89.65C72.24 85.91 73.78 81.39 74.42 75.73C75.69 64.49 73.39 54.65 70.35 45.78C69.2 42.43 67.94 39.2 66.73 36.1C66.34 35.11 65.96 34.13 65.59 33.16C64.04 29.15 62.67 25.35 61.79 21.67C61.22 19.32 60.86 17.02 60.78 14.75"
          fill="url(#paint70_linear_573_35549)"
        />
        <path
          d="M45 108.98C44.69 109.16 44.37 109.33 44.06 109.51C43.25 109.97 42.43 110.42 41.61 110.87C38.7 112.48 35.77 114.1 32.88 115.93C27.69 119.22 22.71 123.15 18.57 128.74C17.6 130.31 16.69 131.99 15.85 133.81C15.54 134.48 15.24 135.17 14.94 135.88C15.82 135.51 16.72 135.16 17.64 134.81C17.66 134.76 17.68 134.72 17.7 134.67C18.58 132.78 19.54 131.03 20.56 129.41C25.02 122.34 30.73 117.69 36.72 113.89C39.37 112.21 42.05 110.71 44.72 109.23C44.81 109.15 44.9 109.06 44.99 108.98M57.33 15.49L55.29 15.92C55.31 18.58 55.71 21.26 56.37 23.98C57.29 27.8 58.7 31.71 60.25 35.73C60.63 36.72 61.02 37.71 61.41 38.71C62.62 41.81 63.86 44.98 64.99 48.28C67.98 57.01 70.19 66.52 68.97 77.34C68.17 84.37 65.94 89.49 62.79 93.54C61.29 96.11 59.48 98.31 57.46 100.27C58.85 99.47 60.23 98.7 61.6 97.94C63.89 95.68 65.89 93.09 67.47 89.99C69.22 86.55 70.45 82.5 71.01 77.57C72.28 66.33 69.98 56.49 66.94 47.62C65.79 44.27 64.53 41.04 63.32 37.94C62.93 36.95 62.55 35.97 62.18 35C60.63 30.99 59.26 27.19 58.38 23.51C57.72 20.77 57.34 18.12 57.36 15.5"
          fill="url(#paint71_linear_573_35549)"
        />
        <path
          d="M45.57 108.47C44.04 109.41 42.47 110.3 40.88 111.19C40.07 111.64 39.25 112.1 38.43 112.55C35.52 114.16 32.59 115.78 29.7 117.61C23.1 121.79 16.85 127.01 12.23 135.34C12.15 135.51 12.07 135.67 11.99 135.84C11.73 136.41 11.47 136.98 11.22 137.58C12.13 137.12 13.08 136.67 14.06 136.24C15.4 133.43 16.91 130.95 18.56 128.73C22.7 123.14 27.68 119.21 32.87 115.92C35.76 114.09 38.69 112.47 41.6 110.86C42.42 110.41 43.24 109.96 44.05 109.5C44.36 109.32 44.68 109.15 44.99 108.97C45.18 108.8 45.37 108.63 45.57 108.46M53.52 16.29L51.45 16.73C51.34 19.8 51.76 22.89 52.51 26.02C53.43 29.84 54.84 33.75 56.39 37.77C56.77 38.76 57.16 39.75 57.55 40.75C58.76 43.85 60 47.02 61.13 50.32C64.12 59.05 66.33 68.56 65.11 79.38C64.5 84.73 63.07 88.98 61.02 92.49C59.17 96.57 56.62 99.81 53.63 102.55C54.9 101.75 56.17 100.99 57.45 100.26C59.48 98.31 61.28 96.1 62.78 93.53C64.96 89.79 66.5 85.27 67.14 79.61C68.41 68.37 66.11 58.53 63.07 49.66C61.92 46.31 60.66 43.08 59.45 39.98C59.06 38.99 58.68 38.01 58.31 37.04C56.76 33.03 55.39 29.23 54.51 25.55C53.75 22.38 53.35 19.31 53.53 16.3"
          fill="url(#paint72_linear_573_35549)"
        />
        <path
          d="M50.44 16.94L48.35 17.38C48.09 20.78 48.51 24.21 49.35 27.7C50.27 31.52 51.68 35.43 53.23 39.45C53.61 40.44 54 41.43 54.39 42.43C55.6 45.53 56.84 48.7 57.97 52C60.96 60.73 63.17 70.24 61.95 81.06C61.16 88.07 58.93 93.19 55.79 97.23C53.6 101 50.75 103.97 47.51 106.53C44.31 109.06 40.72 111.17 37.04 113.23C36.23 113.69 35.41 114.14 34.59 114.59C31.68 116.2 28.75 117.82 25.86 119.65C20.66 122.95 15.67 126.89 11.52 132.5C10.56 134.06 9.65997 135.72 8.82997 137.51C8.55997 138.1 8.28997 138.7 8.02997 139.32C8.97997 138.76 9.96997 138.21 11.02 137.67C11.41 136.86 11.82 136.08 12.24 135.33C16.86 127 23.12 121.78 29.71 117.6C32.6 115.77 35.53 114.15 38.44 112.54C39.26 112.09 40.08 111.63 40.89 111.18C42.48 110.29 44.05 109.39 45.58 108.46C48.1 106.27 50.75 104.38 53.43 102.68C53.5 102.64 53.57 102.59 53.64 102.55C56.63 99.81 59.18 96.57 61.03 92.49C62.47 89.32 63.49 85.65 63.98 81.3C65.25 70.06 62.95 60.22 59.91 51.35C58.76 48 57.5 44.77 56.29 41.67C55.9 40.68 55.52 39.7 55.15 38.73C53.6 34.72 52.23 30.92 51.35 27.24C50.5 23.71 50.11 20.3 50.45 16.96"
          fill="url(#paint73_linear_573_35549)"
        />
        <path
          d="M46.76 17.72L44.63 18.17C44.14 21.99 44.56 25.84 45.5 29.76C46.42 33.58 47.83 37.49 49.38 41.51C49.76 42.5 50.15 43.49 50.54 44.49C51.75 47.59 52.99 50.76 54.12 54.06C57.11 62.79 59.32 72.3 58.1 83.12C57.4 89.28 55.6 93.98 53.04 97.77C51.14 100.58 48.83 102.89 46.24 104.94C45.42 105.59 44.57 106.21 43.7 106.81C42.33 107.75 40.9 108.65 39.43 109.52C38.32 110.18 37.19 110.82 36.04 111.47C35.72 111.65 35.4 111.83 35.07 112.01C34.59 112.28 34.11 112.54 33.62 112.81C32.89 113.22 32.15 113.62 31.4 114.04C29.91 114.87 28.42 115.72 26.92 116.61C26.19 117.05 25.47 117.49 24.74 117.95C22.84 119.16 20.95 120.45 19.12 121.89C18.93 122.04 18.74 122.19 18.55 122.34C18.45 122.42 18.36 122.5 18.26 122.58C18.01 122.79 17.75 123 17.5 123.21C16.93 123.69 16.37 124.19 15.81 124.7C12.63 127.63 9.68997 131.09 7.16997 135.38C6.38997 136.71 5.64997 138.12 4.94997 139.61C4.55997 140.44 4.18997 141.29 3.83997 142.18C4.81997 141.42 5.89997 140.67 7.05997 139.94C8.37997 137.18 9.87997 134.73 11.5 132.54C15.64 126.93 20.63 122.99 25.84 119.69C28.73 117.86 31.66 116.24 34.57 114.63C35.39 114.18 36.21 113.72 37.02 113.27C40.7 111.21 44.29 109.1 47.49 106.57C50.73 104.01 53.58 101.03 55.77 97.27C57.94 93.54 59.47 89.03 60.11 83.38C61.38 72.14 59.08 62.3 56.04 53.43C54.89 50.08 53.63 46.85 52.42 43.75C52.03 42.76 51.65 41.78 51.28 40.81C49.73 36.8 48.36 33 47.48 29.32C46.52 25.34 46.15 21.52 46.75 17.76"
          fill="url(#paint74_linear_573_35549)"
        />
        <path
          d="M95.95 81.12C93.15 83.15 90.11 84.93 87.01 86.66C86.2 87.12 85.38 87.57 84.56 88.02C81.65 89.63 78.72 91.25 75.83 93.08C70.65 96.37 65.68 100.29 61.55 105.86C60.58 107.44 59.66 109.13 58.82 110.95C56.21 116.56 54.27 123.37 53.26 131.8C53.93 131.93 54.6 132.08 55.27 132.24C56.25 123.9 58.15 117.24 60.68 111.82C60.98 111.18 61.29 110.55 61.6 109.94C66.28 100.92 72.8 95.41 79.7 91.04C82.59 89.21 85.52 87.59 88.43 85.98C89.25 85.53 90.07 85.07 90.88 84.62C92.48 83.73 94.06 82.82 95.6 81.88C95.72 81.63 95.84 81.38 95.96 81.13"
          fill="url(#paint75_linear_573_35549)"
        />
        <path
          d="M96.69 79.62C95.93 80.32 95.14 80.99 94.32 81.64C91.12 84.17 87.53 86.28 83.85 88.34C83.04 88.8 82.22 89.25 81.4 89.7C78.49 91.31 75.56 92.93 72.67 94.76C66.06 98.95 59.8 104.18 55.17 112.54C55.1 112.7 55.02 112.85 54.95 113.01C52.62 118.02 50.82 123.98 49.74 131.21C50.41 131.3 51.09 131.41 51.76 131.53C52.81 124.47 54.56 118.69 56.8 113.88C58.2 110.87 59.8 108.23 61.54 105.88C65.67 100.31 70.64 96.39 75.82 93.1C78.71 91.27 81.64 89.65 84.55 88.04C85.37 87.59 86.19 87.13 87 86.68C90.11 84.94 93.14 83.17 95.94 81.14C96.18 80.64 96.43 80.15 96.68 79.64"
          fill="url(#paint76_linear_573_35549)"
        />
        <path
          d="M101.52 70.06C100.7 71.65 99.76 73.1 98.72 74.43C96.53 78.18 93.7 81.14 90.46 83.7C87.26 86.23 83.67 88.34 79.99 90.4C79.18 90.86 78.36 91.31 77.54 91.76C74.63 93.37 71.7 94.99 68.81 96.82C63.63 100.11 58.65 104.03 54.52 109.61C53.55 111.18 52.64 112.87 51.79 114.69C49.68 119.23 48 124.55 46.91 130.88C47.59 130.94 48.27 131.02 48.95 131.1C50.02 124.98 51.64 119.87 53.65 115.55C54.14 114.5 54.65 113.5 55.18 112.54C59.81 104.18 66.07 98.95 72.68 94.76C75.57 92.93 78.5 91.31 81.41 89.7C82.23 89.25 83.05 88.79 83.86 88.34C87.54 86.28 91.13 84.17 94.33 81.64C95.15 81 95.94 80.33 96.7 79.62C97.38 78.27 98.11 76.88 98.99 75.4C100 73.69 100.84 71.9 101.53 70.06"
          fill="url(#paint77_linear_573_35549)"
        />
        <path
          d="M90.95 8.39001L88.94 8.81001C89.84 12.03 91.04 15.31 92.33 18.67C92.71 19.66 93.1 20.65 93.49 21.65C94.7 24.75 95.94 27.92 97.07 31.22C100.06 39.95 102.27 49.46 101.04 60.28C100.36 66.32 98.62 70.95 96.14 74.69C93.86 79.19 90.7 82.62 87.03 85.52C83.83 88.05 80.24 90.16 76.56 92.22C75.75 92.68 74.93 93.13 74.11 93.58C71.2 95.19 68.27 96.81 65.38 98.64C59.36 102.46 53.63 107.13 49.16 114.25C48.73 115.05 48.32 115.88 47.92 116.74C46.07 120.72 44.55 125.31 43.47 130.65C44.16 130.68 44.84 130.71 45.53 130.76C46.58 125.69 48.03 121.35 49.77 117.6C51.17 114.59 52.76 111.95 54.5 109.61C58.64 104.03 63.61 100.11 68.79 96.82C71.68 94.99 74.61 93.37 77.52 91.76C78.34 91.31 79.16 90.85 79.97 90.4C83.65 88.34 87.24 86.23 90.44 83.7C93.67 81.15 96.51 78.18 98.7 74.43C100.89 70.69 102.42 66.17 103.06 60.5C104.33 49.26 102.03 39.42 98.99 30.55C97.84 27.2 96.58 23.97 95.37 20.87C94.98 19.88 94.6 18.9 94.23 17.93C92.96 14.62 91.8 11.46 90.94 8.39001"
          fill="url(#paint78_linear_573_35549)"
        />
        <path
          d="M87.25 9.17004L85.25 9.59004C86.17 13.14 87.49 16.77 88.92 20.49C89.3 21.48 89.69 22.47 90.08 23.47C91.29 26.57 92.53 29.74 93.66 33.04C96.65 41.77 98.86 51.28 97.63 62.1C96.84 69.13 94.6 74.25 91.45 78.3C89.26 82.05 86.42 85.02 83.19 87.57C79.99 90.1 76.4 92.21 72.72 94.27C71.91 94.73 71.09 95.18 70.27 95.63C67.36 97.24 64.43 98.86 61.54 100.69C56.35 103.98 51.37 107.92 47.23 113.5C46.26 115.07 45.35 116.75 44.51 118.56C42.88 122.07 41.5 126.05 40.46 130.61C40.65 130.61 40.84 130.61 41.03 130.61C41.54 130.61 42.04 130.61 42.55 130.63C43.55 126.39 44.84 122.69 46.36 119.43C47.22 117.57 48.16 115.85 49.17 114.26C53.64 107.14 59.37 102.47 65.39 98.65C68.28 96.82 71.21 95.2 74.12 93.59C74.94 93.14 75.76 92.68 76.57 92.23C80.25 90.17 83.84 88.06 87.04 85.53C90.71 82.63 93.87 79.2 96.15 74.7C97.88 71.28 99.11 67.23 99.66 62.34C100.93 51.1 98.63 41.26 95.59 32.39C94.44 29.04 93.18 25.81 91.97 22.71C91.58 21.72 91.2 20.74 90.83 19.77C89.41 16.09 88.14 12.58 87.25 9.19004"
          fill="url(#paint79_linear_573_35549)"
        />
        <path
          d="M83.11 10.04L81.11 10.46C81.13 10.57 81.16 10.67 81.18 10.78C82.1 14.6 83.51 18.51 85.06 22.53C85.44 23.52 85.83 24.51 86.22 25.51C87.43 28.61 88.67 31.78 89.8 35.08C92.79 43.81 95 53.32 93.77 64.14C93.16 69.49 91.73 73.74 89.68 77.25C87.37 82.33 83.99 86.11 80.01 89.25C76.81 91.78 73.22 93.89 69.54 95.95C68.73 96.41 67.91 96.86 67.09 97.31C64.18 98.92 61.25 100.54 58.36 102.37C51.76 106.55 45.51 111.77 40.89 120.11C40.81 120.28 40.73 120.44 40.65 120.61C39.25 123.62 38.04 126.97 37.07 130.73C37.78 130.69 38.49 130.66 39.21 130.64C40.13 127.24 41.24 124.2 42.5 121.48C43.89 118.48 45.48 115.85 47.21 113.51C51.35 107.92 56.33 103.99 61.52 100.7C64.41 98.87 67.34 97.25 70.25 95.64C71.07 95.19 71.89 94.73 72.7 94.28C76.38 92.22 79.97 90.11 83.17 87.58C86.4 85.03 89.24 82.06 91.43 78.31C93.61 74.57 95.15 70.05 95.79 64.39C97.06 53.15 94.76 43.31 91.72 34.44C90.57 31.09 89.31 27.86 88.1 24.76C87.71 23.77 87.33 22.79 86.96 21.82C85.41 17.81 84.04 14.01 83.16 10.33C83.14 10.24 83.12 10.16 83.1 10.07"
          fill="url(#paint80_linear_573_35549)"
        />
        <path
          d="M79.72 10.76L77.72 11.18C77.81 11.61 77.91 12.04 78.01 12.46C78.93 16.28 80.34 20.19 81.89 24.21C82.27 25.2 82.66 26.19 83.05 27.19C84.26 30.29 85.5 33.46 86.63 36.76C89.62 45.49 91.83 55 90.61 65.82C89.82 72.85 87.59 77.97 84.43 82.01C82.24 85.76 79.4 88.73 76.17 91.28C72.97 93.81 69.38 95.92 65.7 97.98C64.89 98.44 64.07 98.89 63.25 99.34C60.34 100.95 57.41 102.57 54.52 104.4C49.33 107.69 44.35 111.62 40.21 117.21C39.24 118.78 38.33 120.46 37.49 122.27C36.27 124.88 35.2 127.75 34.31 130.93C35.03 130.86 35.76 130.79 36.49 130.74C37.31 127.96 38.27 125.43 39.35 123.13C39.84 122.07 40.36 121.06 40.9 120.08C45.52 111.75 51.78 106.53 58.37 102.34C61.26 100.51 64.19 98.89 67.1 97.28C67.92 96.83 68.74 96.37 69.55 95.92C73.23 93.86 76.82 91.75 80.02 89.22C84 86.08 87.38 82.3 89.69 77.22C91.13 74.05 92.15 70.38 92.64 66.03C93.91 54.79 91.61 44.95 88.57 36.08C87.42 32.73 86.16 29.5 84.95 26.4C84.56 25.41 84.18 24.43 83.81 23.46C82.26 19.45 80.89 15.65 80.01 11.97C79.91 11.56 79.82 11.15 79.73 10.74"
          fill="url(#paint81_linear_573_35549)"
        />
        <path
          d="M75.64 11.62L73.64 12.04C73.79 12.86 73.96 13.68 74.16 14.51C75.08 18.33 76.49 22.24 78.04 26.26C78.42 27.25 78.81 28.24 79.2 29.24C80.41 32.34 81.65 35.51 82.78 38.81C85.77 47.54 87.98 57.05 86.76 67.87C86.06 74.1 84.22 78.83 81.62 82.64C79.74 85.4 77.45 87.67 74.9 89.69C74.51 90 74.11 90.3 73.71 90.6C72.37 91.58 70.97 92.51 69.52 93.41C68.12 94.27 66.68 95.11 65.2 95.94C65.03 96.03 64.87 96.13 64.7 96.22C63.9 96.66 63.1 97.11 62.29 97.56C62.06 97.69 61.82 97.82 61.59 97.95C60.22 98.71 58.84 99.48 57.45 100.28C56.18 101.02 54.9 101.78 53.63 102.57C53.56 102.61 53.49 102.66 53.42 102.7C50.74 104.4 48.09 106.28 45.57 108.48C45.38 108.65 45.19 108.82 44.99 108.99C44.9 109.07 44.81 109.16 44.72 109.24C44.63 109.33 44.53 109.41 44.44 109.5C44.17 109.75 43.9 110.01 43.63 110.26C43.06 110.81 42.49 111.39 41.94 111.98C39.46 114.63 37.17 117.7 35.17 121.34C34.64 122.3 34.13 123.31 33.65 124.35C32.64 126.51 31.74 128.85 30.95 131.39C31.69 131.28 32.44 131.17 33.2 131.09C33.89 128.98 34.67 127.03 35.51 125.21C36.9 122.21 38.49 119.58 40.22 117.24C44.36 111.65 49.34 107.72 54.53 104.43C57.42 102.6 60.35 100.98 63.26 99.37C64.08 98.92 64.9 98.46 65.71 98.01C69.39 95.95 72.98 93.84 76.18 91.31C79.42 88.76 82.25 85.79 84.44 82.04C86.62 78.3 88.16 73.78 88.8 68.12C90.07 56.88 87.77 47.04 84.73 38.17C83.58 34.82 82.32 31.59 81.11 28.49C80.72 27.5 80.34 26.52 79.97 25.55C78.42 21.54 77.05 17.74 76.17 14.06C75.98 13.25 75.8 12.44 75.66 11.65"
          fill="url(#paint82_linear_573_35549)"
        />
        <path
          d="M177.01 20.19C177.21 20.64 177.42 21.08 177.63 21.52C179.06 24.44 180.8 27.15 183.09 29.56C186.12 32.08 189.9 34.21 194.72 35.84C201.4 38.09 207.85 38.87 214.01 38.87C217.73 38.87 221.35 38.59 224.86 38.17C228.38 37.75 231.8 37.2 235.09 36.67C236.14 36.5 237.19 36.33 238.21 36.17C242.46 35.51 246.46 34.96 250.25 34.88C250.4 34.88 250.55 34.88 250.69 34.88C250.39 34.18 250.05 33.51 249.67 32.86C245.9 32.99 241.96 33.53 237.89 34.16C236.85 34.32 235.79 34.49 234.73 34.67C231.45 35.2 228.09 35.74 224.62 36.15C221.17 36.56 217.63 36.84 214.01 36.84C208.02 36.84 201.81 36.08 195.38 33.92C188.68 31.66 184.14 28.4 180.85 24.47C179.4 23.14 178.13 21.72 177.01 20.21"
          fill="url(#paint83_linear_573_35549)"
        />
        <path
          d="M175.14 15.48C175.71 17.1 176.33 18.67 177.01 20.19C178.13 21.69 179.4 23.12 180.85 24.45C184.05 27.37 188.14 29.82 193.53 31.64C200.21 33.89 206.66 34.67 212.82 34.67C216.54 34.67 220.16 34.39 223.67 33.97C227.19 33.55 230.61 33 233.9 32.47C234.95 32.3 236 32.13 237.02 31.97C240.94 31.36 244.66 30.85 248.19 30.7C247.62 30.01 247.01 29.36 246.34 28.76C243.23 28.98 240.01 29.43 236.71 29.95C235.67 30.11 234.61 30.28 233.55 30.46C230.27 30.99 226.91 31.53 223.44 31.94C219.99 32.35 216.45 32.63 212.83 32.63C206.84 32.63 200.63 31.87 194.2 29.71C189.1 27.99 185.26 25.7 182.27 22.96C179.32 20.77 177.02 18.25 175.16 15.49"
          fill="url(#paint84_linear_573_35549)"
        />
        <path
          d="M173.57 10.72C174.07 12.34 174.58 13.93 175.13 15.48C176.99 18.24 179.29 20.76 182.24 22.95C185.03 25.02 188.4 26.79 192.55 28.19C199.23 30.44 205.68 31.22 211.84 31.22C215.56 31.22 219.18 30.94 222.69 30.52C226.21 30.1 229.63 29.55 232.92 29.02C233.97 28.85 235.02 28.68 236.04 28.52C239.02 28.05 241.88 27.65 244.63 27.42C243.65 26.74 242.6 26.16 241.48 25.69C239.59 25.92 237.67 26.2 235.72 26.5C234.67 26.66 233.62 26.83 232.56 27.01C229.28 27.54 225.92 28.08 222.45 28.49C219 28.9 215.46 29.18 211.84 29.18C205.85 29.18 199.64 28.42 193.21 26.26C186.51 24 181.97 20.74 178.68 16.8C176.65 14.95 174.99 12.91 173.58 10.72"
          fill="url(#paint85_linear_573_35549)"
        />
        <path
          d="M173.11 9.19C173.21 9.52 173.31 9.84 173.41 10.17C173.47 10.35 173.52 10.53 173.58 10.72C174.99 12.91 176.65 14.94 178.68 16.8C181.88 19.72 185.97 22.18 191.37 24C198.05 26.25 204.5 27.03 210.66 27.03C214.38 27.03 218 26.75 221.51 26.33C225.03 25.91 228.45 25.36 231.74 24.83C232.8 24.66 233.84 24.49 234.86 24.33C235.25 24.27 235.63 24.21 236.02 24.15L230.8 22.91C227.71 23.41 224.54 23.91 221.28 24.3C217.83 24.71 214.29 24.99 210.67 24.99C204.68 24.99 198.47 24.23 192.04 22.07C186.27 20.13 182.11 17.44 178.96 14.22C177.17 12.77 175.64 11.18 174.31 9.49L173.13 9.21"
          fill="url(#paint86_linear_573_35549)"
        />
        <path
          d="M174.28 9.46997C175.61 11.16 177.14 12.75 178.93 14.2C181.91 16.62 185.61 18.68 190.29 20.26C196.97 22.51 203.42 23.29 209.58 23.29C213.3 23.29 216.92 23.01 220.43 22.59C222.37 22.36 224.28 22.09 226.15 21.8L220.67 20.5C220.51 20.52 220.35 20.54 220.19 20.56C216.74 20.97 213.2 21.25 209.58 21.25C203.59 21.25 197.38 20.49 190.95 18.33C185.07 16.35 180.86 13.6 177.69 10.29L174.28 9.47997"
          fill="url(#paint87_linear_573_35549)"
        />
        <path
          d="M178.27 10.42C181.16 12.66 184.7 14.58 189.11 16.06C195.79 18.31 202.24 19.09 208.4 19.09C210.27 19.09 212.12 19.02 213.93 18.89L205.97 17C200.73 16.81 195.33 15.99 189.76 14.12C187.78 13.45 185.99 12.7 184.37 11.87L178.27 10.42Z"
          fill="url(#paint88_linear_573_35549)"
        />
        <path
          d="M190.04 13.22C192.12 13.84 194.18 14.32 196.21 14.69L190.04 13.22Z"
          fill="url(#paint89_linear_573_35549)"
        />
        <path
          d="M184.26 49.05C184.66 50.03 185.09 50.99 185.55 51.93C187 54.88 188.76 57.62 191.09 60.06C194.26 62.9 198.29 65.29 203.58 67.07C210.26 69.32 216.71 70.1 222.87 70.1C226.59 70.1 230.21 69.82 233.72 69.4C237.23 68.98 240.66 68.43 243.95 67.9C245 67.73 246.05 67.56 247.07 67.4C248.77 67.13 250.44 66.89 252.06 66.68V64.62C250.32 64.84 248.55 65.1 246.75 65.38C245.7 65.54 244.65 65.71 243.59 65.89C240.31 66.42 236.95 66.96 233.48 67.37C230.03 67.78 226.49 68.06 222.87 68.06C216.88 68.06 210.67 67.3 204.24 65.14C197.52 62.87 192.98 59.6 189.68 55.65C187.5 53.65 185.74 51.44 184.26 49.06M163.64 6.94C163.79 7.09 163.93 7.23 164.08 7.38C167.72 10.74 170.48 14.34 172.66 18.04C171.14 14.5 169.26 11.01 166.77 7.68L163.64 6.94Z"
          fill="url(#paint90_linear_573_35549)"
        />
        <path
          d="M182.5 44.28C183.05 45.91 183.62 47.51 184.26 49.05C185.73 51.43 187.5 53.65 189.68 55.64C192.88 58.57 196.98 61.04 202.4 62.86C209.08 65.11 215.53 65.89 221.69 65.89C225.41 65.89 229.03 65.61 232.54 65.19C236.05 64.77 239.48 64.22 242.77 63.69C243.82 63.52 244.87 63.35 245.89 63.19C248.01 62.86 250.07 62.56 252.07 62.33V60.27C249.95 60.51 247.78 60.82 245.57 61.17C244.52 61.33 243.47 61.5 242.41 61.68C239.13 62.21 235.77 62.75 232.3 63.16C228.85 63.57 225.31 63.85 221.69 63.85C215.7 63.85 209.49 63.09 203.06 60.93C197.96 59.21 194.12 56.92 191.12 54.18C187.33 51.37 184.61 48 182.52 44.28M166.77 7.69C169.26 11.02 171.13 14.5 172.66 18.05C173.05 18.71 173.42 19.37 173.77 20.04C173.57 19.45 173.36 18.86 173.14 18.27C171.87 14.82 170.36 11.39 168.36 8.08L166.77 7.7"
          fill="url(#paint91_linear_573_35549)"
        />
        <path
          d="M181.01 39.56C181.11 39.9 181.22 40.24 181.32 40.58C181.7 41.83 182.09 43.07 182.5 44.28C184.6 48 187.31 51.37 191.1 54.18C193.89 56.25 197.27 58.02 201.41 59.42C208.09 61.67 214.54 62.45 220.7 62.45C224.42 62.45 228.04 62.17 231.55 61.75C235.06 61.33 238.49 60.78 241.78 60.25C242.83 60.08 243.88 59.91 244.9 59.75C247.37 59.36 249.75 59.02 252.06 58.78V56.72C249.63 56.97 247.13 57.33 244.59 57.72C243.54 57.88 242.49 58.05 241.43 58.23C238.15 58.76 234.79 59.3 231.32 59.71C227.87 60.12 224.33 60.4 220.71 60.4C214.72 60.4 208.51 59.64 202.08 57.48C195.4 55.23 190.87 51.98 187.58 48.06C184.81 45.54 182.72 42.67 181.03 39.56M168.37 8.07001C170.36 11.39 171.87 14.81 173.15 18.26C173.37 18.85 173.58 19.44 173.78 20.03C173.92 20.3 174.07 20.57 174.2 20.84C173.51 18.59 172.78 16.32 171.95 14.06C171.24 12.14 170.46 10.23 169.57 8.35001L168.37 8.07001Z"
          fill="url(#paint92_linear_573_35549)"
        />
        <path
          d="M179.79 35.51C180.04 36.33 180.28 37.14 180.53 37.94C180.69 38.48 180.86 39.02 181.02 39.56C182.71 42.67 184.81 45.54 187.57 48.06C190.76 50.97 194.85 53.41 200.22 55.23C206.9 57.48 213.35 58.26 219.51 58.26C223.23 58.26 226.85 57.98 230.36 57.56C233.88 57.14 237.3 56.59 240.59 56.06C241.64 55.89 242.69 55.72 243.71 55.56C246.6 55.11 249.39 54.71 252.06 54.48V52.43C249.25 52.67 246.35 53.08 243.39 53.55C242.34 53.71 241.29 53.88 240.23 54.06C236.95 54.59 233.59 55.13 230.12 55.54C226.67 55.95 223.13 56.23 219.51 56.23C213.52 56.23 207.31 55.47 200.88 53.31C195.09 51.36 190.91 48.66 187.76 45.42C184.27 42.57 181.75 39.21 179.8 35.53M169.56 8.35003C170.46 10.24 171.24 12.15 171.94 14.06C172.77 16.32 173.5 18.59 174.19 20.84C174.25 20.96 174.32 21.09 174.38 21.21C174.25 20.78 174.12 20.35 173.99 19.92C173.03 16.74 172.06 13.53 170.88 10.32C170.65 9.71003 170.42 9.10003 170.18 8.49003L169.55 8.34003"
          fill="url(#paint93_linear_573_35549)"
        />
        <path
          d="M178.22 30.59C178.78 32.24 179.29 33.88 179.79 35.51C181.75 39.2 184.26 42.55 187.75 45.4C190.74 47.84 194.46 49.91 199.16 51.5C205.84 53.75 212.29 54.53 218.45 54.53C222.17 54.53 225.79 54.25 229.3 53.83C232.81 53.41 236.24 52.86 239.53 52.33C240.58 52.16 241.63 51.99 242.65 51.83C245.93 51.32 249.06 50.88 252.06 50.66V48.61C248.92 48.83 245.67 49.28 242.34 49.81C241.29 49.97 240.24 50.14 239.18 50.32C235.9 50.85 232.54 51.39 229.07 51.8C225.62 52.21 222.08 52.49 218.46 52.49C212.47 52.49 206.26 51.73 199.83 49.57C193.15 47.32 188.62 44.07 185.33 40.15C182.26 37.35 180.01 34.12 178.23 30.61M170.19 8.5C170.43 9.11 170.66 9.72 170.89 10.33C172.07 13.54 173.04 16.75 174 19.93C174.13 20.36 174.26 20.79 174.39 21.22C174.5 21.44 174.61 21.66 174.71 21.88C174.33 20.74 173.97 19.59 173.62 18.43C173.35 17.54 173.08 16.64 172.81 15.74C172.1 13.37 171.38 10.98 170.57 8.59L170.2 8.5"
          fill="url(#paint94_linear_573_35549)"
        />
        <path
          d="M170.56 8.59C171.37 10.98 172.09 13.37 172.8 15.74C173.07 16.64 173.34 17.53 173.61 18.43C173.97 19.59 174.33 20.74 174.7 21.88C175.71 23.97 176.58 26.08 177.36 28.19C177.65 28.99 177.94 29.79 178.21 30.59C179.99 34.1 182.24 37.33 185.31 40.13C188.5 43.04 192.58 45.48 197.96 47.29C204.64 49.54 211.09 50.32 217.25 50.32C220.97 50.32 224.59 50.04 228.1 49.62C231.62 49.2 235.04 48.65 238.33 48.12C239.38 47.95 240.42 47.78 241.45 47.62C245.16 47.04 248.69 46.55 252.05 46.38V44.33C248.54 44.5 244.89 45.01 241.13 45.6C240.08 45.76 239.03 45.93 237.97 46.1C234.69 46.63 231.33 47.17 227.86 47.58C224.41 47.99 220.87 48.27 217.25 48.27C211.26 48.27 205.05 47.51 198.62 45.35C193.52 43.63 189.68 41.33 186.68 38.6C182.19 35.27 179.21 31.17 176.98 26.61C175.18 22.94 173.87 18.99 172.64 14.96C172.37 14.07 172.1 13.17 171.83 12.27C171.46 11.05 171.1 9.83 170.71 8.6L170.56 8.56"
          fill="url(#paint95_linear_573_35549)"
        />
        <path
          d="M170.71 8.62C171.09 9.85 171.46 11.07 171.83 12.29C172.1 13.19 172.37 14.08 172.64 14.98C173.88 19.01 175.19 22.96 176.98 26.63C179.21 31.19 182.19 35.29 186.68 38.62C189.47 40.69 192.85 42.46 196.99 43.86C203.67 46.11 210.12 46.89 216.28 46.89C220 46.89 223.62 46.61 227.13 46.19C230.65 45.77 234.07 45.22 237.36 44.69C238.42 44.52 239.46 44.35 240.48 44.19C244.56 43.55 248.41 43.03 252.06 42.91V41.61C252.06 41.36 252.06 41.11 252.04 40.87C248.24 40.99 244.26 41.53 240.16 42.18C239.11 42.34 238.06 42.51 237 42.69C233.72 43.22 230.36 43.76 226.89 44.17C223.44 44.58 219.9 44.86 216.28 44.86C210.29 44.86 204.08 44.1 197.65 41.94C190.93 39.67 186.39 36.4 183.1 32.45C179.91 29.52 177.61 26.13 175.8 22.44C174 18.77 172.69 14.82 171.46 10.79C171.24 10.08 171.03 9.38 170.82 8.67L170.72 8.64999"
          fill="url(#paint96_linear_573_35549)"
        />
        <path
          d="M170.81 8.65002C171.02 9.36002 171.24 10.07 171.45 10.77C172.69 14.8 174 18.75 175.79 22.42C177.6 26.11 179.9 29.5 183.09 32.43C186.29 35.36 190.39 37.83 195.8 39.65C202.48 41.9 208.93 42.68 215.09 42.68C218.81 42.68 222.43 42.4 225.94 41.98C229.46 41.56 232.88 41.01 236.17 40.48C237.22 40.31 238.27 40.14 239.29 39.98C243.54 39.32 247.54 38.77 251.33 38.69C251.49 38.69 251.65 38.69 251.81 38.69C251.69 38 251.53 37.32 251.33 36.65H251.28C247.35 36.74 243.23 37.3 238.97 37.97C237.93 38.13 236.87 38.3 235.81 38.48C232.53 39.01 229.17 39.55 225.7 39.96C222.25 40.37 218.71 40.65 215.09 40.65C209.1 40.65 202.89 39.89 196.46 37.73C190.52 35.73 186.28 32.94 183.1 29.58C180.81 27.16 179.07 24.45 177.64 21.54C177.43 21.1 177.22 20.66 177.02 20.21C176.34 18.69 175.72 17.12 175.15 15.5C174.6 13.95 174.09 12.36 173.59 10.74C173.53 10.56 173.48 10.38 173.42 10.19C173.32 9.87002 173.22 9.54002 173.12 9.21002L170.82 8.66002"
          fill="url(#paint97_linear_573_35549)"
        />
        <path
          d="M193.8 81.86C194 82.3 194.2 82.73 194.41 83.16C195.84 86.08 197.58 88.79 199.88 91.21C202.91 93.72 206.69 95.85 211.5 97.48C218.18 99.73 224.63 100.51 230.79 100.51C234.51 100.51 238.13 100.23 241.64 99.81C245.15 99.39 248.58 98.84 251.87 98.31L252.06 98.28V96.21C251.88 96.24 251.7 96.27 251.52 96.3C248.24 96.83 244.88 97.37 241.41 97.78C237.96 98.19 234.42 98.47 230.8 98.47C224.81 98.47 218.6 97.71 212.17 95.55C205.49 93.3 200.96 90.05 197.67 86.13C196.21 84.8 194.94 83.38 193.81 81.87M100.36 14.34C100.03 14.34 99.71 14.34 99.38 14.34C99.6 14.98 99.84 15.67 100.08 16.38C100.17 16.38 100.26 16.38 100.35 16.38C107.15 16.38 114.58 16.89 122.69 18.01C144.35 21.01 157.97 26.56 166.92 33.33C168.75 34.72 170.39 36.16 171.87 37.64C174.98 40.34 177.48 43.21 179.54 46.17C177.75 42.44 175.5 38.8 172.47 35.37C171.16 34.11 169.72 32.89 168.16 31.7C158.84 24.65 144.84 19.01 122.98 15.98C114.78 14.84 107.26 14.33 100.36 14.33"
          fill="url(#paint98_linear_573_35549)"
        />
        <path
          d="M191.92 77.14C192.49 78.76 193.11 80.33 193.79 81.86C194.91 83.37 196.19 84.79 197.65 86.12C200.84 89.03 204.92 91.47 210.3 93.28C216.98 95.53 223.43 96.31 229.59 96.31C233.31 96.31 236.93 96.03 240.44 95.61C243.96 95.19 247.38 94.64 250.67 94.11C251.13 94.04 251.6 93.96 252.05 93.89V91.82C251.47 91.91 250.89 92.01 250.31 92.1C247.03 92.63 243.67 93.17 240.2 93.58C236.75 93.99 233.21 94.27 229.59 94.27C223.6 94.27 217.39 93.51 210.96 91.35C205.86 89.63 202.02 87.34 199.02 84.6C196.08 82.42 193.78 79.9 191.92 77.14ZM99.16 10.14C98.73 10.14 98.29 10.14 97.86 10.14C98.08 10.73 98.33 11.41 98.61 12.18C98.79 12.18 98.97 12.18 99.15 12.18C105.95 12.18 113.38 12.69 121.49 13.81C143.15 16.81 156.77 22.36 165.72 29.13C168.33 31.11 170.55 33.19 172.47 35.36C175.5 38.79 177.75 42.43 179.54 46.16C180.01 46.84 180.46 47.53 180.89 48.22C178.2 41.03 174.42 33.98 167.38 27.82C167.24 27.71 167.1 27.6 166.95 27.49C157.63 20.44 143.63 14.8 121.77 11.77C113.57 10.63 106.05 10.12 99.15 10.12"
          fill="url(#paint99_linear_573_35549)"
        />
        <path
          d="M190.36 72.36C190.86 73.99 191.38 75.59 191.93 77.14C193.79 79.9 196.08 82.41 199.03 84.6C201.82 86.67 205.2 88.44 209.34 89.84C216.02 92.09 222.47 92.87 228.63 92.87C232.35 92.87 235.97 92.59 239.48 92.17C243 91.75 246.42 91.2 249.71 90.67C250.5 90.54 251.29 90.41 252.07 90.29V88.22C251.17 88.36 250.27 88.51 249.36 88.65C246.08 89.18 242.72 89.72 239.25 90.13C235.8 90.54 232.26 90.82 228.64 90.82C222.65 90.82 216.44 90.06 210.01 87.9C203.29 85.63 198.74 82.36 195.45 78.41C193.44 76.57 191.78 74.54 190.38 72.36M99.77 6.70001L96.92 7.69001C96.92 7.69001 97.07 8.06001 97.33 8.74001C97.61 8.74001 97.89 8.74001 98.18 8.74001C104.98 8.74001 112.41 9.25001 120.52 10.37C142.18 13.37 155.8 18.92 164.75 25.69C165.68 26.39 166.55 27.11 167.39 27.83C174.43 33.99 178.21 41.04 180.9 48.23C181.1 48.55 181.29 48.87 181.49 49.2C180.99 47.63 180.46 46.05 179.88 44.47C177.76 38.72 174.98 33.04 170.42 27.84C169.08 26.55 167.61 25.29 166 24.07C156.68 17.02 142.68 11.38 120.82 8.35001C113.24 7.30001 106.25 6.78001 99.79 6.71001"
          fill="url(#paint100_linear_573_35549)"
        />
        <path
          d="M189.17 68.43C189.24 68.67 189.32 68.92 189.39 69.16C189.66 70.05 189.92 70.93 190.19 71.8C190.25 71.98 190.3 72.17 190.36 72.35C191.76 74.52 193.42 76.55 195.43 78.4C198.63 81.34 202.73 83.8 208.15 85.63C214.83 87.88 221.28 88.66 227.44 88.66C231.16 88.66 234.78 88.38 238.29 87.96C241.8 87.54 245.23 86.99 248.52 86.46C249.57 86.29 250.62 86.12 251.64 85.96C251.78 85.94 251.92 85.92 252.07 85.89V83.82C251.82 83.86 251.58 83.9 251.33 83.94C250.28 84.1 249.23 84.27 248.17 84.45C244.89 84.98 241.53 85.52 238.06 85.93C234.61 86.34 231.07 86.62 227.45 86.62C221.46 86.62 215.25 85.86 208.82 83.7C203.07 81.76 198.92 79.09 195.77 75.88C193.05 73.68 190.92 71.17 189.19 68.44M110.2 3.08001L105.31 4.78001C109.74 5.03001 114.41 5.49001 119.33 6.17001C140.98 9.17001 154.61 14.72 163.56 21.49C166.22 23.5 168.47 25.62 170.4 27.83C174.96 33.04 177.75 38.72 179.86 44.46C180.44 46.04 180.97 47.62 181.47 49.19C181.53 49.29 181.59 49.4 181.65 49.5C180.77 46.59 179.87 43.66 178.8 40.74C176.33 34.04 172.96 27.45 166.93 21.57C166.24 20.99 165.53 20.42 164.78 19.85C155.46 12.8 141.46 7.16001 119.6 4.13001C116.36 3.68001 113.22 3.33001 110.19 3.07001"
          fill="url(#paint101_linear_573_35549)"
        />
        <path
          d="M187.76 63.89C188.25 65.42 188.71 66.94 189.16 68.44C190.9 71.17 193.02 73.68 195.74 75.88C198.72 78.29 202.41 80.34 207.07 81.91C213.75 84.16 220.2 84.94 226.36 84.94C230.08 84.94 233.7 84.66 237.21 84.24C240.73 83.82 244.15 83.27 247.44 82.74C248.5 82.57 249.53 82.4 250.56 82.24C251.06 82.16 251.56 82.09 252.05 82.01V79.94C251.45 80.03 250.85 80.12 250.25 80.22C249.2 80.38 248.15 80.55 247.09 80.73C243.81 81.26 240.45 81.8 236.98 82.21C233.53 82.62 229.99 82.9 226.37 82.9C220.38 82.9 214.17 82.14 207.74 79.98C201.02 77.71 196.48 74.44 193.18 70.49C191 68.49 189.24 66.28 187.76 63.9M119.69 0.579988C117.99 0.819988 116.3 1.13999 114.63 1.53999L113.7 1.85999C115.2 2.02999 116.73 2.21999 118.28 2.43999C139.94 5.43999 153.56 10.99 162.51 17.76C164.14 18.99 165.61 20.26 166.95 21.57C172.98 27.45 176.35 34.04 178.82 40.74C179.9 43.67 180.8 46.6 181.67 49.5C181.73 49.61 181.79 49.72 181.86 49.82C181.76 49.49 181.65 49.15 181.55 48.81C181.28 47.92 181.01 47.02 180.74 46.12C179.78 42.94 178.82 39.73 177.63 36.52C175.51 30.77 172.73 25.09 168.16 19.88C166.82 18.59 165.35 17.33 163.74 16.11C154.58 9.17999 140.9 3.61999 119.69 0.549988"
          fill="url(#paint102_linear_573_35549)"
        />
        <path
          d="M186.34 59.72C186.85 61.11 187.31 62.5 187.76 63.89C189.23 66.27 191 68.49 193.18 70.48C196.38 73.41 200.48 75.88 205.89 77.7C212.57 79.95 219.02 80.73 225.18 80.73C228.9 80.73 232.52 80.45 236.03 80.03C239.54 79.61 242.97 79.06 246.26 78.53C247.31 78.36 248.35 78.19 249.38 78.03C250.28 77.89 251.18 77.75 252.06 77.63V75.56C251.07 75.7 250.07 75.85 249.07 76.01C248.02 76.17 246.97 76.34 245.91 76.52C242.63 77.05 239.27 77.59 235.8 78C232.35 78.41 228.81 78.69 225.19 78.69C219.2 78.69 212.99 77.93 206.56 75.77C201.49 74.06 197.65 71.78 194.66 69.06C191.05 66.39 188.41 63.23 186.35 59.73M127.77 0H127.52C143.26 3.18 153.89 7.95 161.31 13.56C163.97 15.57 166.22 17.69 168.15 19.9C172.71 25.11 175.5 30.79 177.62 36.54C178.8 39.75 179.77 42.96 180.73 46.14C181 47.04 181.27 47.93 181.54 48.83C181.64 49.17 181.75 49.5 181.85 49.84C181.97 50.05 182.09 50.26 182.2 50.47C181.62 48.8 181.08 47.1 180.55 45.39C180.28 44.5 180.01 43.6 179.74 42.7C178.78 39.52 177.82 36.31 176.63 33.1C173.92 25.74 170.12 18.49 162.88 12.2C162.77 12.11 162.65 12.03 162.54 11.94C157.64 8.23 151.45 4.92 143.5 2.17L140.91 1.55C136.59 0.52 132.18 0.01 127.76 0.01"
          fill="url(#paint103_linear_573_35549)"
        />
        <path
          d="M148.92 3.44C153.41 5.47 157.16 7.71 160.32 10.11C161.22 10.79 162.07 11.48 162.88 12.19C170.11 18.49 173.92 25.73 176.63 33.09C177.81 36.3 178.78 39.51 179.74 42.69C180.01 43.59 180.28 44.48 180.55 45.38C181.08 47.09 181.61 48.79 182.2 50.46C183.83 53.41 185.12 56.41 186.23 59.42C186.27 59.52 186.3 59.62 186.34 59.72C188.4 63.22 191.03 66.39 194.65 69.05C197.43 71.1 200.79 72.86 204.91 74.25C211.59 76.5 218.04 77.28 224.2 77.28C227.92 77.28 231.54 77 235.05 76.58C238.57 76.16 241.99 75.61 245.28 75.08C246.33 74.91 247.37 74.74 248.4 74.58C249.64 74.39 250.86 74.2 252.06 74.04V71.98C250.75 72.16 249.42 72.36 248.08 72.57C247.03 72.73 245.98 72.9 244.92 73.08C241.64 73.61 238.28 74.15 234.81 74.56C231.36 74.97 227.82 75.25 224.2 75.25C218.21 75.25 212 74.49 205.57 72.33C198.87 70.07 194.33 66.81 191.04 62.87C187.84 59.94 185.53 56.54 183.72 52.84C181.92 49.17 180.61 45.22 179.38 41.19C179.11 40.3 178.84 39.4 178.57 38.5C177.61 35.32 176.65 32.11 175.46 28.9C173.34 23.13 170.54 17.44 165.96 12.22C164.63 10.94 163.17 9.7 161.58 8.49C160.15 7.41 158.62 6.37 156.96 5.36L148.94 3.45"
          fill="url(#paint104_linear_573_35549)"
        />
        <path
          d="M159.06 5.84998L159.15 5.91998C161.79 7.91998 164.03 10.02 165.95 12.22C170.54 17.44 173.33 23.14 175.45 28.9C176.63 32.11 177.6 35.32 178.56 38.5C178.83 39.4 179.1 40.29 179.37 41.19C180.61 45.22 181.92 49.17 183.71 52.84C185.52 56.54 187.83 59.94 191.03 62.87C194.23 65.79 198.32 68.25 203.72 70.07C210.4 72.32 216.85 73.1 223.01 73.1C226.73 73.1 230.35 72.82 233.86 72.4C237.37 71.98 240.8 71.43 244.09 70.9C245.14 70.73 246.19 70.56 247.21 70.4C248.86 70.14 250.48 69.9 252.06 69.7V67.64C250.36 67.85 248.64 68.11 246.9 68.38C245.85 68.54 244.8 68.71 243.74 68.88C240.46 69.41 237.1 69.95 233.63 70.36C230.18 70.77 226.64 71.05 223.02 71.05C217.03 71.05 210.82 70.29 204.39 68.13C198.5 66.14 194.28 63.38 191.11 60.07C188.78 57.63 187.01 54.89 185.57 51.94C185.11 51 184.68 50.04 184.28 49.06C183.64 47.52 183.07 45.92 182.52 44.29C182.12 43.08 181.73 41.84 181.34 40.59C181.24 40.25 181.13 39.91 181.03 39.57C180.87 39.03 180.71 38.49 180.54 37.95C180.3 37.14 180.05 36.33 179.8 35.52C179.3 33.89 178.79 32.25 178.23 30.6C177.96 29.8 177.68 29 177.38 28.2C176.6 26.09 175.73 23.98 174.72 21.89C174.61 21.67 174.51 21.45 174.4 21.23C174.34 21.11 174.27 20.98 174.21 20.86C174.07 20.59 173.93 20.32 173.79 20.05C173.44 19.38 173.07 18.72 172.68 18.06C170.5 14.35 167.74 10.76 164.1 7.39998C163.94 7.24998 163.77 7.09998 163.6 6.94998L159.07 5.86998"
          fill="url(#paint105_linear_573_35549)"
        />
        <path
          d="M204.74 115.59C206.41 118.84 208.51 121.83 211.38 124.41C213.9 126.08 216.85 127.53 220.36 128.71C227.04 130.96 233.49 131.74 239.65 131.74C243.37 131.74 246.99 131.46 250.5 131.04C251.02 130.98 251.54 130.91 252.06 130.85V128.79C251.46 128.87 250.86 128.94 250.26 129.02C246.81 129.43 243.27 129.71 239.65 129.71C233.66 129.71 227.45 128.95 221.02 126.79C214.3 124.52 209.76 121.25 206.46 117.3C205.86 116.75 205.29 116.18 204.75 115.6M109.21 45.57C108.63 45.57 108.06 45.57 107.49 45.58C107.59 46.26 107.67 46.95 107.75 47.62C108.23 47.62 108.72 47.62 109.21 47.62C116.01 47.62 123.44 48.13 131.55 49.25C153.21 52.25 166.83 57.8 175.78 64.57C176.5 65.11 177.19 65.67 177.85 66.23C178.31 66.56 178.77 66.89 179.21 67.23C182.22 69.51 184.74 71.93 186.87 74.45C185.38 71.79 183.61 69.2 181.43 66.72C180.09 65.43 178.62 64.17 177.01 62.95C167.69 55.9 153.69 50.26 131.83 47.23C123.63 46.09 116.11 45.58 109.21 45.58"
          fill="url(#paint106_linear_573_35549)"
        />
        <path
          d="M202.63 110.84C203.17 112.22 203.74 113.57 204.38 114.87C204.5 115.11 204.62 115.35 204.74 115.58C205.28 116.16 205.85 116.73 206.45 117.28C209.65 120.21 213.75 122.68 219.16 124.5C225.84 126.75 232.29 127.53 238.45 127.53C242.17 127.53 245.79 127.25 249.3 126.83C250.22 126.72 251.14 126.6 252.05 126.48V124.42C251.06 124.56 250.07 124.69 249.06 124.81C245.61 125.22 242.07 125.5 238.45 125.5C232.46 125.5 226.25 124.74 219.82 122.58C214.72 120.86 210.88 118.56 207.88 115.83C205.84 114.32 204.12 112.65 202.63 110.85M108.02 41.37C107.61 41.37 107.21 41.37 106.8 41.37C106.92 42.05 107.04 42.73 107.15 43.41C107.44 43.41 107.72 43.41 108.01 43.41C114.81 43.41 122.24 43.92 130.35 45.04C152.01 48.04 165.63 53.59 174.58 60.36C177.24 62.37 179.49 64.49 181.42 66.7C183.6 69.19 185.37 71.78 186.86 74.43C187.49 75.18 188.09 75.93 188.66 76.7C186.04 70.48 182.38 64.43 176.25 59.06C176.11 58.95 175.96 58.84 175.82 58.73C166.5 51.68 152.5 46.04 130.64 43.01C122.44 41.87 114.92 41.36 108.02 41.36"
          fill="url(#paint107_linear_573_35549)"
        />
        <path
          d="M200.95 106.04C201.48 107.68 202.03 109.28 202.63 110.84C204.12 112.64 205.84 114.31 207.88 115.82C210.67 117.89 214.05 119.66 218.19 121.06C224.87 123.31 231.32 124.09 237.48 124.09C241.2 124.09 244.82 123.81 248.33 123.39C249.58 123.24 250.83 123.08 252.05 122.9V120.83C250.74 121.02 249.42 121.2 248.09 121.36C244.64 121.77 241.1 122.05 237.48 122.05C231.49 122.05 225.28 121.29 218.85 119.13C212.17 116.88 207.64 113.63 204.35 109.71C203.09 108.56 201.96 107.33 200.95 106.05M107.05 37.92C106.74 37.92 106.43 37.92 106.12 37.92C106.26 38.6 106.4 39.28 106.54 39.96C106.71 39.96 106.88 39.96 107.05 39.96C113.85 39.96 121.28 40.47 129.39 41.59C151.05 44.59 164.67 50.14 173.62 56.91C174.55 57.61 175.43 58.33 176.26 59.06C182.39 64.43 186.05 70.48 188.67 76.7C188.95 77.08 189.22 77.45 189.48 77.83C189.24 77.12 188.98 76.4 188.72 75.69C186.59 69.9 183.78 64.18 179.16 58.94C177.85 57.68 176.41 56.46 174.84 55.27C165.52 48.22 151.52 42.58 129.66 39.55C121.46 38.41 113.94 37.9 107.04 37.9"
          fill="url(#paint108_linear_573_35549)"
        />
        <path
          d="M199.72 102.09C199.87 102.57 200.01 103.05 200.16 103.53C200.42 104.38 200.68 105.22 200.95 106.05C201.96 107.34 203.08 108.56 204.35 109.71C207.54 112.62 211.62 115.06 217 116.87C223.68 119.12 230.12 119.9 236.28 119.9C240 119.9 243.62 119.62 247.13 119.2C248.79 119 250.43 118.78 252.05 118.53V116.46C250.35 116.72 248.64 116.96 246.89 117.16C243.44 117.57 239.9 117.85 236.28 117.85C230.29 117.85 224.08 117.09 217.65 114.93C211.86 112.98 207.68 110.28 204.53 107.04C202.67 105.52 201.09 103.86 199.72 102.09ZM105.85 33.73C105.62 33.73 105.39 33.73 105.16 33.73C105.32 34.41 105.48 35.09 105.64 35.77H105.84C112.64 35.77 120.07 36.28 128.18 37.4C149.84 40.4 163.46 45.95 172.41 52.72C175.02 54.7 177.25 56.78 179.16 58.95C183.78 64.19 186.59 69.91 188.72 75.7C188.98 76.41 189.23 77.13 189.48 77.84C189.58 77.98 189.67 78.12 189.77 78.26C189.12 76.17 188.44 74.07 187.67 71.97C185.22 65.32 181.88 58.77 175.93 52.92C175.2 52.3 174.45 51.69 173.65 51.09C164.33 44.04 150.33 38.4 128.47 35.37C120.27 34.23 112.75 33.72 105.85 33.72"
          fill="url(#paint109_linear_573_35549)"
        />
        <path
          d="M198.4 97.73C198.73 98.79 199.04 99.84 199.36 100.88C199.48 101.28 199.6 101.68 199.72 102.08C201.09 103.86 202.67 105.52 204.53 107.03C207.52 109.47 211.24 111.54 215.94 113.12C222.62 115.37 229.06 116.15 235.22 116.15C238.94 116.15 242.56 115.87 246.07 115.45C248.09 115.21 250.09 114.92 252.04 114.62V112.55C250 112.86 247.93 113.16 245.83 113.41C242.38 113.82 238.84 114.1 235.22 114.1C229.23 114.1 223.02 113.34 216.59 111.18C209.91 108.93 205.38 105.68 202.09 101.76C200.7 100.5 199.48 99.14 198.4 97.72M104.8 29.99C104.6 29.99 104.41 29.99 104.22 29.99C104.4 30.67 104.58 31.35 104.75 32.03H104.8C111.6 32.03 119.03 32.54 127.14 33.66C148.79 36.66 162.42 42.21 171.37 48.98C173.05 50.25 174.56 51.56 175.94 52.91C181.89 58.76 185.23 65.31 187.68 71.96C188.45 74.06 189.13 76.16 189.78 78.25L189.92 78.45C189.81 78.09 189.7 77.73 189.59 77.36C188.63 74.18 187.66 70.97 186.48 67.76C184.35 61.97 181.54 56.26 176.92 51.02C175.6 49.76 174.17 48.54 172.6 47.35C163.28 40.3 149.28 34.66 127.42 31.63C119.22 30.49 111.7 29.98 104.8 29.98"
          fill="url(#paint110_linear_573_35549)"
        />
        <path
          d="M197.21 93.98C197.63 95.23 198.03 96.48 198.41 97.73C199.49 99.16 200.71 100.51 202.1 101.77C205.29 104.68 209.38 107.12 214.75 108.94C221.43 111.19 227.87 111.97 234.03 111.97C237.75 111.97 241.37 111.69 244.88 111.27C247.32 110.98 249.71 110.63 252.05 110.26V108.19C249.63 108.57 247.16 108.94 244.64 109.24C241.19 109.65 237.65 109.93 234.03 109.93C228.04 109.93 221.83 109.17 215.4 107.01C210.3 105.29 206.46 102.99 203.46 100.26C200.95 98.4 198.91 96.29 197.21 93.99M103.6 25.8C103.42 25.8 103.23 25.8 103.05 25.8C103.24 26.47 103.44 27.15 103.63 27.84C110.42 27.84 117.84 28.35 125.94 29.47C147.6 32.47 161.22 38.02 170.17 44.79C172.78 46.77 175.01 48.86 176.92 51.03C181.54 56.27 184.34 61.98 186.48 67.77C187.66 70.98 188.63 74.19 189.59 77.37C189.7 77.73 189.81 78.09 189.92 78.46L190.08 78.7C189.86 78.01 189.64 77.31 189.43 76.61C189.16 75.72 188.89 74.82 188.62 73.92C187.66 70.74 186.7 67.53 185.51 64.32C182.81 56.98 179.02 49.77 171.84 43.49C171.7 43.38 171.56 43.27 171.41 43.16C162.09 36.11 148.09 30.47 126.23 27.44C118.03 26.3 110.51 25.79 103.61 25.79"
          fill="url(#paint111_linear_573_35549)"
        />
        <path
          d="M195.29 88.76C195.61 89.55 195.91 90.34 196.2 91.13C196.55 92.08 196.88 93.03 197.2 93.98C198.9 96.27 200.94 98.38 203.45 100.25C206.24 102.32 209.62 104.09 213.76 105.49C220.44 107.74 226.89 108.52 233.05 108.52C236.77 108.52 240.39 108.24 243.9 107.82C246.68 107.49 249.4 107.08 252.04 106.65V104.58C249.31 105.02 246.52 105.45 243.65 105.79C240.2 106.2 236.66 106.48 233.04 106.48C227.05 106.48 220.84 105.72 214.41 103.56C207.69 101.29 203.14 98.02 199.85 94.07C198.07 92.44 196.57 90.66 195.28 88.77M102.63 22.35C102.43 22.35 102.22 22.35 102.02 22.35C102.23 23.02 102.43 23.7 102.64 24.39C109.44 24.39 116.86 24.9 124.97 26.02C146.62 29.02 160.25 34.57 169.2 41.34C170.13 42.04 171 42.76 171.84 43.48C179.02 49.76 182.81 56.98 185.51 64.31C186.69 67.52 187.66 70.73 188.62 73.91C188.89 74.81 189.16 75.7 189.43 76.6C189.64 77.3 189.86 78 190.08 78.69C190.27 78.98 190.47 79.28 190.65 79.57C189.76 77.24 188.98 74.83 188.24 72.39C187.97 71.5 187.7 70.6 187.43 69.7C186.47 66.52 185.5 63.31 184.32 60.1C182.2 54.35 179.42 48.67 174.86 43.46C173.52 42.17 172.05 40.91 170.44 39.69C161.12 32.64 147.12 27 125.26 23.97C117.06 22.83 109.54 22.32 102.64 22.32"
          fill="url(#paint112_linear_573_35549)"
        />
        <path
          d="M101.44 18.14C101.18 18.14 100.93 18.14 100.67 18.14C100.89 18.8 101.11 19.48 101.33 20.18H101.43C108.23 20.18 115.66 20.69 123.77 21.81C145.43 24.81 159.05 30.36 168 37.13C170.66 39.14 172.91 41.26 174.84 43.47C179.4 48.68 182.19 54.36 184.3 60.11C185.48 63.32 186.45 66.53 187.41 69.71C187.68 70.61 187.95 71.5 188.22 72.4C188.97 74.84 189.74 77.24 190.63 79.58C192.53 82.58 194.02 85.66 195.28 88.75C196.57 90.64 198.07 92.42 199.85 94.05C203.05 96.99 207.15 99.45 212.57 101.27C219.25 103.52 225.7 104.3 231.86 104.3C235.58 104.3 239.2 104.02 242.71 103.6C245.91 103.22 249.03 102.73 252.04 102.24V100.17C248.93 100.67 245.75 101.18 242.47 101.57C239.02 101.98 235.48 102.26 231.86 102.26C225.87 102.26 219.66 101.5 213.23 99.34C207.29 97.34 203.05 94.55 199.88 91.2C197.58 88.78 195.84 86.07 194.41 83.15C194.2 82.72 194 82.29 193.8 81.85C193.11 80.33 192.5 78.75 191.93 77.13C191.38 75.57 190.86 73.98 190.36 72.35C190.3 72.17 190.25 71.98 190.19 71.8C189.92 70.93 189.66 70.05 189.39 69.16C189.32 68.92 189.24 68.67 189.17 68.43C188.72 66.93 188.26 65.41 187.77 63.88C187.33 62.49 186.86 61.1 186.35 59.71C186.31 59.61 186.28 59.51 186.24 59.41C185.13 56.41 183.84 53.4 182.21 50.45C182.09 50.24 181.98 50.03 181.86 49.82C181.8 49.71 181.74 49.6 181.67 49.5C181.61 49.4 181.55 49.29 181.49 49.19C181.3 48.87 181.1 48.55 180.9 48.22C180.47 47.53 180.02 46.84 179.55 46.16C177.49 43.2 174.99 40.33 171.88 37.63C171.05 36.91 170.17 36.19 169.25 35.5C159.93 28.45 145.93 22.81 124.07 19.78C115.87 18.64 108.35 18.13 101.45 18.13"
          fill="url(#paint113_linear_573_35549)"
        />
        <path
          d="M210.57 143.31C210.95 144.23 211.35 145.13 211.79 146.01C212.95 148.37 214.31 150.6 216 152.64C219.28 155.82 223.56 158.49 229.31 160.43C235.99 162.68 242.44 163.46 248.6 163.46C249.76 163.46 250.92 163.43 252.06 163.38V161.33C250.92 161.38 249.76 161.41 248.6 161.41C242.61 161.41 236.4 160.65 229.97 158.49C223.25 156.22 218.7 152.95 215.41 149C213.51 147.26 211.93 145.35 210.57 143.31ZM118.16 77.29C112.65 77.29 107.55 77.62 102.81 78.22C102.45 78.96 102.06 79.7 101.64 80.44C106.68 79.73 112.18 79.33 118.15 79.33C124.95 79.33 132.38 79.84 140.49 80.96C162.15 83.96 175.77 89.51 184.72 96.28C187.48 98.37 189.81 100.58 191.79 102.88C194.37 105.48 196.47 108.21 198.22 111.01C196.31 106.66 193.87 102.4 190.38 98.42C189.04 97.13 187.57 95.87 185.96 94.65C176.64 87.6 162.64 81.96 140.78 78.93C132.58 77.79 125.06 77.28 118.16 77.28"
          fill="url(#paint114_linear_573_35549)"
        />
        <path
          d="M207.18 133.41C207.31 133.83 207.43 134.25 207.56 134.66C208.48 137.67 209.43 140.57 210.57 143.31C211.92 145.35 213.51 147.26 215.41 149C218.61 151.93 222.71 154.4 228.13 156.22C234.81 158.47 241.25 159.25 247.41 159.25C248.98 159.25 250.53 159.2 252.06 159.11V157.06C250.53 157.15 248.98 157.2 247.41 157.2C241.42 157.2 235.21 156.44 228.78 154.28C220.49 151.49 215.52 147.16 212.11 141.92C210.55 139.93 209.27 137.79 208.17 135.54C207.83 134.84 207.5 134.13 207.19 133.4M116.97 73.08C112.66 73.08 108.59 73.28 104.75 73.66C104.49 74.38 104.21 75.09 103.91 75.8C107.98 75.36 112.33 75.12 116.97 75.12C123.77 75.12 131.2 75.63 139.31 76.75C160.97 79.75 174.59 85.3 183.54 92.07C186.2 94.08 188.45 96.2 190.38 98.41C193.87 102.39 196.32 106.65 198.22 111C199.88 113.66 201.22 116.38 202.37 119.12C201.61 116.62 200.82 114.11 199.89 111.6C198.37 107.48 196.52 103.4 193.91 99.5C191.49 96.32 188.52 93.27 184.77 90.44C175.45 83.39 161.45 77.75 139.59 74.72C131.39 73.58 123.87 73.07 116.97 73.07"
          fill="url(#paint115_linear_573_35549)"
        />
        <path
          d="M205.72 128.6C206.08 129.75 206.42 130.89 206.76 132.02C206.9 132.49 207.04 132.95 207.18 133.41C207.49 134.13 207.82 134.84 208.16 135.55C209.26 137.8 210.55 139.94 212.1 141.93C215.66 146.48 220.65 150.25 228.17 152.78C234.85 155.03 241.29 155.81 247.45 155.81C249 155.81 250.53 155.76 252.05 155.67V153.62C250.53 153.71 249 153.76 247.45 153.76C241.46 153.76 235.25 153 228.82 150.84C222.14 148.59 217.61 145.34 214.32 141.42C211.09 138.48 208.78 135.07 206.96 131.35C206.52 130.45 206.11 129.53 205.72 128.59M117.02 69.64C113.11 69.64 109.4 69.81 105.89 70.12C105.69 70.83 105.48 71.53 105.25 72.23C108.95 71.87 112.87 71.68 117.02 71.68C123.82 71.68 131.25 72.19 139.36 73.31C161.01 76.31 174.64 81.86 183.59 88.63C188.03 91.99 191.33 95.66 193.91 99.51C196.52 103.41 198.38 107.49 199.89 111.61C200.81 114.12 201.61 116.63 202.37 119.13C202.46 119.35 202.55 119.58 202.65 119.8L202.62 119.7C202.35 118.81 202.08 117.91 201.81 117.02C200.85 113.84 199.89 110.63 198.71 107.42C196.58 101.63 193.77 95.91 189.15 90.67C187.84 89.41 186.4 88.19 184.83 87C175.51 79.95 161.51 74.31 139.65 71.28C131.45 70.14 123.93 69.63 117.03 69.63"
          fill="url(#paint116_linear_573_35549)"
        />
        <path
          d="M115.83 65.44C112.73 65.44 109.76 65.54 106.91 65.74C106.78 66.44 106.63 67.13 106.47 67.82C109.45 67.6 112.56 67.48 115.82 67.48C122.62 67.48 130.05 67.99 138.16 69.11C159.82 72.11 173.44 77.66 182.39 84.43C185 86.41 187.22 88.49 189.14 90.66C193.76 95.9 196.57 101.62 198.7 107.41C199.88 110.62 200.85 113.83 201.8 117.01C202.07 117.91 202.34 118.8 202.61 119.69L202.64 119.79C202.97 120.61 203.29 121.43 203.59 122.25C204.37 124.36 205.06 126.48 205.7 128.58C206.09 129.51 206.5 130.43 206.94 131.34C208.76 135.06 211.08 138.47 214.3 141.41C217.49 144.32 221.57 146.76 226.95 148.57C233.63 150.82 240.08 151.6 246.24 151.6C248.2 151.6 250.13 151.52 252.03 151.39V149.34C250.12 149.48 248.19 149.56 246.24 149.56C240.25 149.56 234.04 148.8 227.61 146.64C221.82 144.69 217.64 141.99 214.49 138.75C210.61 135.58 207.94 131.79 205.89 127.62C204.09 123.96 202.78 120 201.55 115.97C201.28 115.08 201.01 114.18 200.74 113.29C199.78 110.11 198.82 106.9 197.63 103.69C195.18 97.04 191.84 90.49 185.9 84.65C185.17 84.03 184.41 83.42 183.62 82.82C174.3 75.77 160.3 70.13 138.44 67.1C130.24 65.96 122.72 65.45 115.82 65.45"
          fill="url(#paint117_linear_573_35549)"
        />
        <path
          d="M114.77 61.71C112.28 61.71 109.86 61.78 107.53 61.91C107.44 62.6 107.33 63.29 107.22 63.98C109.64 63.84 112.16 63.76 114.76 63.76C121.56 63.76 128.99 64.27 137.1 65.39C158.76 68.39 172.38 73.94 181.33 80.71C183.01 81.98 184.52 83.29 185.9 84.65C191.85 90.49 195.19 97.04 197.63 103.69C198.81 106.9 199.78 110.11 200.74 113.29C201.01 114.19 201.28 115.08 201.55 115.97C202.79 120 204.1 123.95 205.89 127.62C207.93 131.79 210.6 135.59 214.49 138.75C217.48 141.19 221.2 143.26 225.9 144.85C232.58 147.1 239.03 147.88 245.19 147.88C247.51 147.88 249.8 147.77 252.04 147.58V145.53C249.79 145.72 247.51 145.83 245.19 145.83C239.2 145.83 232.99 145.07 226.56 142.91C219.88 140.66 215.34 137.41 212.06 133.49C208.84 130.55 206.52 127.14 204.7 123.43C202.9 119.77 201.59 115.81 200.36 111.78C200.09 110.89 199.82 109.99 199.55 109.1C198.59 105.92 197.63 102.71 196.44 99.5C194.31 93.71 191.5 88 186.88 82.76C185.56 81.5 184.13 80.28 182.56 79.09C173.24 72.04 159.24 66.4 137.38 63.37C129.18 62.23 121.66 61.72 114.76 61.72"
          fill="url(#paint118_linear_573_35549)"
        />
        <path
          d="M113.58 57.51C111.67 57.51 109.81 57.55 108 57.63C107.94 58.32 107.87 59.01 107.8 59.69C109.67 59.61 111.6 59.56 113.57 59.56C120.37 59.56 127.8 60.07 135.91 61.19C157.57 64.19 171.19 69.74 180.14 76.51C182.75 78.49 184.98 80.58 186.89 82.75C191.51 87.99 194.31 93.7 196.45 99.49C197.63 102.7 198.6 105.91 199.56 109.09C199.83 109.99 200.1 110.88 200.37 111.77C201.61 115.8 202.92 119.75 204.71 123.42C206.53 127.13 208.84 130.54 212.07 133.48C215.26 136.39 219.35 138.83 224.72 140.65C231.4 142.9 237.85 143.68 244.01 143.68C246.75 143.68 249.43 143.53 252.05 143.28V141.23C249.42 141.48 246.74 141.64 244.01 141.64C238.02 141.64 231.81 140.88 225.38 138.72C220.28 137 216.44 134.71 213.44 131.97C208.95 128.64 205.97 124.54 203.74 119.98C201.94 116.31 200.63 112.36 199.4 108.33C199.13 107.44 198.86 106.54 198.59 105.65C197.63 102.47 196.67 99.26 195.48 96.05C192.78 88.71 188.99 81.5 181.81 75.21C181.67 75.1 181.53 74.99 181.38 74.88C172.06 67.83 158.06 62.19 136.2 59.16C128 58.02 120.48 57.51 113.58 57.51Z"
          fill="url(#paint119_linear_573_35549)"
        />
        <path
          d="M112.6 54.06C111.11 54.06 109.64 54.08 108.2 54.13C108.2 54.38 108.2 54.63 108.2 54.88C108.17 55.32 108.14 55.75 108.11 56.18C109.57 56.13 111.07 56.1 112.59 56.1C119.39 56.1 126.82 56.61 134.93 57.73C156.59 60.73 170.21 66.28 179.16 73.05C180.09 73.75 180.96 74.47 181.79 75.19C188.97 81.47 192.76 88.69 195.46 96.03C196.64 99.24 197.61 102.45 198.57 105.63C198.84 106.53 199.11 107.42 199.38 108.31C200.62 112.34 201.93 116.29 203.72 119.96C205.95 124.52 208.93 128.62 213.42 131.95C216.21 134.02 219.59 135.79 223.73 137.19C230.41 139.44 236.86 140.22 243.02 140.22C246.1 140.22 249.11 140.03 252.04 139.72V137.66C249.09 137.97 246.09 138.17 243.02 138.17C237.03 138.17 230.82 137.41 224.39 135.25C217.67 132.98 213.12 129.71 209.83 125.76C206.64 122.84 204.35 119.44 202.54 115.76C200.74 112.09 199.43 108.14 198.2 104.11C197.93 103.22 197.66 102.32 197.39 101.42C196.43 98.24 195.47 95.03 194.28 91.82C192.16 86.07 189.38 80.39 184.82 75.18C183.48 73.89 182.01 72.63 180.4 71.41C171.08 64.36 157.08 58.72 135.22 55.69C127.02 54.55 119.5 54.04 112.6 54.04"
          fill="url(#paint120_linear_573_35549)"
        />
        <path
          d="M111.41 49.86C110.25 49.86 109.11 49.87 107.98 49.9C108.04 50.59 108.09 51.27 108.12 51.94C109.2 51.91 110.29 51.9 111.4 51.9C118.2 51.9 125.63 52.41 133.74 53.53C155.4 56.53 169.02 62.08 177.97 68.85C180.63 70.86 182.88 72.98 184.81 75.19C189.37 80.4 192.16 86.08 194.27 91.83C195.45 95.04 196.42 98.25 197.38 101.43C197.65 102.33 197.92 103.22 198.19 104.12C199.43 108.15 200.74 112.1 202.53 115.77C204.34 119.46 206.63 122.85 209.82 125.77C213.02 128.7 217.12 131.17 222.54 132.99C229.22 135.24 235.66 136.02 241.82 136.02C245.32 136.02 248.72 135.77 252.03 135.39V133.33C248.71 133.71 245.3 133.97 241.82 133.97C235.83 133.97 229.62 133.21 223.19 131.05C218.15 129.35 214.34 127.09 211.36 124.4C208.49 121.81 206.4 118.83 204.72 115.58C204.6 115.34 204.48 115.1 204.36 114.87C203.72 113.57 203.15 112.22 202.61 110.84C202.01 109.28 201.46 107.68 200.93 106.04C200.66 105.21 200.4 104.37 200.14 103.52C199.99 103.04 199.85 102.56 199.7 102.08C199.58 101.68 199.46 101.28 199.34 100.88C199.03 99.84 198.71 98.79 198.38 97.73C198 96.49 197.6 95.24 197.18 93.98C196.86 93.03 196.53 92.08 196.18 91.13C195.89 90.34 195.58 89.55 195.27 88.76C194.02 85.66 192.52 82.58 190.62 79.59C190.43 79.3 190.24 79 190.05 78.71L189.89 78.47L189.75 78.27C189.66 78.13 189.56 77.99 189.46 77.85C189.2 77.47 188.92 77.09 188.65 76.72C188.08 75.96 187.48 75.2 186.85 74.45C184.72 71.93 182.21 69.51 179.19 67.23C178.75 66.89 178.29 66.56 177.83 66.23C168.52 59.64 154.83 54.4 134.01 51.51C125.81 50.37 118.29 49.86 111.39 49.86"
          fill="url(#paint121_linear_573_35549)"
        />
        <path
          d="M215.4 162.51C215.47 162.75 215.55 163 215.62 163.24C215.74 163.65 215.87 164.05 215.99 164.46C216.57 165.28 217.23 166.03 217.98 166.71C219.99 168.51 221.82 170.1 223.63 171.5C223.05 171 222.5 170.48 221.99 169.95C219.27 167.75 217.14 165.24 215.41 162.51M123.23 96.57C108.57 96.57 96.73 98.9 87.18 102.55C86.87 103.56 86.74 104.36 86.68 104.95C95.09 101.56 105.41 99.22 118.09 98.72C120.24 98.55 122.42 98.45 124.62 98.45C125.48 98.45 126.33 98.46 127.19 98.5C130.22 98.5 136.3 98.89 143.83 100.03C144.41 100.1 144.99 100.18 145.57 100.26C167.23 103.26 180.85 108.81 189.8 115.58C191.48 116.85 192.99 118.16 194.37 119.51L194.39 119.53C197.41 122.09 199.83 124.73 201.81 127.4C199.69 123.31 196.96 119.35 193.18 115.67C192.49 115.09 191.78 114.51 191.03 113.95C181.71 106.9 167.71 101.26 145.85 98.23C137.65 97.09 130.13 96.58 123.23 96.58"
          fill="url(#paint122_linear_573_35549)"
        />
        <path
          d="M214 157.97C214.49 159.5 214.95 161.02 215.4 162.52C217.14 165.25 219.26 167.76 221.98 169.96C224.96 172.37 228.65 174.42 233.31 175.99C239.79 178.17 246.05 178.97 252.05 179.02V176.97C246.23 176.92 240.2 176.15 233.97 174.05C227.25 171.78 222.71 168.51 219.41 164.56C217.23 162.56 215.47 160.35 213.99 157.97M122.17 92.85C109.11 92.85 98.28 94.7 89.32 97.69C88.79 98.64 88.36 99.52 88.01 100.31C97.12 97 108.34 94.9 122.16 94.9C128.96 94.9 136.39 95.41 144.51 96.53C166.17 99.53 179.79 105.08 188.74 111.85C190.37 113.08 191.84 114.35 193.18 115.66C196.96 119.34 199.69 123.3 201.81 127.39C202.47 128.28 203.09 129.18 203.65 130.08C201.56 124.51 198.81 119.03 194.39 113.98C193.05 112.69 191.58 111.43 189.97 110.21C180.65 103.16 166.65 97.52 144.79 94.49C136.59 93.35 129.07 92.84 122.17 92.84"
          fill="url(#paint123_linear_573_35549)"
        />
        <path
          d="M212.58 153.79C213.09 155.18 213.56 156.57 214 157.96C215.47 160.34 217.24 162.56 219.42 164.55C222.62 167.48 226.72 169.95 232.13 171.77C238.81 174.02 245.25 174.8 251.41 174.8C251.62 174.8 251.84 174.8 252.05 174.8V172.75C251.84 172.75 251.62 172.75 251.41 172.75C245.42 172.75 239.21 171.99 232.78 169.83C227.71 168.12 223.88 165.84 220.89 163.12C217.28 160.45 214.64 157.28 212.58 153.78M120.98 88.64C110.27 88.64 101.06 89.88 93.15 91.98C92.42 92.92 91.78 93.81 91.2 94.65C99.46 92.19 109.29 90.68 120.98 90.68C127.78 90.68 135.21 91.19 143.33 92.31C164.99 95.31 178.61 100.86 187.56 107.63C190.22 109.64 192.47 111.76 194.4 113.97C198.82 119.02 201.57 124.5 203.66 130.07C203.83 130.34 204 130.62 204.16 130.89C203.75 129.65 203.33 128.4 202.87 127.16C200.16 119.8 196.36 112.56 189.13 106.26C189.01 106.17 188.9 106.08 188.78 105.99C179.46 98.94 165.46 93.3 143.6 90.27C135.4 89.13 127.88 88.62 120.98 88.62"
          fill="url(#paint124_linear_573_35549)"
        />
        <path
          d="M210.7 148.39C210.82 148.91 210.94 149.43 211.05 149.94C211.55 151.12 212.02 152.31 212.46 153.5C212.5 153.6 212.53 153.7 212.57 153.8C214.63 157.3 217.27 160.47 220.88 163.14C223.66 165.19 227.02 166.95 231.14 168.34C237.82 170.59 244.27 171.37 250.43 171.37C250.97 171.37 251.51 171.37 252.05 171.35V169.3C251.51 169.31 250.97 169.32 250.43 169.32C244.44 169.32 238.23 168.56 231.8 166.4C225.1 164.14 220.56 160.88 217.27 156.94C214.5 154.4 212.4 151.52 210.71 148.39M120 85.19C111.36 85.19 103.71 86 96.92 87.41C96.43 88.02 95.92 88.64 95.39 89.25C95.18 89.5 94.97 89.74 94.76 89.98C102.02 88.25 110.38 87.23 119.99 87.23C126.79 87.23 134.22 87.74 142.33 88.86C163.98 91.86 177.61 97.41 186.56 104.18C187.46 104.86 188.31 105.56 189.13 106.26C196.36 112.56 200.16 119.8 202.87 127.16C203.33 128.4 203.75 129.65 204.16 130.89C204.24 131.03 204.33 131.17 204.41 131.31C203.57 128.54 202.71 125.75 201.68 122.96C199.56 117.19 196.76 111.49 192.17 106.27C190.84 104.99 189.39 103.75 187.8 102.55C178.48 95.5 164.48 89.86 142.62 86.83C134.42 85.69 126.9 85.18 120 85.18"
          fill="url(#paint125_linear_573_35549)"
        />
        <path
          d="M118.81 81C112.15 81 106.06 81.48 100.51 82.35C100.03 83.13 99.51 83.9 98.97 84.68C104.9 83.63 111.49 83.05 118.81 83.05C125.61 83.05 133.04 83.56 141.15 84.68C162.81 87.68 176.43 93.23 185.38 100C188.02 101.99 190.25 104.1 192.18 106.29C196.77 111.51 199.57 117.21 201.69 122.98C202.72 125.77 203.58 128.56 204.42 131.33C207.85 137.18 209.47 143.04 210.71 148.39C212.4 151.52 214.5 154.41 217.27 156.94C220.47 159.86 224.56 162.32 229.96 164.14C236.64 166.39 243.09 167.17 249.25 167.17C250.19 167.17 251.13 167.15 252.06 167.12V165.07C251.13 165.1 250.19 165.12 249.25 165.12C243.26 165.12 237.05 164.36 230.62 162.2C223.86 159.92 219.3 156.62 216 152.64C214.31 150.59 212.95 148.37 211.79 146.01C211.36 145.13 210.96 144.23 210.57 143.31C209.43 140.57 208.49 137.67 207.56 134.66C207.43 134.25 207.31 133.83 207.18 133.41C207.04 132.95 206.9 132.48 206.76 132.02C206.42 130.89 206.08 129.75 205.72 128.6C205.07 126.5 204.38 124.39 203.61 122.27C203.31 121.45 202.99 120.63 202.66 119.81C202.57 119.59 202.48 119.36 202.38 119.14C201.24 116.4 199.89 113.68 198.23 111.02C196.48 108.22 194.38 105.49 191.8 102.89C190.25 101.33 188.54 99.82 186.61 98.37C177.29 91.32 163.29 85.68 141.43 82.65C133.23 81.51 125.71 81 118.81 81Z"
          fill="url(#paint126_linear_573_35549)"
        />
        <path
          d="M211.06 149.94C212.36 155.69 213.37 160.76 215.99 164.47C215.87 164.07 215.75 163.66 215.62 163.25C215.55 163.01 215.47 162.76 215.4 162.52C214.95 161.02 214.49 159.5 214 157.97C213.55 156.58 213.09 155.19 212.58 153.8C212.54 153.7 212.51 153.6 212.47 153.5C212.03 152.31 211.57 151.12 211.06 149.94Z"
          fill="url(#paint127_linear_573_35549)"
        />
        <path
          d="M128.16 200.64C128.04 200.67 127.93 200.69 127.82 200.71C127.68 200.88 127.53 201.05 127.39 201.22C127.65 201.03 127.91 200.84 128.16 200.64Z"
          fill="url(#paint128_linear_573_35549)"
        />
        <path
          d="M127.82 200.71C126.63 200.96 125.91 201.05 125.7 201.07L125.68 201.1C125.96 201.34 126.24 201.58 126.52 201.83C126.81 201.62 127.1 201.42 127.38 201.21C127.53 201.04 127.67 200.87 127.81 200.7"
          fill="url(#paint129_linear_573_35549)"
        />
        <path
          d="M125.71 201.07L125.65 201.08L125.68 201.11L125.71 201.07Z"
          fill="url(#paint130_linear_573_35549)"
        />
        <path
          d="M131.95 202.22C131.13 202.71 130.31 203.21 129.49 203.73C129.31 203.84 129.13 203.96 128.96 204.07C129.06 204.16 129.16 204.25 129.25 204.35C129.73 204 130.21 203.64 130.68 203.27C131.11 202.93 131.54 202.58 131.95 202.22ZM155.28 186.95C155.16 187.04 155.05 187.13 154.93 187.22C154.78 187.5 154.63 187.77 154.47 188.03C154.75 187.68 155.02 187.32 155.28 186.95ZM159.85 183.05L159.83 183.07L159.79 183.15L159.85 183.05Z"
          fill="url(#paint131_linear_573_35549)"
        />
        <path
          d="M132.42 201.8C131.2 202.48 129.99 203.17 128.78 203.89L128.96 204.06C129.14 203.95 129.32 203.83 129.49 203.72C130.31 203.2 131.13 202.7 131.95 202.21C132.11 202.07 132.27 201.94 132.42 201.8Z"
          fill="url(#paint132_linear_573_35549)"
        />
        <path
          d="M132.67 201.58C132.18 201.85 131.69 202.12 131.19 202.4C130.35 202.86 129.51 203.33 128.66 203.8L128.77 203.9C129.98 203.18 131.2 202.49 132.41 201.81L132.66 201.59"
          fill="url(#paint133_linear_573_35549)"
        />
        <path
          d="M133 201.28C132.09 201.81 131.16 202.34 130.23 202.86C129.69 203.16 129.16 203.46 128.62 203.76L128.67 203.81C129.51 203.34 130.36 202.87 131.2 202.41C131.69 202.14 132.18 201.87 132.68 201.59C132.79 201.49 132.9 201.39 133 201.29"
          fill="url(#paint134_linear_573_35549)"
        />
        <path
          d="M134.09 200.22C132.31 201.44 130.45 202.57 128.54 203.67L128.62 203.75C129.16 203.45 129.7 203.15 130.23 202.85C131.16 202.33 132.08 201.81 133 201.27C133.37 200.93 133.73 200.57 134.09 200.21"
          fill="url(#paint135_linear_573_35549)"
        />
        <path
          d="M135.94 198.16C135.81 198.21 135.69 198.26 135.57 198.31C134.96 198.85 134.33 199.38 133.69 199.88C132.02 201.2 130.25 202.4 128.41 203.54L128.54 203.66C130.45 202.56 132.31 201.43 134.09 200.21C134.73 199.55 135.35 198.87 135.94 198.15"
          fill="url(#paint136_linear_573_35549)"
        />
        <path
          d="M135.56 198.31C134.59 198.69 133.68 199.02 132.83 199.31C131.87 200.24 130.87 201.11 129.82 201.93C129.25 202.38 128.67 202.82 128.07 203.24C128.18 203.34 128.29 203.44 128.4 203.54C130.24 202.4 132.01 201.19 133.68 199.88C134.32 199.37 134.95 198.85 135.56 198.31Z"
          fill="url(#paint137_linear_573_35549)"
        />
        <path
          d="M132.83 199.31C130.92 199.95 129.34 200.37 128.16 200.64C127.91 200.84 127.65 201.03 127.39 201.22C127.1 201.43 126.82 201.64 126.53 201.84C127.03 202.28 127.55 202.75 128.08 203.25C128.67 202.82 129.26 202.39 129.83 201.94C130.88 201.11 131.88 200.24 132.84 199.32"
          fill="url(#paint138_linear_573_35549)"
        />
        <path
          d="M169.42 170.59C168.48 172.7 167.2 174.73 165.75 176.64C164.66 179.6 163.21 182.12 161.49 184.31C160.51 185.99 159.39 187.51 158.17 188.91C158.57 188.62 158.95 188.32 159.34 188.02C161.91 185.99 164.2 183.7 166.09 180.93C167.61 177.99 168.76 174.6 169.42 170.6"
          fill="url(#paint139_linear_573_35549)"
        />
        <path
          d="M164.04 178.73C162.69 180.29 161.26 181.74 159.86 183.05L159.8 183.15C158.28 186.51 156.3 189.3 153.98 191.71C155.43 190.81 156.84 189.89 158.17 188.9C159.39 187.5 160.51 185.98 161.49 184.3C162.48 182.61 163.34 180.76 164.04 178.72"
          fill="url(#paint140_linear_573_35549)"
        />
        <path
          d="M159.83 183.07C158.22 184.57 156.64 185.88 155.28 186.95C155.02 187.32 154.75 187.68 154.47 188.03C153.09 190.38 151.46 192.43 149.63 194.25C151.12 193.41 152.57 192.58 153.97 191.71C156.29 189.3 158.27 186.51 159.79 183.15L159.83 183.07Z"
          fill="url(#paint141_linear_573_35549)"
        />
        <path
          d="M154.93 187.22C154.14 187.83 153.43 188.35 152.84 188.77C152.19 189.23 151.54 189.68 150.9 190.11C149.51 192.43 147.87 194.45 146.04 196.25C146.27 196.12 146.5 196 146.72 195.87C147.53 195.42 148.34 194.97 149.13 194.53C149.3 194.44 149.46 194.34 149.63 194.25C151.46 192.42 153.09 190.38 154.47 188.03C154.63 187.76 154.78 187.49 154.93 187.22Z"
          fill="url(#paint142_linear_573_35549)"
        />
        <path
          d="M150.9 190.11C149.43 191.1 148.01 192 146.64 192.82C145.27 194.98 143.67 196.87 141.91 198.58C143.29 197.78 144.68 197.01 146.04 196.26C147.87 194.46 149.51 192.44 150.9 190.12"
          fill="url(#paint143_linear_573_35549)"
        />
        <path
          d="M146.63 192.82C145.5 193.49 144.41 194.11 143.35 194.68C141.84 197.02 140.06 199.05 138.08 200.86C139.34 200.06 140.62 199.3 141.89 198.57C143.66 196.87 145.25 194.97 146.62 192.81"
          fill="url(#paint144_linear_573_35549)"
        />
        <path
          d="M143.36 194.68C142.06 195.38 140.81 196.01 139.62 196.56C137.52 199.86 134.9 202.54 131.95 204.86C131.56 205.17 131.16 205.47 130.76 205.77L130.97 205.97C133.2 204.12 135.52 202.5 137.86 201.01L138.1 200.86C140.07 199.05 141.85 197.02 143.37 194.68"
          fill="url(#paint145_linear_573_35549)"
        />
        <path
          d="M139.61 196.57C138.31 197.18 137.08 197.71 135.93 198.17C135.34 198.89 134.72 199.57 134.08 200.23C133.72 200.59 133.36 200.94 132.99 201.29C132.88 201.39 132.77 201.49 132.67 201.59L132.42 201.81C132.26 201.95 132.11 202.09 131.95 202.22C131.53 202.58 131.11 202.93 130.68 203.27C130.21 203.64 129.74 204 129.25 204.35C129.74 204.81 130.24 205.29 130.75 205.78C131.15 205.48 131.55 205.18 131.94 204.87C134.89 202.54 137.51 199.87 139.61 196.57Z"
          fill="url(#paint146_linear_573_35549)"
        />
        <path
          d="M159.62 191.83C154.65 195.05 149.89 198.9 145.92 204.28C144.97 205.83 144.07 207.5 143.24 209.28C142.37 211.14 141.58 213.14 140.87 215.28C141.42 215.77 141.98 216.26 142.54 216.75C143.29 214.36 144.15 212.16 145.09 210.14C147.25 205.5 149.87 201.73 152.79 198.56C154.92 195.99 157.22 193.78 159.62 191.82M191.68 121.75C191.92 122.42 192.16 123.09 192.39 123.76C195.38 132.49 197.59 142 196.37 152.82C195.58 159.83 193.35 164.95 190.21 168.99C188.26 172.33 185.8 175.06 183 177.42C184.62 176.4 186.16 175.33 187.62 174.18C188.99 173.1 190.28 171.94 191.48 170.68C195.02 166.3 197.53 160.72 198.4 153.05C199.57 142.75 197.73 133.63 195.08 125.35L194.91 125.16C193.8 123.92 192.73 122.79 191.69 121.75"
          fill="url(#paint147_linear_573_35549)"
        />
        <path
          d="M160.98 190.75C159.68 191.5 158.39 192.27 157.1 193.09C150.49 197.28 144.22 202.52 139.59 210.89C139.52 211.04 139.45 211.19 139.38 211.34C139.1 211.95 138.82 212.57 138.56 213.2C139.09 213.68 139.62 214.17 140.17 214.67C140.51 213.82 140.87 213 141.24 212.21C142.62 209.23 144.2 206.62 145.92 204.29C149.89 198.91 154.65 195.06 159.62 191.84C160.07 191.47 160.52 191.12 160.98 190.77M184.78 115.8C184.84 115.95 184.9 116.09 184.95 116.24C186.16 119.34 187.4 122.51 188.53 125.81C191.52 134.54 193.73 144.05 192.51 154.87C191.91 160.21 190.47 164.44 188.44 167.95C186.16 172.99 182.82 176.74 178.89 179.87C180.3 179.07 181.67 178.26 183 177.42C185.8 175.06 188.26 172.33 190.21 168.99C192.38 165.26 193.91 160.75 194.55 155.11C195.82 143.87 193.52 134.03 190.48 125.16C189.68 122.82 188.82 120.53 187.96 118.31C186.86 117.37 185.8 116.54 184.79 115.81"
          fill="url(#paint148_linear_573_35549)"
        />
        <path
          d="M161.56 190.31C158.79 191.84 155.99 193.4 153.24 195.14C148.06 198.43 143.09 202.35 138.95 207.92C138.23 209.09 137.54 210.33 136.88 211.63C137.39 212.11 137.91 212.59 138.44 213.08C138.81 212.32 139.19 211.58 139.59 210.87C144.22 202.5 150.48 197.27 157.1 193.07C158.38 192.26 159.68 191.48 160.98 190.73C161.17 190.58 161.37 190.44 161.56 190.29M179.78 112.7C180.06 113.44 180.34 114.19 180.63 114.94C181.01 115.93 181.4 116.92 181.79 117.92C183 121.02 184.24 124.19 185.37 127.49C188.36 136.22 190.57 145.73 189.34 156.55C188.54 163.59 186.31 168.72 183.15 172.76C180.96 176.51 178.13 179.47 174.89 182.02C174.79 182.1 174.69 182.18 174.58 182.26C174.72 182.19 174.85 182.11 174.99 182.04C175.8 181.59 176.61 181.15 177.4 180.7C177.89 180.42 178.39 180.15 178.87 179.87C182.8 176.74 186.14 172.99 188.42 167.95C189.85 164.79 190.87 161.12 191.36 156.78C192.63 145.54 190.33 135.7 187.29 126.83C186.14 123.48 184.88 120.25 183.67 117.15C183.3 116.19 182.93 115.25 182.57 114.31C181.58 113.68 180.65 113.15 179.77 112.7"
          fill="url(#paint149_linear_573_35549)"
        />
        <path
          d="M161.99 189.99C161.66 190.18 161.33 190.36 161 190.54C160.19 190.99 159.37 191.45 158.55 191.9C155.64 193.51 152.71 195.13 149.82 196.96C144.53 200.32 139.45 204.34 135.27 210.11C135.71 210.53 136.17 210.96 136.63 211.39C137.37 210.16 138.15 209.01 138.96 207.92C143.09 202.35 148.06 198.42 153.25 195.14C156 193.4 158.79 191.84 161.57 190.31C161.71 190.2 161.86 190.09 162 189.99M174.44 110.58C175.14 112.69 175.94 114.83 176.77 117C177.15 117.99 177.54 118.98 177.93 119.98C179.14 123.08 180.38 126.25 181.51 129.56C184.5 138.29 186.71 147.8 185.48 158.62C184.8 164.66 183.06 169.28 180.58 173.03C178.3 177.53 175.14 180.96 171.47 183.86C171.22 184.05 170.97 184.25 170.72 184.44C172.01 183.7 173.3 182.99 174.58 182.28C174.68 182.2 174.78 182.12 174.89 182.04C178.12 179.49 180.96 176.52 183.15 172.78C185.34 169.04 186.88 164.51 187.52 158.85C188.79 147.61 186.49 137.77 183.45 128.9C182.3 125.55 181.04 122.32 179.83 119.22C179.44 118.23 179.06 117.25 178.69 116.28C178.05 114.62 177.44 113 176.88 111.41C175.99 111.06 175.17 110.8 174.44 110.6"
          fill="url(#paint150_linear_573_35549)"
        />
        <path
          d="M170.79 109.77V111.7C171.54 114.03 172.42 116.4 173.35 118.81C173.73 119.8 174.12 120.79 174.51 121.79C175.72 124.89 176.96 128.06 178.09 131.36C181.08 140.08 183.29 149.6 182.07 160.42C181.27 167.47 179.03 172.61 175.86 176.66C173.68 180.39 170.85 183.35 167.62 185.89C164.42 188.42 160.83 190.53 157.15 192.59C156.34 193.04 155.52 193.5 154.7 193.95C151.79 195.56 148.86 197.18 145.97 199.01C141.73 201.7 137.63 204.81 134.02 208.91C134.43 209.3 134.85 209.71 135.28 210.11C139.47 204.34 144.54 200.32 149.83 196.96C152.72 195.13 155.65 193.51 158.56 191.9C159.38 191.45 160.2 190.99 161.01 190.54C161.34 190.36 161.67 190.17 162 189.99C163.36 188.99 164.74 188.06 166.14 187.18C167.66 186.21 169.21 185.3 170.74 184.42C170.99 184.23 171.24 184.04 171.49 183.84C175.16 180.94 178.32 177.51 180.6 173.01C182.33 169.59 183.56 165.54 184.11 160.65C185.38 149.41 183.08 139.57 180.04 130.7C178.89 127.35 177.63 124.12 176.42 121.02C176.03 120.03 175.65 119.04 175.27 118.08C174.21 115.33 173.23 112.68 172.44 110.1C171.75 109.96 171.21 109.87 170.8 109.77"
          fill="url(#paint151_linear_573_35549)"
        />
        <path
          d="M170.79 118.53V124.2C171.95 127.18 173.14 130.24 174.23 133.42C177.22 142.14 179.43 151.66 178.21 162.47C177.61 167.79 176.18 172.02 174.16 175.51C171.85 180.63 168.46 184.42 164.46 187.57C161.26 190.1 157.67 192.21 153.99 194.27C153.18 194.72 152.36 195.18 151.54 195.63C148.63 197.24 145.7 198.86 142.81 200.69C139.46 202.81 136.21 205.2 133.21 208.13C133.48 208.39 133.75 208.65 134.02 208.91C137.63 204.81 141.73 201.7 145.97 199.01C148.86 197.18 151.79 195.56 154.7 193.95C155.52 193.5 156.34 193.04 157.15 192.59C160.83 190.53 164.42 188.42 167.62 185.89C170.84 183.35 173.67 180.39 175.86 176.66C178.06 172.91 179.6 168.38 180.25 162.7C181.52 151.46 179.22 141.62 176.18 132.75C175.03 129.4 173.77 126.17 172.56 123.07C172.17 122.08 171.79 121.1 171.42 120.13C171.21 119.59 171.01 119.06 170.81 118.53"
          fill="url(#paint152_linear_573_35549)"
        />
        <path
          d="M170.79 128.37V134.28C170.89 134.55 170.98 134.83 171.07 135.1C174.06 143.83 176.27 153.34 175.05 164.16C174.25 171.21 172.01 176.35 168.84 180.4C166.66 184.13 163.83 187.08 160.6 189.63C157.4 192.16 153.81 194.27 150.13 196.33C149.32 196.79 148.5 197.24 147.68 197.69C144.77 199.3 141.84 200.92 138.95 202.75C136.73 204.16 134.54 205.69 132.45 207.41C132.7 207.65 132.95 207.89 133.21 208.14C136.21 205.21 139.47 202.83 142.81 200.7C145.7 198.87 148.63 197.25 151.54 195.64C152.36 195.19 153.18 194.73 153.99 194.28C157.67 192.22 161.26 190.11 164.46 187.58C168.46 184.42 171.85 180.63 174.16 175.52C175.58 172.37 176.59 168.71 177.08 164.39C178.35 153.15 176.05 143.31 173.01 134.44C172.3 132.37 171.55 130.35 170.79 128.37Z"
          fill="url(#paint153_linear_573_35549)"
        />
        <path
          d="M170.79 141.67V150.93C171.56 155.74 171.8 160.81 171.19 166.2C170.49 172.4 168.67 177.12 166.09 180.92C164.2 183.7 161.91 185.99 159.34 188.01C158.96 188.31 158.57 188.61 158.17 188.9C156.83 189.89 155.43 190.81 153.98 191.71C152.58 192.58 151.12 193.42 149.64 194.25C149.47 194.34 149.31 194.44 149.14 194.53C148.35 194.97 147.54 195.42 146.73 195.87C146.5 196 146.28 196.12 146.05 196.25C144.68 197.01 143.3 197.77 141.92 198.57C140.65 199.3 139.37 200.06 138.11 200.86L137.87 201.01C135.53 202.5 133.21 204.12 130.98 205.97C131.46 206.43 131.96 206.91 132.46 207.4C134.56 205.68 136.74 204.15 138.96 202.74C141.85 200.91 144.78 199.29 147.69 197.68C148.51 197.23 149.33 196.77 150.14 196.32C153.82 194.26 157.41 192.15 160.61 189.62C163.83 187.08 166.66 184.12 168.85 180.39C171.05 176.64 172.59 172.11 173.24 166.43C174.27 157.33 172.96 149.15 170.81 141.67"
          fill="url(#paint154_linear_573_35549)"
        />
        <path
          d="M183.28 181.23C180.04 183.74 176.98 186.67 174.28 190.31C173.3 191.9 172.37 193.61 171.52 195.44C167.42 204.25 164.96 216.02 165.2 232.39C165.88 232.74 166.57 233.08 167.26 233.42C166.9 216.71 169.35 204.95 173.37 196.31C174.32 194.26 175.37 192.38 176.49 190.64C178.49 186.98 180.79 183.9 183.28 181.23Z"
          fill="url(#paint155_linear_573_35549)"
        />
        <path
          d="M184.98 179.51C178.5 183.67 172.39 188.87 167.85 197.09C167.79 197.22 167.72 197.36 167.66 197.49C163.92 205.54 161.54 216.05 161.34 230.29C162.01 230.67 162.69 231.05 163.37 231.42C163.46 216.83 165.83 206.28 169.51 198.35C170.92 195.32 172.52 192.67 174.28 190.31C176.98 186.68 180.04 183.74 183.28 181.23C183.84 180.63 184.4 180.06 184.98 179.5"
          fill="url(#paint156_linear_573_35549)"
        />
        <path
          d="M185.81 178.72C184.37 179.54 182.94 180.39 181.52 181.3C176.35 184.58 171.39 188.49 167.26 194.05C166.28 195.64 165.35 197.34 164.5 199.18C161.07 206.55 158.79 216 158.28 228.47C158.94 228.88 159.61 229.28 160.28 229.68C160.69 216.85 162.97 207.33 166.36 200.04C166.84 199.02 167.34 198.03 167.86 197.09C172.4 188.87 178.52 183.67 184.99 179.51C185.26 179.24 185.54 178.98 185.82 178.73M209.22 162.18C208.39 163.24 207.51 164.23 206.57 165.17C207.62 164.58 208.65 163.99 209.65 163.39C209.5 162.99 209.35 162.59 209.21 162.18"
          fill="url(#paint157_linear_573_35549)"
        />
        <path
          d="M186.08 178.48C183.42 179.95 180.74 181.45 178.1 183.12C172.08 186.94 166.35 191.61 161.88 198.73C161.45 199.53 161.04 200.36 160.64 201.22C157.62 207.71 155.5 215.79 154.68 226.14C155.32 226.57 155.97 227 156.64 227.43C157.38 216.73 159.5 208.54 162.5 202.09C163.91 199.06 165.51 196.41 167.26 194.05C171.39 188.5 176.35 184.58 181.52 181.3C182.94 180.4 184.38 179.55 185.81 178.72L186.08 178.47M208.51 159.84C206.99 162.67 205.11 165.07 202.97 167.17C203.2 167.04 203.43 166.92 203.65 166.79C204.46 166.34 205.27 165.9 206.06 165.45C206.23 165.36 206.39 165.26 206.56 165.17C207.5 164.23 208.38 163.24 209.21 162.18C208.95 161.42 208.72 160.64 208.51 159.83"
          fill="url(#paint158_linear_573_35549)"
        />
        <path
          d="M186.34 178.24C186.04 178.41 185.73 178.58 185.42 178.75C184.61 179.21 183.79 179.66 182.97 180.11C180.06 181.72 177.13 183.34 174.24 185.17C169.06 188.46 164.08 192.38 159.95 197.96C158.98 199.53 158.07 201.22 157.22 203.04C154.6 208.68 152.65 215.52 151.64 224.01C152.27 224.46 152.9 224.91 153.55 225.36C154.49 216.53 156.45 209.55 159.07 203.9C159.93 202.04 160.87 200.32 161.88 198.73C166.35 191.61 172.08 186.94 178.1 183.12C180.74 181.45 183.42 179.95 186.08 178.48L186.34 178.24ZM207.76 156.7C206.77 158.99 205.55 161 204.15 162.79C202.66 165.35 200.86 167.54 198.84 169.49C200.22 168.69 201.61 167.92 202.97 167.17C205.11 165.06 206.99 162.67 208.51 159.84C208.24 158.83 207.99 157.78 207.76 156.7Z"
          fill="url(#paint159_linear_573_35549)"
        />
        <path
          d="M186.92 177.72C185.4 178.65 183.84 179.54 182.26 180.43C181.45 180.88 180.63 181.34 179.81 181.79C176.9 183.4 173.97 185.02 171.08 186.85C164.47 191.04 158.2 196.28 153.58 204.64C153.51 204.79 153.44 204.94 153.37 205.1C151.23 209.71 149.53 215.13 148.43 221.6C149.04 222.07 149.65 222.54 150.27 223C151.33 216.22 153.04 210.63 155.22 205.96C156.62 202.95 158.21 200.31 159.95 197.97C164.09 192.39 169.06 188.47 174.24 185.18C177.13 183.35 180.06 181.73 182.97 180.12C183.79 179.67 184.61 179.21 185.42 178.76C185.73 178.59 186.03 178.42 186.34 178.25C186.53 178.08 186.73 177.9 186.92 177.73M206.34 149.74C205.67 154.56 204.3 158.45 202.41 161.7C200.56 165.79 198.01 169.03 195.02 171.78C196.28 170.98 197.56 170.23 198.83 169.49C200.85 167.54 202.65 165.35 204.14 162.79C205.48 160.49 206.58 157.89 207.38 154.92C207.04 153.25 206.71 151.52 206.33 149.74"
          fill="url(#paint160_linear_573_35549)"
        />
        <path
          d="M203.46 139.37C203.76 142.87 203.76 146.51 203.33 150.31C202.54 157.32 200.31 162.44 197.17 166.48C194.98 170.25 192.13 173.22 188.89 175.78C185.69 178.31 182.1 180.42 178.42 182.48C177.6 182.94 176.79 183.39 175.97 183.84C173.06 185.45 170.13 187.07 167.24 188.9C162.04 192.2 157.05 196.14 152.9 201.75C151.94 203.31 151.05 204.97 150.21 206.76C148.48 210.48 147.04 214.73 145.98 219.63C146.57 220.11 147.16 220.59 147.77 221.06C148.83 215.87 150.29 211.44 152.07 207.62C152.55 206.58 153.06 205.58 153.59 204.63C158.22 196.26 164.48 191.03 171.09 186.84C173.98 185.01 176.91 183.39 179.82 181.78C180.64 181.33 181.46 180.87 182.27 180.42C183.85 179.54 185.41 178.64 186.93 177.71C189.46 175.51 192.11 173.62 194.8 171.92L195.04 171.77C198.03 169.02 200.58 165.78 202.43 161.69C203.86 158.53 204.88 154.86 205.37 150.53C205.52 149.23 205.62 147.94 205.67 146.68C205.1 144.3 204.4 141.85 203.47 139.36"
          fill="url(#paint161_linear_573_35549)"
        />
        <path
          d="M196.84 127.48C199.1 135.05 200.51 143.24 199.47 152.36C198.53 160.67 195.58 166.32 191.47 170.67C190.28 171.94 188.98 173.09 187.61 174.17C186.15 175.32 184.61 176.39 182.99 177.41C181.66 178.25 180.29 179.06 178.88 179.86C178.39 180.14 177.9 180.41 177.41 180.69C176.62 181.13 175.81 181.58 175 182.03C174.86 182.1 174.73 182.18 174.59 182.25C173.32 182.95 172.02 183.67 170.73 184.41C169.19 185.29 167.65 186.2 166.13 187.17C164.74 188.05 163.35 188.99 161.99 189.98C161.84 190.09 161.7 190.19 161.56 190.3C161.37 190.45 161.17 190.59 160.98 190.74C160.52 191.09 160.07 191.45 159.62 191.81C157.22 193.77 154.92 195.97 152.79 198.55C150.38 201.46 148.2 204.83 146.35 208.81C145.16 211.38 144.1 214.2 143.21 217.31C143.77 217.79 144.35 218.28 144.93 218.76C145.84 215.39 146.95 212.37 148.21 209.67C149.6 206.69 151.17 204.07 152.89 201.74C157.04 196.13 162.02 192.19 167.23 188.89C170.12 187.06 173.05 185.44 175.96 183.83C176.78 183.38 177.6 182.93 178.41 182.47C182.09 180.41 185.68 178.3 188.88 175.77C192.12 173.21 194.97 170.23 197.16 166.47C199.33 162.73 200.86 158.23 201.5 152.58C202.31 145.38 201.66 138.76 200.29 132.6C199.31 130.89 198.16 129.17 196.83 127.46"
          fill="url(#paint162_linear_573_35549)"
        />
        <path
          d="M207.6 169.77C205.93 171.4 204.34 173.19 202.86 175.19C201.91 176.74 201.01 178.41 200.18 180.19C195.25 190.79 192.7 205.66 194.34 227.67C194.72 232.74 195.27 237.52 195.98 242.01C196.68 242.1 197.38 242.19 198.09 242.27C197.35 237.67 196.77 232.76 196.37 227.52C194.79 206.43 197.13 192.18 201.57 182.06C202.24 180.08 202.99 178.23 203.79 176.5C204.94 174.03 206.21 171.8 207.59 169.77"
          fill="url(#paint163_linear_573_35549)"
        />
        <path
          d="M209.56 167.08C204.64 170.76 200.1 175.35 196.54 181.8C196.47 181.95 196.4 182.1 196.33 182.25C191.4 192.85 188.85 207.72 190.49 229.73C190.79 233.8 191.21 237.67 191.73 241.36C192.43 241.48 193.13 241.6 193.85 241.71C193.3 237.88 192.85 233.84 192.53 229.58C190.9 207.78 193.45 193.29 198.18 183.12C199.56 180.14 201.14 177.53 202.86 175.2C204.34 173.19 205.93 171.4 207.6 169.78C208.23 168.84 208.89 167.95 209.56 167.1"
          fill="url(#paint164_linear_573_35549)"
        />
        <path
          d="M210.63 165.78C210.48 165.87 210.34 165.96 210.19 166.06C205.01 169.35 200.04 173.27 195.9 178.84C194.93 180.41 194.01 182.1 193.17 183.92C188.24 194.52 185.69 209.39 187.33 231.4C187.57 234.61 187.88 237.69 188.25 240.67C188.95 240.82 189.65 240.97 190.37 241.1C189.96 237.95 189.62 234.67 189.37 231.25C187.74 209.45 190.29 194.96 195.02 184.79C195.5 183.75 196.01 182.76 196.54 181.8C200.1 175.35 204.64 170.77 209.56 167.08C209.91 166.64 210.27 166.2 210.63 165.78Z"
          fill="url(#paint165_linear_573_35549)"
        />
        <path
          d="M210.61 165.56C209.32 166.3 208.04 167.07 206.76 167.88C200.74 171.7 195.01 176.38 190.54 183.5C190.11 184.3 189.7 185.12 189.3 185.98C184.37 196.58 181.82 211.45 183.46 233.46C183.62 235.58 183.81 237.64 184.02 239.65C184.72 239.84 185.42 240.01 186.14 240.19C185.89 237.96 185.67 235.67 185.5 233.31C183.87 211.51 186.42 197.02 191.15 186.85C192.55 183.84 194.14 181.2 195.89 178.85C200.02 173.28 205 169.35 210.18 166.07C210.33 165.98 210.47 165.88 210.62 165.79L210.68 165.71L210.61 165.56Z"
          fill="url(#paint166_linear_573_35549)"
        />
        <path
          d="M210.56 165.46C208 166.88 205.43 168.32 202.9 169.93C197.73 173.21 192.77 177.12 188.64 182.68C187.66 184.27 186.73 185.97 185.88 187.81C180.95 198.41 178.4 213.28 180.04 235.29C180.12 236.41 180.22 237.51 180.32 238.6C181.02 238.82 181.72 239.03 182.43 239.23C182.3 237.89 182.18 236.53 182.08 235.14C180.45 213.34 183 198.85 187.73 188.68C188.59 186.83 189.53 185.11 190.53 183.52C195 176.4 200.73 171.72 206.75 167.9C208.03 167.09 209.31 166.32 210.6 165.58L210.55 165.48"
          fill="url(#paint167_linear_573_35549)"
        />
        <path
          d="M210.54 165.41C209.85 165.79 209.17 166.17 208.48 166.55C205.57 168.16 202.64 169.78 199.75 171.61C193.12 175.81 186.85 181.06 182.22 189.46C182.16 189.59 182.1 189.72 182.04 189.85C177.12 200.43 174.57 215.26 176.19 237.19C176.88 237.44 177.59 237.69 178.3 237.93C178.28 237.68 178.26 237.43 178.24 237.18C176.61 215.38 179.16 200.89 183.89 190.72C185.3 187.69 186.9 185.04 188.65 182.68C192.78 177.13 197.74 173.21 202.91 169.93C205.44 168.32 208.01 166.88 210.57 165.46L210.54 165.41Z"
          fill="url(#paint168_linear_573_35549)"
        />
        <path
          d="M210.48 165.28C209.36 165.94 208.22 166.59 207.07 167.23C206.26 167.69 205.44 168.14 204.62 168.59C201.71 170.2 198.78 171.82 195.89 173.65C190.72 176.93 185.76 180.85 181.63 186.4C180.65 187.99 179.73 189.69 178.88 191.52C174.19 201.61 171.65 215.58 172.84 235.89C173.53 236.17 174.23 236.45 174.94 236.72C173.63 216.1 176.17 202.23 180.74 192.39C181.21 191.37 181.71 190.39 182.23 189.45C186.86 181.05 193.14 175.81 199.76 171.6C202.65 169.77 205.58 168.15 208.49 166.54C209.18 166.16 209.87 165.78 210.55 165.4L210.49 165.28"
          fill="url(#paint169_linear_573_35549)"
        />
        <path
          d="M209.65 163.39C208.64 163.99 207.61 164.58 206.57 165.17C206.4 165.26 206.24 165.36 206.07 165.45C205.27 165.9 204.47 166.34 203.66 166.79C203.43 166.92 203.2 167.04 202.98 167.17C201.61 167.93 200.23 168.69 198.85 169.49C197.58 170.22 196.3 170.98 195.04 171.78L194.8 171.93C192.11 173.64 189.46 175.52 186.93 177.72C186.73 177.89 186.54 178.06 186.35 178.24L186.09 178.48L185.82 178.73C185.54 178.99 185.27 179.25 184.99 179.51C184.42 180.07 183.85 180.64 183.29 181.24C180.8 183.91 178.5 186.99 176.5 190.65C175.99 191.59 175.49 192.57 175.02 193.59C170.63 203.04 168.12 215.88 168.79 234.15C169.48 234.47 170.17 234.78 170.87 235.09C170.07 216.49 172.57 203.69 176.87 194.46C178.28 191.44 179.88 188.79 181.63 186.43C185.76 180.87 190.72 176.96 195.89 173.68C198.78 171.85 201.71 170.23 204.62 168.62C205.44 168.17 206.26 167.71 207.07 167.26C208.22 166.62 209.36 165.97 210.48 165.31C210.18 164.7 209.91 164.07 209.66 163.42"
          fill="url(#paint170_linear_573_35549)"
        />
        <path
          d="M224.28 178.19C222.66 186.85 222.14 197.45 223.12 210.6C223.8 219.66 225.03 227.76 226.71 235.01C227.29 234.46 227.86 233.91 228.43 233.35C226.92 226.52 225.79 218.92 225.16 210.45C224.21 197.72 224.68 187.48 226.18 179.14C225.54 178.85 224.9 178.53 224.27 178.2"
          fill="url(#paint171_linear_573_35549)"
        />
        <path
          d="M221.25 176.38C218.99 185.7 218.13 197.48 219.27 212.64C219.98 222.18 221.32 230.66 223.13 238.2C223.71 237.7 224.3 237.2 224.87 236.68C223.21 229.52 221.98 221.49 221.31 212.48C220.21 197.83 221.01 186.47 223.08 177.52C222.46 177.16 221.85 176.78 221.25 176.38Z"
          fill="url(#paint172_linear_573_35549)"
        />
        <path
          d="M218.99 174.72C216.08 184.53 214.84 197.29 216.11 214.32C216.85 224.18 218.25 232.9 220.15 240.62C220.74 240.16 221.33 239.69 221.91 239.21C220.15 231.83 218.85 223.52 218.15 214.16C216.92 197.72 218.07 185.43 220.74 176.02C220.15 175.61 219.56 175.17 218.99 174.71"
          fill="url(#paint173_linear_573_35549)"
        />
        <path
          d="M216.56 172.54C212.65 182.84 210.79 196.78 212.25 216.38C212.99 226.25 214.39 234.98 216.3 242.71C216.69 242.69 217.08 242.66 217.47 242.64C217.73 242.45 217.99 242.27 218.25 242.07C216.39 234.5 215.02 225.93 214.29 216.23C212.88 197.32 214.61 183.91 218.18 174.05C217.63 173.57 217.08 173.07 216.56 172.54Z"
          fill="url(#paint174_linear_573_35549)"
        />
        <path
          d="M214.66 170.73C209.74 181.33 207.19 196.19 208.83 218.19C209.52 227.36 210.77 235.55 212.48 242.86C213.17 242.84 213.86 242.82 214.56 242.79C212.83 235.49 211.56 227.27 210.87 218.04C209.27 196.69 211.69 182.35 216.23 172.21C215.95 171.92 215.68 171.63 215.41 171.33C215.15 171.14 214.9 170.94 214.65 170.73"
          fill="url(#paint175_linear_573_35549)"
        />
        <path
          d="M212.99 169.1C212.3 170.13 211.64 171.21 211.01 172.35C210.94 172.49 210.88 172.63 210.81 172.77C205.88 183.37 203.33 198.24 204.97 220.25C205.59 228.58 206.69 236.1 208.16 242.89C208.65 242.89 209.15 242.9 209.65 242.9C209.85 242.9 210.06 242.9 210.26 242.9C208.76 236.09 207.64 228.52 207.01 220.1C205.38 198.3 207.93 183.81 212.66 173.63C213.18 172.51 213.73 171.45 214.3 170.43C213.83 170.01 213.39 169.57 212.98 169.1"
          fill="url(#paint176_linear_573_35549)"
        />
        <path
          d="M211.8 167.57C211.31 168.16 210.83 168.77 210.37 169.4C209.4 170.97 208.5 172.65 207.66 174.46C202.73 185.06 200.18 199.93 201.82 221.94C202.39 229.54 203.35 236.48 204.64 242.79C205.34 242.82 206.04 242.85 206.74 242.87C205.42 236.51 204.44 229.51 203.86 221.79C202.23 199.99 204.78 185.5 209.51 175.33C209.99 174.3 210.49 173.31 211.01 172.37C211.64 171.23 212.3 170.15 212.99 169.12C212.56 168.63 212.17 168.12 211.8 167.59"
          fill="url(#paint177_linear_573_35549)"
        />
        <path
          d="M210.69 165.71L210.63 165.79C210.27 166.21 209.91 166.65 209.56 167.09C208.89 167.94 208.23 168.84 207.6 169.77C206.23 171.8 204.95 174.03 203.8 176.5C202.99 178.24 202.25 180.09 201.58 182.06C198.15 192.16 196.59 205.57 197.97 223.97C198.47 230.65 199.27 236.81 200.33 242.49C201.03 242.55 201.73 242.61 202.44 242.65C201.35 236.9 200.52 230.64 200.01 223.82C198.38 202.02 200.93 187.53 205.66 177.36C207.05 174.37 208.64 171.74 210.37 169.4C210.84 168.77 211.32 168.16 211.8 167.57C211.39 166.98 211.03 166.36 210.69 165.71Z"
          fill="url(#paint178_linear_573_35549)"
        />
        <path
          d="M251.19 183.13C251.19 183.13 250.6 183.2 249.56 183.25C249.49 187.48 249.61 192.03 249.94 196.95C250.75 193.36 251.19 189.72 251.19 186.08V183.13Z"
          fill="url(#paint179_linear_573_35549)"
        />
        <path
          d="M245.75 183.29C245.58 188.44 245.71 194.1 246.18 200.33C246.34 202.54 246.54 204.68 246.77 206.77C247.37 205.34 247.91 203.89 248.4 202.43C248.34 201.69 248.27 200.93 248.22 200.18C247.76 193.99 247.63 188.39 247.8 183.3C247.55 183.3 247.3 183.3 247.03 183.3C246.63 183.3 246.2 183.3 245.76 183.29"
          fill="url(#paint180_linear_573_35549)"
        />
        <path
          d="M242.66 183.14C242.4 188.77 242.5 195.03 243.02 202.01C243.29 205.65 243.65 209.13 244.1 212.46C244.68 211.36 245.23 210.25 245.75 209.13C245.48 206.78 245.25 204.36 245.06 201.86C244.54 194.96 244.45 188.79 244.71 183.26C244.06 183.23 243.38 183.2 242.67 183.14"
          fill="url(#paint181_linear_573_35549)"
        />
        <path
          d="M238.95 182.74C238.53 188.99 238.57 196.06 239.17 204.06C239.54 209.04 240.08 213.74 240.78 218.16C241.35 217.26 241.91 216.36 242.44 215.45C241.93 211.79 241.51 207.95 241.21 203.91C240.62 196.03 240.58 189.1 240.99 182.99C240.33 182.92 239.65 182.84 238.96 182.74"
          fill="url(#paint182_linear_573_35549)"
        />
        <path
          d="M235.71 182.18C235.1 189.01 235.06 196.84 235.74 205.88C236.19 211.83 236.87 217.37 237.77 222.53C238.34 221.75 238.9 220.96 239.45 220.16C238.73 215.65 238.17 210.85 237.79 205.73C237.13 196.86 237.16 189.2 237.74 182.56C237.08 182.45 236.4 182.33 235.72 182.19"
          fill="url(#paint183_linear_573_35549)"
        />
        <path
          d="M232.16 181.31C231.27 188.8 231.11 197.57 231.88 207.92C232.39 214.78 233.23 221.1 234.33 226.9C234.9 226.21 235.47 225.51 236.02 224.81C235.09 219.56 234.37 213.88 233.92 207.77C233.16 197.65 233.31 189.1 234.15 181.83C233.49 181.67 232.82 181.5 232.15 181.31"
          fill="url(#paint184_linear_573_35549)"
        />
        <path
          d="M228.37 180.05C227.13 188.16 226.82 197.87 227.7 209.61C228.29 217.46 229.29 224.59 230.64 231.07C231.22 230.46 231.79 229.84 232.35 229.21C231.17 223.21 230.28 216.64 229.74 209.46C228.88 198.02 229.18 188.6 230.34 180.75C229.69 180.53 229.03 180.3 228.38 180.05"
          fill="url(#paint185_linear_573_35549)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_573_35549"
            x1="-29.71"
            y1="109.92"
            x2="135.46"
            y2="335.23"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_573_35549"
            x1="-29.17"
            y1="109.52"
            x2="136.01"
            y2="334.84"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_573_35549"
            x1="-28.92"
            y1="109.34"
            x2="136.25"
            y2="334.65"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_573_35549"
            x1="-28.59"
            y1="109.09"
            x2="136.58"
            y2="334.41"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear_573_35549"
            x1="-28.26"
            y1="108.85"
            x2="136.92"
            y2="334.17"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint5_linear_573_35549"
            x1="-27.81"
            y1="108.52"
            x2="137.37"
            y2="333.84"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear_573_35549"
            x1="-27.35"
            y1="108.18"
            x2="137.83"
            y2="333.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear_573_35549"
            x1="-26.58"
            y1="107.62"
            x2="138.59"
            y2="332.94"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint8_linear_573_35549"
            x1="-30.33"
            y1="110.37"
            x2="134.85"
            y2="335.69"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint9_linear_573_35549"
            x1="-30.59"
            y1="110.56"
            x2="134.58"
            y2="335.88"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint10_linear_573_35549"
            x1="-30.78"
            y1="110.7"
            x2="134.4"
            y2="336.02"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint11_linear_573_35549"
            x1="-30.87"
            y1="110.76"
            x2="134.31"
            y2="336.08"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint12_linear_573_35549"
            x1="-30.61"
            y1="110.57"
            x2="134.56"
            y2="335.89"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint13_linear_573_35549"
            x1="-30.25"
            y1="110.31"
            x2="134.93"
            y2="335.63"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint14_linear_573_35549"
            x1="-30.0001"
            y1="110.12"
            x2="135.18"
            y2="335.44"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint15_linear_573_35549"
            x1="-29.71"
            y1="109.91"
            x2="135.47"
            y2="335.23"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint16_linear_573_35549"
            x1="-14.8201"
            y1="99"
            x2="150.35"
            y2="324.32"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint17_linear_573_35549"
            x1="-17.58"
            y1="101.02"
            x2="147.6"
            y2="326.34"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint18_linear_573_35549"
            x1="-19.95"
            y1="102.76"
            x2="145.22"
            y2="328.08"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint19_linear_573_35549"
            x1="-22.55"
            y1="104.66"
            x2="142.63"
            y2="329.98"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint20_linear_573_35549"
            x1="-24.89"
            y1="106.38"
            x2="140.28"
            y2="331.7"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint21_linear_573_35549"
            x1="-27.23"
            y1="108.1"
            x2="137.95"
            y2="333.41"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint22_linear_573_35549"
            x1="-28.81"
            y1="109.25"
            x2="136.37"
            y2="334.57"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint23_linear_573_35549"
            x1="-29.53"
            y1="109.78"
            x2="135.65"
            y2="335.1"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint24_linear_573_35549"
            x1="12.28"
            y1="79.13"
            x2="177.45"
            y2="304.45"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint25_linear_573_35549"
            x1="8.99998"
            y1="81.53"
            x2="174.18"
            y2="306.85"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint26_linear_573_35549"
            x1="4.98996"
            y1="84.47"
            x2="170.17"
            y2="309.79"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint27_linear_573_35549"
            x1="0.729966"
            y1="87.6"
            x2="165.91"
            y2="312.91"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint28_linear_573_35549"
            x1="-2.67002"
            y1="90.09"
            x2="162.5"
            y2="315.41"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint29_linear_573_35549"
            x1="-6.19002"
            y1="92.67"
            x2="158.99"
            y2="317.99"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint30_linear_573_35549"
            x1="-8.99003"
            y1="94.72"
            x2="156.19"
            y2="320.04"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint31_linear_573_35549"
            x1="-12.18"
            y1="97.06"
            x2="152.99"
            y2="322.38"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint32_linear_573_35549"
            x1="4.69998"
            y1="84.69"
            x2="169.87"
            y2="310.01"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint33_linear_573_35549"
            x1="2.40997"
            y1="86.37"
            x2="167.58"
            y2="311.69"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint34_linear_573_35549"
            x1="1.67998"
            y1="86.9"
            x2="166.85"
            y2="312.22"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint35_linear_573_35549"
            x1="1.68995"
            y1="86.89"
            x2="166.87"
            y2="312.21"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint36_linear_573_35549"
            x1="2.49996"
            y1="86.3"
            x2="167.68"
            y2="311.61"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint37_linear_573_35549"
            x1="4.26996"
            y1="85.01"
            x2="169.44"
            y2="310.32"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint38_linear_573_35549"
            x1="6.48997"
            y1="83.37"
            x2="171.67"
            y2="308.69"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint39_linear_573_35549"
            x1="10.03"
            y1="80.78"
            x2="175.21"
            y2="306.1"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint40_linear_573_35549"
            x1="19.55"
            y1="73.7999"
            x2="184.73"
            y2="299.12"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint41_linear_573_35549"
            x1="19.31"
            y1="73.98"
            x2="184.49"
            y2="299.3"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint42_linear_573_35549"
            x1="19.09"
            y1="74.14"
            x2="184.26"
            y2="299.46"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint43_linear_573_35549"
            x1="3.88999"
            y1="56.93"
            x2="232.03"
            y2="291.47"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint44_linear_573_35549"
            x1="1.30998"
            y1="59.44"
            x2="229.46"
            y2="293.98"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint45_linear_573_35549"
            x1="-1.07002"
            y1="61.75"
            x2="227.08"
            y2="296.29"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint46_linear_573_35549"
            x1="-3.24001"
            y1="63.87"
            x2="224.9"
            y2="298.41"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint47_linear_573_35549"
            x1="-5.66003"
            y1="66.22"
            x2="222.48"
            y2="300.76"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint48_linear_573_35549"
            x1="-17.7"
            y1="77.93"
            x2="210.45"
            y2="312.47"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint49_linear_573_35549"
            x1="-19.68"
            y1="79.86"
            x2="208.46"
            y2="314.4"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint50_linear_573_35549"
            x1="-21.8"
            y1="81.92"
            x2="206.34"
            y2="316.46"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint51_linear_573_35549"
            x1="1.14997"
            y1="59.59"
            x2="229.29"
            y2="294.14"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint52_linear_573_35549"
            x1="0.84997"
            y1="59.89"
            x2="228.99"
            y2="294.43"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint53_linear_573_35549"
            x1="0.839972"
            y1="59.9"
            x2="228.98"
            y2="294.44"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint54_linear_573_35549"
            x1="0.449967"
            y1="60.28"
            x2="228.59"
            y2="294.82"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint55_linear_573_35549"
            x1="-0.090028"
            y1="60.81"
            x2="228.05"
            y2="295.35"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint56_linear_573_35549"
            x1="-0.840008"
            y1="61.53"
            x2="227.31"
            y2="296.07"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint57_linear_573_35549"
            x1="-1.50002"
            y1="62.18"
            x2="226.64"
            y2="296.72"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint58_linear_573_35549"
            x1="-2.35003"
            y1="63"
            x2="225.79"
            y2="297.54"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint59_linear_573_35549"
            x1="16.76"
            y1="44.41"
            x2="244.9"
            y2="278.95"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint60_linear_573_35549"
            x1="17.51"
            y1="43.69"
            x2="245.65"
            y2="278.23"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint61_linear_573_35549"
            x1="17.73"
            y1="43.47"
            x2="245.87"
            y2="278.01"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint62_linear_573_35549"
            x1="17.51"
            y1="43.69"
            x2="245.65"
            y2="278.23"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint63_linear_573_35549"
            x1="17.1599"
            y1="44.02"
            x2="245.3"
            y2="278.56"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint64_linear_573_35549"
            x1="16.64"
            y1="44.53"
            x2="244.78"
            y2="279.07"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint65_linear_573_35549"
            x1="16.17"
            y1="44.98"
            x2="244.32"
            y2="279.52"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint66_linear_573_35549"
            x1="15.73"
            y1="45.42"
            x2="243.87"
            y2="279.96"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint67_linear_573_35549"
            x1="19.93"
            y1="41.33"
            x2="248.07"
            y2="275.87"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint68_linear_573_35549"
            x1="17.62"
            y1="43.58"
            x2="245.76"
            y2="278.12"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint69_linear_573_35549"
            x1="15.69"
            y1="45.46"
            x2="243.83"
            y2="280"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint70_linear_573_35549"
            x1="13.29"
            y1="47.79"
            x2="241.43"
            y2="282.33"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint71_linear_573_35549"
            x1="11.09"
            y1="49.93"
            x2="239.23"
            y2="284.47"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint72_linear_573_35549"
            x1="8.52997"
            y1="52.42"
            x2="236.67"
            y2="286.96"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint73_linear_573_35549"
            x1="6.30997"
            y1="54.58"
            x2="234.45"
            y2="289.12"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint74_linear_573_35549"
            x1="3.39996"
            y1="57.41"
            x2="231.54"
            y2="291.95"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint75_linear_573_35549"
            x1="15.47"
            y1="45.67"
            x2="243.61"
            y2="280.21"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint76_linear_573_35549"
            x1="15.28"
            y1="45.85"
            x2="243.42"
            y2="280.39"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint77_linear_573_35549"
            x1="18.26"
            y1="42.95"
            x2="246.4"
            y2="277.49"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint78_linear_573_35549"
            x1="30.14"
            y1="31.4"
            x2="258.28"
            y2="265.94"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint79_linear_573_35549"
            x1="28.23"
            y1="33.26"
            x2="256.37"
            y2="267.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint80_linear_573_35549"
            x1="26.05"
            y1="35.38"
            x2="254.19"
            y2="269.92"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint81_linear_573_35549"
            x1="24.23"
            y1="37.15"
            x2="252.37"
            y2="271.69"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint82_linear_573_35549"
            x1="21.99"
            y1="39.32"
            x2="250.13"
            y2="273.86"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint83_linear_573_35549"
            x1="124.63"
            y1="-58.98"
            x2="322.84"
            y2="135.84"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint84_linear_573_35549"
            x1="125.56"
            y1="-59.92"
            x2="323.77"
            y2="134.9"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint85_linear_573_35549"
            x1="126.09"
            y1="-60.47"
            x2="324.3"
            y2="134.36"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint86_linear_573_35549"
            x1="125.55"
            y1="-59.91"
            x2="323.76"
            y2="134.91"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint87_linear_573_35549"
            x1="124.38"
            y1="-58.73"
            x2="322.6"
            y2="136.09"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint88_linear_573_35549"
            x1="122.94"
            y1="-57.26"
            x2="321.15"
            y2="137.57"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint89_linear_573_35549"
            x1="121.83"
            y1="-56.13"
            x2="320.04"
            y2="138.7"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint90_linear_573_35549"
            x1="111.61"
            y1="-45.74"
            x2="309.82"
            y2="149.09"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint91_linear_573_35549"
            x1="113.45"
            y1="-47.6"
            x2="311.66"
            y2="147.22"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint92_linear_573_35549"
            x1="114.96"
            y1="-49.14"
            x2="313.17"
            y2="145.68"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint93_linear_573_35549"
            x1="116.79"
            y1="-51"
            x2="315"
            y2="143.82"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint94_linear_573_35549"
            x1="118.42"
            y1="-52.66"
            x2="316.63"
            y2="142.17"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint95_linear_573_35549"
            x1="120.24"
            y1="-54.51"
            x2="318.45"
            y2="140.31"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint96_linear_573_35549"
            x1="121.73"
            y1="-56.03"
            x2="319.94"
            y2="138.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint97_linear_573_35549"
            x1="123.37"
            y1="-57.69"
            x2="321.58"
            y2="137.13"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint98_linear_573_35549"
            x1="92.28"
            y1="-26.06"
            x2="290.49"
            y2="168.76"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint99_linear_573_35549"
            x1="94.06"
            y1="-27.88"
            x2="292.27"
            y2="166.94"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint100_linear_573_35549"
            x1="95.51"
            y1="-29.35"
            x2="293.72"
            y2="165.47"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint101_linear_573_35549"
            x1="99.56"
            y1="-33.47"
            x2="297.77"
            y2="161.35"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint102_linear_573_35549"
            x1="103.32"
            y1="-37.3"
            x2="301.53"
            y2="157.53"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint103_linear_573_35549"
            x1="106.78"
            y1="-40.82"
            x2="304.99"
            y2="154"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint104_linear_573_35549"
            x1="108.3"
            y1="-42.36"
            x2="306.51"
            y2="152.46"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint105_linear_573_35549"
            x1="110.14"
            y1="-44.24"
            x2="308.35"
            y2="150.58"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint106_linear_573_35549"
            x1="78.21"
            y1="-11.75"
            x2="276.42"
            y2="183.07"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint107_linear_573_35549"
            x1="80.2"
            y1="-13.78"
            x2="278.42"
            y2="181.04"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint108_linear_573_35549"
            x1="81.81"
            y1="-15.42"
            x2="280.02"
            y2="179.41"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint109_linear_573_35549"
            x1="83.73"
            y1="-17.37"
            x2="281.95"
            y2="177.45"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint110_linear_573_35549"
            x1="85.42"
            y1="-19.09"
            x2="283.63"
            y2="175.74"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint111_linear_573_35549"
            x1="87.29"
            y1="-20.99"
            x2="285.5"
            y2="173.83"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint112_linear_573_35549"
            x1="88.81"
            y1="-22.53"
            x2="287.02"
            y2="172.29"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint113_linear_573_35549"
            x1="90.64"
            y1="-24.4"
            x2="288.85"
            y2="170.43"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint114_linear_573_35549"
            x1="60.94"
            y1="5.82002"
            x2="259.15"
            y2="200.64"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint115_linear_573_35549"
            x1="63.42"
            y1="3.30001"
            x2="261.63"
            y2="198.12"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint116_linear_573_35549"
            x1="65.51"
            y1="1.16"
            x2="263.72"
            y2="195.99"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint117_linear_573_35549"
            x1="67.7"
            y1="-1.06"
            x2="265.92"
            y2="193.76"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint118_linear_573_35549"
            x1="69.81"
            y1="-3.19998"
            x2="268.02"
            y2="191.62"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint119_linear_573_35549"
            x1="72.09"
            y1="-5.52999"
            x2="270.31"
            y2="189.29"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint120_linear_573_35549"
            x1="73.93"
            y1="-7.40003"
            x2="272.15"
            y2="187.42"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint121_linear_573_35549"
            x1="76.08"
            y1="-9.59002"
            x2="274.29"
            y2="185.24"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint122_linear_573_35549"
            x1="47.52"
            y1="19.47"
            x2="245.73"
            y2="214.3"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint123_linear_573_35549"
            x1="49.72"
            y1="17.23"
            x2="247.94"
            y2="212.05"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint124_linear_573_35549"
            x1="52.58"
            y1="14.32"
            x2="250.79"
            y2="209.14"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint125_linear_573_35549"
            x1="55.34"
            y1="11.52"
            x2="253.55"
            y2="206.34"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint126_linear_573_35549"
            x1="58.46"
            y1="8.34"
            x2="256.67"
            y2="203.17"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint127_linear_573_35549"
            x1="60.2398"
            y1="6.53001"
            x2="258.45"
            y2="201.36"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint128_linear_573_35549"
            x1="-2.84077"
            y1="48.9995"
            x2="199.61"
            y2="284.481"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint129_linear_573_35549"
            x1="-3.41999"
            y1="49.4994"
            x2="199.031"
            y2="284.979"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint130_linear_573_35549"
            x1="-4.11476"
            y1="50.1254"
            x2="198.297"
            y2="285.591"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint131_linear_573_35549"
            x1="10.3599"
            y1="37.65"
            x2="212.81"
            y2="273.13"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint132_linear_573_35549"
            x1="-2.17002"
            y1="48.4204"
            x2="200.279"
            y2="283.9"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint133_linear_573_35549"
            x1="-2.04986"
            y1="48.3105"
            x2="200.399"
            y2="283.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint134_linear_573_35549"
            x1="-1.87995"
            y1="48.1701"
            x2="200.56"
            y2="283.66"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint135_linear_573_35549"
            x1="-1.30992"
            y1="47.6803"
            x2="201.13"
            y2="283.17"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint136_linear_573_35549"
            x1="-0.280006"
            y1="46.7898"
            x2="202.17"
            y2="282.28"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint137_linear_573_35549"
            x1="-0.440021"
            y1="46.9402"
            x2="202"
            y2="282.42"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint138_linear_573_35549"
            x1="-1.56998"
            y1="47.9099"
            x2="200.87"
            y2="283.39"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint139_linear_573_35549"
            x1="28.3399"
            y1="22.19"
            x2="230.79"
            y2="257.67"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint140_linear_573_35549"
            x1="22.88"
            y1="26.89"
            x2="225.33"
            y2="262.37"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint141_linear_573_35549"
            x1="18.72"
            y1="30.4601"
            x2="221.17"
            y2="265.94"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint142_linear_573_35549"
            x1="14.76"
            y1="33.8701"
            x2="217.21"
            y2="269.35"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint143_linear_573_35549"
            x1="11.1201"
            y1="36.99"
            x2="213.57"
            y2="272.47"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint144_linear_573_35549"
            x1="7.56992"
            y1="40.05"
            x2="210.01"
            y2="275.53"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint145_linear_573_35549"
            x1="2.83995"
            y1="44.1099"
            x2="205.29"
            y2="279.59"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint146_linear_573_35549"
            x1="1.21997"
            y1="45.5102"
            x2="203.67"
            y2="280.99"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint147_linear_573_35549"
            x1="35.4"
            y1="16.12"
            x2="237.85"
            y2="251.6"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint148_linear_573_35549"
            x1="34.95"
            y1="16.5101"
            x2="237.4"
            y2="251.99"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint149_linear_573_35549"
            x1="34.3"
            y1="17.07"
            x2="236.74"
            y2="252.55"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint150_linear_573_35549"
            x1="33.29"
            y1="17.93"
            x2="235.74"
            y2="253.41"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint151_linear_573_35549"
            x1="32.27"
            y1="18.81"
            x2="234.71"
            y2="254.3"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint152_linear_573_35549"
            x1="29.67"
            y1="21.04"
            x2="232.12"
            y2="256.53"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint153_linear_573_35549"
            x1="27.1999"
            y1="23.17"
            x2="229.65"
            y2="258.65"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint154_linear_573_35549"
            x1="23.8399"
            y1="26.06"
            x2="226.29"
            y2="261.54"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint155_linear_573_35549"
            x1="20.9699"
            y1="28.53"
            x2="223.42"
            y2="264.01"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint156_linear_573_35549"
            x1="21.29"
            y1="28.25"
            x2="223.74"
            y2="263.73"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint157_linear_573_35549"
            x1="32.11"
            y1="18.95"
            x2="234.56"
            y2="254.43"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint158_linear_573_35549"
            x1="32.0299"
            y1="19.02"
            x2="234.48"
            y2="254.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint159_linear_573_35549"
            x1="32.24"
            y1="18.83"
            x2="234.69"
            y2="254.32"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint160_linear_573_35549"
            x1="33.2299"
            y1="17.9901"
            x2="235.67"
            y2="253.47"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint161_linear_573_35549"
            x1="34.7399"
            y1="16.69"
            x2="237.19"
            y2="252.17"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint162_linear_573_35549"
            x1="35.55"
            y1="15.99"
            x2="238"
            y2="251.47"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint163_linear_573_35549"
            x1="37.2598"
            y1="14.52"
            x2="239.71"
            y2="250"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint164_linear_573_35549"
            x1="37.43"
            y1="14.37"
            x2="239.88"
            y2="249.86"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint165_linear_573_35549"
            x1="37.2299"
            y1="14.55"
            x2="239.68"
            y2="250.03"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint166_linear_573_35549"
            x1="36.32"
            y1="15.33"
            x2="238.77"
            y2="250.81"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint167_linear_573_35549"
            x1="35.5299"
            y1="16.01"
            x2="237.97"
            y2="251.49"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint168_linear_573_35549"
            x1="34.6899"
            y1="16.7401"
            x2="237.13"
            y2="252.22"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint169_linear_573_35549"
            x1="34.0499"
            y1="17.28"
            x2="236.5"
            y2="252.76"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint170_linear_573_35549"
            x1="33.56"
            y1="17.71"
            x2="236"
            y2="253.19"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint171_linear_573_35549"
            x1="50.8601"
            y1="2.83006"
            x2="253.31"
            y2="238.31"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint172_linear_573_35549"
            x1="48.5502"
            y1="4.82006"
            x2="251"
            y2="240.3"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint173_linear_573_35549"
            x1="46.7899"
            y1="6.33005"
            x2="249.23"
            y2="241.81"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint174_linear_573_35549"
            x1="44.92"
            y1="7.94001"
            x2="247.37"
            y2="243.42"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint175_linear_573_35549"
            x1="43.6801"
            y1="9.00003"
            x2="246.13"
            y2="244.49"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint176_linear_573_35549"
            x1="42.3199"
            y1="10.1701"
            x2="244.76"
            y2="245.66"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint177_linear_573_35549"
            x1="41.31"
            y1="11.04"
            x2="243.76"
            y2="246.52"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint178_linear_573_35549"
            x1="40.2899"
            y1="11.92"
            x2="242.73"
            y2="247.4"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint179_linear_573_35549"
            x1="73.1495"
            y1="-16.3298"
            x2="275.589"
            y2="219.151"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint180_linear_573_35549"
            x1="68.7903"
            y1="-12.5901"
            x2="271.24"
            y2="222.9"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint181_linear_573_35549"
            x1="65.7302"
            y1="-9.96003"
            x2="268.18"
            y2="225.53"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint182_linear_573_35549"
            x1="62.3697"
            y1="-7.05997"
            x2="264.81"
            y2="228.42"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint183_linear_573_35549"
            x1="59.6003"
            y1="-4.68"
            x2="262.05"
            y2="230.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint184_linear_573_35549"
            x1="56.68"
            y1="-2.16996"
            x2="259.12"
            y2="233.31"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
          <linearGradient
            id="paint185_linear_573_35549"
            x1="53.7496"
            y1="0.340007"
            x2="256.2"
            y2="235.82"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#15CC44" />
            <stop offset="0.48" stopColor="#31F2FE" />
            <stop offset="1" stopColor="#4E5ED6" />
          </linearGradient>
        </defs>
      </svg>
    </SvgIcon>
  );
};

export default LogoFullIcon;
