// Holds general utility functions
import numeral from "numeral";
import formatRelative from "date-fns/formatRelative";
import datefnsParseISO from "date-fns/parseISO";
import datefnsParse from "date-fns/parse";
import datefnsFormat from "date-fns/format";
import datefnsFormatISO from "date-fns/formatISO";
import datefnsAdd from "date-fns/add";
import datefnsSub from "date-fns/sub";
import datefnsIsValid from "date-fns/isValid";
import datefnsSubWeeks from "date-fns/subWeeks";
import datefnsSubMonths from "date-fns/subMonths";
import datefnsStartOfDay from "date-fns/startOfDay";
import datefnsStartOfWeek from "date-fns/startOfWeek";
import datefnsStartOfMonth from "date-fns/startOfMonth";
import datefnsFormatInTimeZone from "date-fns-tz/formatInTimeZone";
import datefnsZonedTimeToUtc from "date-fns-tz/zonedTimeToUtc";
import datefnsDifferenceInSeconds from "date-fns/differenceInSeconds";
import datefnsDifferenceInMinutes from "date-fns/differenceInMinutes";
import datefnsUtcToZonedTime from "date-fns-tz/utcToZonedTime";
import datefnsGetTimezoneOffset from "date-fns-tz/getTimezoneOffset";
import { DateRangeEnum } from "~/constants/DateConstants";

// ===== Date / Time Utils =====

// We are trying to avoid importing date-fns directly in components
// so we are wrapping the functions we need here. This is so we can
// avoid accidentally importing the entire library in a component.

export const parse = datefnsParse;
export const libParseISO = datefnsParseISO;
export const format = datefnsFormat;
export const dateAdd = datefnsAdd;
export const dateSub = datefnsSub;
export const dateSubWeeks = datefnsSubWeeks;
export const dateSubMonths = datefnsSubMonths;
export const startOfDay = datefnsStartOfDay;
export const startOfWeek = datefnsStartOfWeek;
export const startOfMonth = datefnsStartOfMonth;
export const formatInTimeZone = datefnsFormatInTimeZone;
export const zonedTimeToUtc = datefnsZonedTimeToUtc;
export const utcToZonedTime = datefnsUtcToZonedTime;
export const differenceInSeconds = datefnsDifferenceInSeconds;
export const differenceInMinutes = datefnsDifferenceInMinutes;
export const getTimezoneOffset = datefnsGetTimezoneOffset;
/**
 * Handle the missing timezone in the ISO date string
 * @param date Date object or ISO date string
 * @returns Date object or undefined
 */
export const parseISO = (date?: Date | string | null): Date | undefined => {
  try {
    if (!date) {
      return;
    }
    if (typeof date === "string") {
      // if the date string does not end with Z or +|-\d\d:\d\d then add Z
      if (!date.endsWith("Z") && !/[+-]\d{2}:\d{2}$/.test(date)) {
        date = date + "Z";
      }

      // const dateStr = date.endsWith("Z") ? date : date + "Z";
      return datefnsParseISO(date);
    }
    return date;
  } catch (e) {
    console.error("Error parsing ISO date", date, e);
    return;
  }
};

/**
 * Get the local timezone of the computer
 * @returns string representing the current timezone
 */
export const getTimeZoneValue = () => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return timezone;
};

/**
 * Formats a number of seconds as a string in the format HH:MM:SS
 * @param value number of seconds
 * @returns string formatted as HH:MM:SS
 */
export const formatNumberForTime = (value: number) => {
  let timeStr = numeral(value).format("00:00:00");
  if (timeStr.startsWith("0:")) {
    timeStr = timeStr.substring(2);
  }
  return timeStr;
};

/**
 * Formats a ISO date string as a string relative to the current date
 * @param isoDate ISO date string
 * @returns string formatted as "x minutes ago"
 */
export const formatISODateToRelative = (isoDate?: Date | string | null) => {
  try {
    if (!isoDate) {
      return;
    }
    const date =
      typeof isoDate === "string"
        ? utcToZonedTime(isoDate, getTimeZoneValue())
        : isoDate;
    if (!date) {
      return;
    }
    return formatRelative(date, new Date());
  } catch (e) {
    console.error("Error formatting date", isoDate, e);
    return;
  }
};

/**
 * Formats a date as a string in the format M/d (e.g. 1/1)
 * @param date Date object
 * @returns string formatted as M/d/yyyy
 */
export const formatDateMonthDay = (date?: Date | string | null) => {
  try {
    if (typeof date === "string") {
      date = parseISO(date);
    }
    if (!date) {
      return;
    }
    return datefnsFormat(date, "M/d");
  } catch (e) {
    console.error("Error formatting date", date, e);
    return;
  }
};

/**
 * Formats a date as a string in the format M/d/yyyy (e.g. 1/1/2021)
 * @param date Date object
 * @returns string formatted as M/d/yyyy
 */
export const formatDateShort = (date?: Date | string | null) => {
  try {
    if (typeof date === "string") {
      date = parseISO(date);
    }
    if (!date) {
      return;
    }
    return datefnsFormat(date, "M/d/yyyy");
  } catch (e) {
    console.error("Error formatting date", date, e);
    return;
  }
};

/**
 * Formats a date as a string in the format M/d/yyyy h:mm a (e.g. 1/1/2021 1:00 PM)
 * @param date Date object
 * @returns string formatted as M/d/yyyy h:mm a
 */
export const formatDateTimeShort = (date?: Date | string | null) => {
  try {
    if (typeof date === "string") {
      date = parseISO(date);
    }
    if (!date) {
      return;
    }
    return datefnsFormat(date, "M/d/yyyy h:mm a");
  } catch (e) {
    console.error("Error formatting date", date, e);
    return;
  }
};

/**
 * Formats the time between two dates as a string in the format HH:MM:SS
 * @param startDate Start date
 * @param endDate End date
 * @returns string formatted as HH:MM:SS
 */
export const formatTimeBetweenDates = (startDate?: Date, endDate?: Date) => {
  try {
    if (!startDate || !endDate) {
      return;
    }
    const seconds = Math.floor(
      (endDate.getTime() - startDate.getTime()) / 1000
    );
    return formatNumberForTime(seconds);
  } catch (e) {
    console.error("Error formatting time between dates", startDate, endDate, e);
    return;
  }
};

export const formatTime = (date?: Date | string | null, short?: boolean) => {
  try {
    if (!date) {
      return;
    }
    if (typeof date === "string") {
      date = parseISO(date);
    }
    if (!date) {
      return;
    }
    if (short) {
      return datefnsFormat(date, "h:mm a");
    }
    return datefnsFormat(date, "hh:mm:ss a");
  } catch (e) {
    console.error("Error formatting time", date, e);
    return;
  }
};

const _padLeftZerosForTime = (numStr: string) => {
  return numStr.padStart(2, "0");
};

export const formatStopwatch = (timeInSeconds: number, withHours?: boolean) => {
  try {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);
    const seconds = timeInSeconds % 60;

    const minutesSeconds = `${_padLeftZerosForTime(
      minutes.toString()
    )}:${_padLeftZerosForTime(seconds.toString())}`;
    if (withHours) {
      return `${_padLeftZerosForTime(hours.toString())}:${minutesSeconds}`;
    }
    return minutesSeconds;
  } catch (e) {
    console.error("Error formatting stopwatch", timeInSeconds, e);
    return;
  }
};

export const getElapsedTimeInSeconds = (
  startDate?: Date | string | null,
  endDate?: Date | string | null
) => {
  try {
    if (!startDate || !endDate) {
      return;
    }
    if (typeof startDate === "string") {
      startDate = parseISO(startDate);
    }
    if (typeof endDate === "string") {
      endDate = parseISO(endDate);
    }
    if (!startDate || !endDate) {
      return;
    }
    const diffInSecs = datefnsDifferenceInSeconds(endDate, startDate);
    return diffInSecs;
  } catch (e) {
    console.error(
      "Error getting elapsed time in seconds",
      startDate,
      endDate,
      e
    );
    return;
  }
};

export const formatElapsedTime = (
  startDate?: Date | string | null,
  endDate?: Date | string | null,
  withHours?: boolean
) => {
  const diffInSecs = getElapsedTimeInSeconds(startDate, endDate);
  if (!diffInSecs) {
    return;
  }
  return formatStopwatch(diffInSecs, withHours);
};

export const getHourOptions = (start: number = 0, stop: number = 24) => {
  let hours = [];
  for (let hour = start; hour < stop; hour++) {
    hours.push(`${hour}:00`);
    hours.push(`${hour}:30`);
  }
  return hours;
};

export const getYearOptions = (start: number, stop?: number) => {
  const currentYear = new Date().getFullYear();
  stop = stop || currentYear;
  let years = [];
  for (let year = stop; year >= start; year--) {
    if (year === currentYear) {
      years.push("Current");
    } else {
      years.push(`${year}`);
    }
  }
  return years;
};

export const getDateRangeFromDateRangeEnum = (dateRangeEnum: DateRangeEnum) => {
  const now = new Date();
  switch (dateRangeEnum) {
    case DateRangeEnum.TODAY:
      return {
        startDate: now,
        endDate: dateAdd(now, { days: 1 }),
      };
    case DateRangeEnum.THIS_WEEK:
      return {
        startDate: startOfWeek(now),
        endDate: dateAdd(now, { days: 1 }),
      };
    case DateRangeEnum.LAST_WEEK:
      return {
        startDate: dateSub(startOfWeek(now), { weeks: 1 }),
        endDate: startOfWeek(now),
      };
    case DateRangeEnum.THIS_MONTH:
      return {
        startDate: startOfMonth(now),
        endDate: dateAdd(now, { days: 1 }),
      };
    case DateRangeEnum.LAST_MONTH:
      return {
        startDate: dateSub(startOfMonth(now), { months: 1 }),
        endDate: startOfWeek(now),
      };
    case DateRangeEnum.MONTH_TO_DATE:
      return {
        startDate: startOfMonth(now),
        endDate: dateAdd(now, { days: 1 }),
      };
    default:
      return;
  }
};

/**
 * Formats a date range enum or date range array as an object with start_date and end_date
 * @param dateRange DateRangeEnum or [Date, Date]
 * @returns object with start_date and end_date
 */
export const formatDateRangeForParams = (
  dateRange?: DateRangeEnum | [Date, Date]
): { startDate?: string; endDate?: string } => {
  if (!dateRange) {
    return {};
  }
  let dateRangeObj;
  if (Array.isArray(dateRange)) {
    dateRangeObj = {
      startDate:
        dateRange[0] instanceof Date ? dateRange[0] : parseISO(dateRange[0]),
      endDate:
        dateRange[1] instanceof Date ? dateRange[1] : parseISO(dateRange[1]),
    };
  } else {
    dateRangeObj = getDateRangeFromDateRangeEnum(dateRange) ?? {};
  }

  const startDate = dateRangeObj.startDate
    ? format(startOfDay(dateRangeObj.startDate), "yyyy-MM-dd")
    : "";
  const endDate = dateRangeObj.endDate
    ? format(startOfDay(dateRangeObj.endDate), "yyyy-MM-dd")
    : "";

  return {
    startDate,
    endDate,
  };
};

export const getDateAsISOString = (date?: Date | string | null) => {
  if (!date) {
    return;
  }

  // If already a Date object, format it
  if (date instanceof Date) {
    return datefnsFormatISO(date);
  }
  // If string, parse it first then format
  if (typeof date === "string") {
    const parsedDate = parseISO(date);
    if (parsedDate && datefnsIsValid(parsedDate)) {
      return datefnsFormatISO(parsedDate);
    }
    // fallback to the Date fn
    return datefnsFormatISO(new Date(date));
  }
  return;
};

/**
 * Checks if a date string has a timezone offset
 * @param dateStr date string
 * @returns true if the date string has a timezone offset or timezone abbreviation
 */
export const dateHasTimezone = (dateStr: string) => {
  return /[+-]\d{2}:\d{2}$/.test(dateStr) || /[A-Z]+$/.test(dateStr);
};

/**
 * Converts a date string to a date object in the timezone from the parameter
 * If the date string does not have a timezone offset or timezone abbreviation,
 * it will be converted to the timezone from the parameter
 * @param dateStr date string
 * @param timezone timezone
 * @returns date object in the timezone from the parameter
 */
export const getTimezoneDateToUTC = (date: string, timezone?: string) => {
  const dateObj = parseISO(date);
  let tzDate = dateObj;
  if (tzDate && !dateHasTimezone(date) && timezone) {
    const offset = getTimezoneOffset(timezone, tzDate);
    tzDate = dateSub(tzDate, { seconds: offset / 1000 });
  }
  return tzDate;
};

/**
 * Formats a date object to a string in the specified timezone
 * @param date Date object
 * @param timezone timezone
 * @returns string formatted as MM/dd/yyyy - hh:mm a (timezone)
 */
export const getTimezoneDateStrFromUTC = (
  date: Date,
  timezone: string = "UTC"
) => {
  const timezoneDateStr = formatInTimeZone(
    date,
    timezone,
    "MM/dd/yyyy - hh:mm a"
  );
  return `${timezoneDateStr} (${timezone})`;
};
