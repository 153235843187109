import { getApiUrl, authenticatedFetch } from "~/utils/ApiUtils";
import { useMutation, useQuery, UseQueryOptions } from "@tanstack/react-query";
import {
  AgentType,
  ScenarioV2Type,
  // CallScriptV2Type,
  KickoutSensitivityType,
  EscalationListType,
  CareLocationType,
} from "~/types/DomainTypes";
import { CallScriptType } from "~/types/DomainTypes";
import type { CallConfigurationsType } from "~/types/CallTypes";
import {
  AgentStatusEnum,
  EscalationLevelEnum,
  ScenarioStatusEnum,
  ScriptStatusEnum,
} from "~/constants/DomainConstants";
import { buildFilterSearchParams } from "~/utils/FilterUtils";
import { ListFilterType, MutationOptionsType } from "~/types/ApiTypes";

// ============== GET AGENTS ==============

export interface GetAgentsFilterType {
  includeKickoutSensitivity?: boolean;
  filterStatus?: AgentStatusEnum[];
  filterDemo?: boolean;
  filterInUse?: "all" | "true" | "false";
}

export interface GetAgentsResponse {
  agents: AgentType[];
  total_count: number;
}

export const getAgents = async (filterObj: GetAgentsFilterType) => {
  const url = `${getApiUrl()}/agents`;
  const searchParams = new URLSearchParams();
  if (filterObj.includeKickoutSensitivity) {
    searchParams.append("include_kickout_sensitivity", "true");
  }
  if (filterObj.filterStatus) {
    const filterStatusStr = filterObj.filterStatus.join(",");
    searchParams.append("status", filterStatusStr);
  }
  if (filterObj.filterDemo) {
    searchParams.append("demo", "true");
  }
  if (filterObj.filterInUse && filterObj.filterInUse !== "all") {
    searchParams.append("in_use", filterObj.filterInUse);
  }
  const urlStr = `${url}?${searchParams.toString()}`;
  const response = await authenticatedFetch(urlStr, {
    method: "GET",
  });
  const agents = (await response.json()) as AgentType[];
  if (!agents) {
    throw new Error("Invalid response from getAgents.");
  }
  return agents;
};

export const useGetAgentListQuery = (
  getAgentsParams?: GetAgentsFilterType,
  options?: Partial<UseQueryOptions<AgentType[]>>
) => {
  return useQuery<AgentType[]>({
    queryKey: ["agents", JSON.stringify(getAgentsParams ?? {})],
    queryFn: () => {
      return getAgents(getAgentsParams ?? {});
    },
    ...options,
  });
};

// ============== GET AGENTS V2 (Paginated) ==============

export interface GetAgentsFilterTypeV2 extends ListFilterType {
  includeKickoutSensitivity?: boolean;
  filterStatus?: AgentStatusEnum[];
  filterDemo?: boolean;
  filterInUse?: "all" | "true" | "false";
  search_term?: string;
}

export interface GetAgentsResponse {
  agents: AgentType[];
  total_count: number;
}

export const getAgentsV2 = async (filterObj: GetAgentsFilterTypeV2) => {
  const url = `${getApiUrl()}/v2/agents`;
  const searchParams = new URLSearchParams();
  if (filterObj.includeKickoutSensitivity) {
    searchParams.append("include_kickout_sensitivity", "true");
  }
  if (filterObj.filterStatus) {
    const filterStatusStr = filterObj.filterStatus.join(",");
    searchParams.append("status", filterStatusStr);
  }
  if (filterObj.filterDemo) {
    searchParams.append("demo", "true");
  }
  if (filterObj.filterInUse && filterObj.filterInUse !== "all") {
    searchParams.append("in_use", filterObj.filterInUse);
  }
  if (filterObj?.limit) {
    searchParams.append("limit", filterObj?.limit.toString());
  }
  if (filterObj?.search_term) {
    searchParams.append("search_term", filterObj?.search_term);
  }
  if (filterObj?.offset) {
    searchParams.append("offset", filterObj?.offset.toString());
  }
  const urlStr = `${url}?${searchParams.toString()}`;
  const response = await authenticatedFetch(urlStr, {
    method: "GET",
  });
  const payload = (await response.json()) as GetAgentsResponse;
  if (!payload) {
    throw new Error("Invalid response from getAgentsV2.");
  }
  return payload;
};

export const useGetAgentPaginatedListQuery = (
  getAgentsParams?: GetAgentsFilterTypeV2,
  options?: Partial<UseQueryOptions<GetAgentsResponse>>
) => {
  return useQuery<GetAgentsResponse>({
    queryKey: ["agents", JSON.stringify(getAgentsParams ?? {})],
    queryFn: () => {
      return getAgentsV2(getAgentsParams ?? {});
    },
    ...options,
  });
};

// ============== GET AGENT BY ID ==============

export const getAgentById = async (agentId: string) => {
  const url = `${getApiUrl()}/agent/${agentId}`;
  const response = await authenticatedFetch(url, {
    method: "GET",
  });
  const agent = (await response.json()) as AgentType;
  if (!agent) {
    throw new Error("Invalid response from getAgentById.");
  }
  return agent;
};

export const useGetAgentQuery = (agentId?: string | null) => {
  return useQuery({
    queryKey: ["agent", agentId],
    queryFn: ({ queryKey }) => {
      return getAgentById(queryKey[1]!);
    },
    enabled: !!agentId,
  });
};

// ============== GET SCENARIOS ==============

interface GetScenarioParamsType {
  filterStatus?: ScenarioStatusEnum[];
}

export const getScenarios = async ({ filterStatus }: GetScenarioParamsType) => {
  const url = `${getApiUrl()}/scenarios_v2`;
  const searchParams = new URLSearchParams();
  if (filterStatus) {
    const filterStatusStr = filterStatus.join(",");
    searchParams.append("filter_status", filterStatusStr);
  }
  const urlStr = `${url}?${searchParams.toString()}`;
  const response = await authenticatedFetch(urlStr, {
    method: "GET",
  });
  const scenarios = (await response.json()) as ScenarioV2Type[];
  if (!scenarios) {
    throw new Error("Invalid response from getScenarios.");
  }
  return scenarios;
};

export const useGetScenarioListQuery = (
  getScenariosParams?: GetScenarioParamsType
) => {
  return useQuery({
    queryKey: ["scenarios", JSON.stringify(getScenariosParams ?? {})],
    queryFn: () => {
      return getScenarios(getScenariosParams ?? {});
    },
  });
};

// ============== GET SCENARIO BY ID ==============

export const getScenarioById = async (scenarioId: string) => {
  const url = `${getApiUrl()}/scenario/${scenarioId}`;
  const response = await authenticatedFetch(url, {
    method: "GET",
  });
  const scenario = (await response.json()) as ScenarioV2Type;
  if (!scenario) {
    throw new Error("Invalid response from getScenarioById.");
  }
  return scenario;
};

export const useGetScenarioQuery = (scenarioId?: string | null) => {
  return useQuery({
    queryKey: ["scenario", scenarioId],
    queryFn: ({ queryKey }) => {
      return getScenarioById(queryKey[1]!);
    },
    enabled: !!scenarioId,
  });
};

// ============== GET KICKOUTS ==============

export const getKickoutSensitivities = async () => {
  const response = await authenticatedFetch(
    `${getApiUrl()}/kickout_sensitivities`
  );
  const kickouts = (await response.json()) as KickoutSensitivityType[];
  if (!kickouts) {
    throw new Error("Invalid response from getKickoutSensitivities.");
  }
  return kickouts;
};

export const useGetKickoutListQuery = () => {
  return useQuery({
    queryKey: ["kickouts"],
    queryFn: getKickoutSensitivities,
  });
};

// ============== GET KICKOUT BY ID ==============

export const getKickoutSensitivityById = async (
  kickoutSensitivityId: string
) => {
  const kickouts = await getKickoutSensitivities();
  const result = (kickouts || []).findLast((kickout) => {
    return kickout.kickout_sensitivity_id === kickoutSensitivityId;
  });
  if (!result) {
    throw new Error("No kickout found.");
  }
  return result;
};

export const useGetKickoutQuery = (kickoutId?: string) => {
  return useQuery({
    queryKey: ["kickout", kickoutId],
    queryFn: ({ queryKey }) => {
      return getKickoutSensitivityById(queryKey[1]!);
    },
    enabled: !!kickoutId,
  });
};

// ============== GET SCRIPTS ==============

interface GetScriptsFilterOptionsType {
  scriptStatuses?: ScriptStatusEnum[];
  withContent?: boolean;
  onlyIgloo?: boolean;
}

export const getScripts = async (
  filterOptions?: GetScriptsFilterOptionsType
) => {
  const url = `${getApiUrl()}/scripts_v2`;
  const searchParams = new URLSearchParams();
  if (filterOptions?.scriptStatuses) {
    searchParams.append("status", filterOptions.scriptStatuses.join(","));
  }
  if (filterOptions?.withContent) {
    searchParams.append("with_content", "true");
  }
  if (filterOptions?.onlyIgloo) {
    searchParams.append("only_igloo", "true");
  }
  const urlStr = `${url}?${searchParams.toString()}`;
  const response = await authenticatedFetch(urlStr, {
    method: "GET",
  });
  const scripts = (await response.json()) as CallScriptType[];
  if (!scripts) {
    throw new Error("Invalid response from getScripts.");
  }
  return scripts;
};

export const useGetScriptListQuery = (
  filterOptions?: GetScriptsFilterOptionsType,
  options?: Partial<UseQueryOptions<CallScriptType[]>>
) => {
  return useQuery({
    queryKey: ["scripts", JSON.stringify(filterOptions)],
    queryFn: () => {
      return getScripts(filterOptions);
    },
    ...options,
  });
};

// ============== GET SCRIPT BY ID ==============

export const getScriptById = async (
  scriptId: string,
  withContent?: boolean
) => {
  const url = `${getApiUrl()}/script/${scriptId}`;
  const searchParams = new URLSearchParams();
  if (withContent) {
    searchParams.append("with_content", "true");
  }
  const urlStr = `${url}?${searchParams.toString()}`;
  const response = await authenticatedFetch(urlStr, {
    method: "GET",
  });
  const script = (await response.json()) as CallScriptType;
  if (!script) {
    throw new Error("Invalid response from getScriptById.");
  }
  return script;
};

export const useGetScriptQuery = (
  scriptId?: string | null,
  withContent?: boolean
) => {
  return useQuery({
    queryKey: ["script", scriptId, withContent],
    queryFn: () => {
      if (!scriptId) {
        return;
      }
      return getScriptById(scriptId, withContent);
    },
    enabled: !!scriptId,
  });
};

// ============== GET CALL CONFIGURATIONS ==============

export const getCallConfigurations = async () => {
  const url = `${getApiUrl()}/calls/configurations`;
  const response = await authenticatedFetch(url, {
    method: "GET",
  });
  const payload = (await response.json()) as CallConfigurationsType;
  if (!payload) {
    throw new Error("Invalid response from getCallConfigurations.");
  }
  return payload;
};

// ============== GET ESCALATIONS  ===============

export interface EscalationListFilterType extends ListFilterType {
  scriptId?: string;
  patientId?: string;
  escalationLevels?: EscalationLevelEnum[];
  patientNameLike?: string;
  customerPatientIdLike?: string;
  careLocationId?: string;
}

export const getEscalationList = async (
  filterObj?: EscalationListFilterType
) => {
  const url = `${getApiUrl()}/calls/escalations`;
  const searchParams = buildFilterSearchParams(filterObj);
  const urlStr = `${url}?${searchParams.toString()}`;
  const response = await authenticatedFetch(urlStr, {
    method: "GET",
  });
  const payload = (await response.json()) as {
    escalations: EscalationListType[];
    total_count: number;
  };
  if (!payload) {
    throw new Error("Invalid response from getEscalations.");
  }
  return payload;
};

export const useGetEscalationListQuery = (
  filterObj?: EscalationListFilterType
) => {
  return useQuery({
    queryKey: ["escalations", JSON.stringify(filterObj ?? {})],
    queryFn: () => {
      return getEscalationList(filterObj ?? {});
    },
  });
};

// ============== GET COMPILED SCRIPTS ==============

export const getCompiledScript = async (
  scriptId?: string,
  patientId?: string,
  agentId?: string
) => {
  const url = `${getApiUrl()}/script/${scriptId}/compiled`;
  const searchParams = new URLSearchParams();
  if (patientId) {
    searchParams.append("patient_id", patientId);
  }
  if (agentId) {
    searchParams.append("agent_id", agentId);
  }
  const urlStr = `${url}?${searchParams.toString()}`;
  const response = await authenticatedFetch(urlStr, {
    method: "GET",
  });
  const { context } = (await response.json()) as { context: string };
  if (!context) {
    throw new Error("Invalid response from getCompiledScript.");
  }
  return context;
};

export const useGetCompiledScriptQuery = (
  scriptId?: string,
  patientId?: string,
  agentId?: string
) => {
  return useQuery({
    queryKey: ["compiledScript", scriptId, patientId, agentId],
    queryFn: () => {
      return getCompiledScript(scriptId, patientId, agentId);
    },
    enabled: !!scriptId && !!patientId && !!agentId,
  });
};

// ============== GET CARE LOCATIONS ==============

export const getCareLocations = async () => {
  const url = `${getApiUrl()}/care-locations`;
  const response = await authenticatedFetch(url, {
    method: "GET",
  });
  const careLocations = (await response.json()) as CareLocationType[];
  if (!careLocations) {
    throw new Error("Invalid response from getCareLocations.");
  }
  return careLocations;
};

export const useGetCareLocationsQuery = (
  options?: Partial<UseQueryOptions<CareLocationType[]>>
) => {
  return useQuery({
    queryKey: ["careLocations"],
    queryFn: () => {
      return getCareLocations();
    },
    ...options,
  });
};

// ============== GET AGENT SEARCH ==============

export interface GetAgentSearchParams {
  queryStrings: string | string[];
  limit?: number;
  offset?: number;
  industries?: string[];
  subsectors?: string[];
  categories?: string[];
  customized_for_user?: boolean;
}

export interface GetAgentSearchResponse {
  agents: AgentType[];
  total_count: number;
}

export const getAgentSearch = async (params: GetAgentSearchParams) => {
  const url = `${getApiUrl()}/agents/search`;

  // Ensure queryStrings is an array
  const queries = Array.isArray(params.queryStrings)
    ? params.queryStrings
    : [params.queryStrings];

  // Aggregate results for all query strings
  const allResults: AgentType[] = [];
  let totalCount = 0;
  for (const queryString of queries) {
    const response = await authenticatedFetch(url, {
      method: "POST",
      body: JSON.stringify({
        query: queryString,
        industries: params.industries,
        subsectors: params.subsectors,
        categories: params.categories,
        limit: params.limit,
        offset: params.offset,
        customized_for_user: params.customized_for_user,
      }),
    });
    const result = (await response.json()) as GetAgentSearchResponse;
    allResults.push(...result.agents);
    totalCount += result.total_count;
  }

  // Remove duplicates based on a unique identifier (e.g., `agent_id`)
  const uniqueResults = Array.from(
    new Map(allResults.map((item) => [item.agent_id, item])).values()
  );

  if (Array.isArray(params.queryStrings)) {
    return {
      agents: uniqueResults,
      total_count: uniqueResults.length,
    };
  }

  return {
    agents: uniqueResults,
    total_count: totalCount,
  };
};

export const useGetAgentSearchQuery = (
  params: GetAgentSearchParams,
  queryOptions?: { retry?: boolean }
) => {
  const queryKey = ["searchAgent", JSON.stringify(params)];

  return useQuery({
    queryKey,
    queryFn: () => {
      return getAgentSearch(params);
    },
    ...queryOptions,
  });
};

// ============== MARKETPLACE USER PROFILE IMAGE UPLOAD ==============

interface CreateAgentParams {
  file: File;
  is_female: boolean;
  name: string;
  voice?: string;
  partner_id?: string;
  agent_id?: string;
  icon_url?: string;
}

export const createAgent = async ({
  file,
  is_female,
  name,
}: CreateAgentParams) => {
  const url = `${getApiUrl()}/agents`;

  const formData = new FormData();
  formData.append("file", file);
  formData.append("is_female", is_female === true ? "1" : "0");
  formData.append("name", name);

  const response = await authenticatedFetch(
    url,
    {
      method: "POST",
      body: formData,
    },
    true
  );
  const agent = (await response.json()) as AgentType;
  if (!agent) {
    throw new Error("Invalid response from createAgent.");
  }
  return agent;
};

export const useCreateAgentMutation = <T extends AgentType>(
  mutationOptions?: MutationOptionsType<T>
) => {
  return useMutation({
    mutationFn: (params: CreateAgentParams) => {
      return createAgent(params);
    },
    onSuccess: (data: AgentType) => {
      if (mutationOptions?.onSuccess) {
        mutationOptions?.onSuccess(data as T);
      }
    },
    onError: (error: any) => {
      if (mutationOptions?.onError) {
        mutationOptions?.onError(error);
      }
    },
  });
};

// ============== CONFIGURE AGENT ==============

interface ConfigureAgentParams {
  script_id: string;
  is_female: boolean;
  partner_id?: string;
  hide_from_partners?: boolean;
}

interface ConfigureAgentResponse {
  agent: AgentType;
}

export const configureAgent = async (params: ConfigureAgentParams) => {
  const url = `${getApiUrl()}/agents/configure`;
  const response = await authenticatedFetch(url, {
    method: "POST",
    body: JSON.stringify(params),
  });
  const agent = (await response.json()) as ConfigureAgentResponse;
  if (!agent) {
    throw new Error("Invalid response from configureAgent.");
  }
  return agent;
};

export const useConfigureAgentMutation = <T extends ConfigureAgentResponse>(
  mutationOptions?: MutationOptionsType<T>
) => {
  return useMutation({
    mutationFn: (params: ConfigureAgentParams) => {
      return configureAgent(params);
    },
    onSuccess: (data: ConfigureAgentResponse) => {
      if (mutationOptions?.onSuccess) {
        mutationOptions?.onSuccess(data as T);
      }
    },
    onError: (error: any) => {
      if (mutationOptions?.onError) {
        mutationOptions?.onError(error);
      }
    },
  });
};

// ============== CREATE AGENT FROM SCRIPT ==============

interface ConfigureAgentFromScriptParams {
  agent_id: string;
  script_id: string;
  partner_id?: string;
  hide_from_partners?: boolean;
}

export const configureAgentFromScript = async (
  params: ConfigureAgentFromScriptParams
) => {
  const url = `${getApiUrl()}/agents/configure`;
  const response = await authenticatedFetch(url, {
    method: "POST",
    body: JSON.stringify(params),
  });
  const result = await response.json();
  if (!result) {
    throw new Error("Invalid response from configureAgentFromScript.");
  }
  return result;
};

export const useConfigureAgentFromScriptMutation = <
  T extends { agent_name: string }
>(
  mutationOptions?: MutationOptionsType<T>
) => {
  return useMutation({
    mutationFn: (params: ConfigureAgentFromScriptParams) => {
      return configureAgentFromScript(params);
    },
    onSuccess: (data: { agent_name: string }) => {
      if (mutationOptions?.onSuccess) {
        mutationOptions?.onSuccess(data as T);
      }
    },
    onError: (error: any) => {
      if (mutationOptions?.onError) {
        mutationOptions?.onError(error);
      }
    },
  });
};
