import { FormOptionType, SectionedFormOptionType } from "~/types/UITypes";
import { enumToList, enumToOptions } from "~/utils/FormUtils";

export enum SnowMSGSessionTypeEnum {
  SESSION_TELEPHONE = "telephone",
  SESSION_WEB_CHAT = "web_chat",
  SESSION_WEB_VOICE = "web_voice",
  SESSION_AUTONOMOUS = "autonomous",
}

// These are saved in the call object itself
export enum CallStatusEnum {
  INITIATED = "call_initiated",
  IN_PROGRESS = "call_in_progress",
  COMPLETED = "call_completed",
  ERRORED = "call_errored",
  // All of the below statuses are deprecated
  CONNECTED = "call_connected",
  FINISHED = "call_finished",
  STARTED = "call_started",
  THIRD_PARTY_INTERRUPT = "call_third_party_interrupt",
  FAILED_TO_CONNECT = "call_failed_to_connect",
  FAILED_WITH_ERROR = "call_failed_with_error_code",
  RESTARTED = "call_restarted",
  DROPPED = "call_dropped",
}

export const CallStatusEnumTerminal = [
  CallStatusEnum.COMPLETED,
  CallStatusEnum.FINISHED,
  CallStatusEnum.DROPPED,
  CallStatusEnum.FAILED_TO_CONNECT,
  CallStatusEnum.FAILED_WITH_ERROR,
  CallStatusEnum.ERRORED,
];

export const CallStatusOptions = enumToOptions<string>(
  CallStatusEnum,
  false,
  5
);

export enum CallMessageAuthorEnum {
  SYSTEM = "system",
  DEBUG = "debug",
  USER = "user",
  TELEMETRY = "telemetry",
  FEEDBACK = "feedback",
  ASSISTANT = "assistant",
  KICKOUT = "kickout",
  SCHEDULE = "schedule",
  ERROR_CHECKER = "error_checker",
  MDR = "mdr",
}

export enum CallAnnotationTypeEnum {
  SYSTEM_PROMPT = "system_prompt",
  DEBUG = "debug",
  TASK = "task",
  VOTE = "vote",
  FEEDBACK = "feedback",
  KICKOUT = "kickout",
  MDR = "mdr",
  SCHEDULE = "schedule",
  ERROR_CHECKER = "error_checker",
  TELEMETRY = "telemetry",
  UNKNOWN = "unknown",
}

export enum CallEndResonEnum {
  UNKNOWN = "unknown",
  USER_ENDED_CALL_REGULAR = "user_ended_call_regular",
  USER_ENDED_CALL_DISCONNECT = "user_ended_call_disconnect",
  SYSTEM_ENDED_CALL_REGULAR = "system_ended_call_regular",
  SYSTEM_ENDED_CALL_EMERGENCY_BEFORE_DOB = "system_ended_call_emergency_before_dob",
  SYSTEM_ENDED_CALL_ERROR = "system_ended_call_error",
  SYSTEM_ENDED_CALL_TIMEOUT = "system_ended_call_timeout",
  SYSTEM_ENDED_CALL_KICKOUT = "system_ended_call_kickout",
  SYSTEM_ENDED_CALL_IDENTITY_VERIFICATION = "system_ended_call_identity_verification",
  SYSTEM_ENDED_CALL_DOB_VERIFICATION = "system_ended_call_dob_verification",
  SYSTEM_ENDED_CALL_VOICEMAIL = "system_ended_call_voicemail",
  SYSTEM_ENDED_CALL_REDTEAM = "system_ended_call_redteam",
  SYSTEM_ENDED_CALL_OTHER_PARTY_DISCONNECT = "system_ended_call_other_party_disconnect",
  SYSTEM_ENDED_CALL_FAILED_TO_CONNECT = "system_ended_call_failed_to_connect",
  SYSTEM_ENDED_CALL_TRANSFER_FALLBACK_INITIATED = "system_ended_call_transfer_fallback_initiated",
  SYSTEM_ENDED_CALL_TRANSFER_FAILED = "system_ended_call_transfer_failed",
}

// These are used for the call status and are different than the ones
// that are saved on the server - it is used for the UI
export enum UICallStatusEnum {
  PENDING = "pending",
  READY = "ready",
  CONNECTING = "connecting",
  ACTIVE = "active",
  ENDED = "ended",
  ERROR = "error",
  AT_CAPACITY = "at_capacity",
  FINISHED = "finished",
}

// These are used for the socket status
export enum SocketStatusEnum {
  ACTIVE = "active",
  PENDING = "pending",
  CONNECTING = "connecting",
  DISCONNECTED = "disconnected",
  CONNECTED = "connected",
  ENDED = "ended",
  ERROR = "error",
  UNSTABLE = "unstable",
  UNREACHABLE = "unreachable",
}

export enum UtteranceStatusEnum {
  UNKNOWN = 0,
  GENERATED = 1,
  IN_FLIGHT = 40,
  FINALIZED_PARTIAL = 99,
  FINALIZED = 100,
}

export const MESSAGE_TAGS: Record<string, Record<string, string>> = {
  main_model: {
    looping: "Looping",
    repetition: "Repetition",
    ignored: "Ignored me",
    med_inaccurate: "Medically inaccurate",
    knowledge_error: "Knowledge error",
    nutrition_inaccurate: "Incorrect nutritional advice",
    rude: "Rude or inappropriate tone",
    ignored_task: "Ignored engine task",
    "data-annotation-a": "Data Annotation A",
    "data-annotation-b": "Data Annotation B",
  },
  tts_noise: {
    ai_stopped_abruptly: "AI stopped abruptly",
    slow_to_respond: "Slow to respond",
  },
  "kickout-engine": {
    "kickout-engine_false_positive": "False Positive",
    "kickout-engine_incorrect_detection": "Incorrect Detection",
    "kickout-engine_incorrect_evaluation": "Incorrect Evaluation",
  },
  "lab-engine": {
    "lab-engine_false_positive": "False Positive",
    "lab-engine_incorrect_lab": "Incorrect Lab Detection",
    "lab-engine_incorrect_value": "Incorrect Value Detection",
    "lab-engine_missing_lab_value": "Missing Lab Value",
  },
  "medication-engine": {
    "medication-engine_false_positive": "False Positive",
    "medication-engine_incorrect_perscription":
      "Incorrect Perscription Detection",
    "medication-engine_incorrect_otc": "Incorrect OTC Detection",
    "medication-engine_incorrect_dosage": "Incorrect Dosage Detection",
  },
  "dob-verification-engine": {
    "dob-verification-engine_false_positive": "False Positive",
    "dob-verification-engine_incorrect_dob": "Incorrect DOB Detection",
  },
  "menu-engine": {
    "menu-engine_false_positive": "False Positive",
    "menu-engine_incorrect_menu": "Incorrect Menu Retrieval",
  },
  "retrieval-engine": {
    "retrieval-engine_false_positive": "False Positive",
    "retrieval-engine_incorrect_perscription": "Incorrect Document Retrieval",
  },
  "preference-engine": {
    "preference-engine_false_positive": "False Positive",
    "preference-engine_incorrect_preference": "Incorrect Snippet Retrieval",
  },
  "simple-retrieval-engine": {
    "simple-retrieval-engine_false_positive": "False Positive",
    "simple-retrieval-engine_incorrect_document":
      "Incorrect Document Retrieval",
  },
  generic_engine: {
    generic_engine_false_positive: "False Positive",
  },
  false_negative: {
    "kickout-engine_false_negative": `False Negative: kickout-engine`,
    "lab-engine_false_negative": `False Negative: lab-engine`,
    "medication-engine_false_negative": `False Negative: medication-engine`,
    "call-end-engine_false_negative": `False Negative: call-end-engine`,
    "nutrition-engine_false_negative": `False Negative: nutrition-engine`,
    "dob-verification-engine_false_negative": `False Negative: dob-verification-engine`,
    "menu-engine_false_negative": `False Negative: menu-engine`,
    "retrieval-engine_false_negative": `False Negative: retrieval-engine`,
    "preference-engine_false_negative": `False Negative: preference-engine`,
    "simple-retrieval-engine_false_negative": `False Negative: simple-retrieval-engine`,
  },
  asr: {
    misheard_me: "Misheard Me",
    heard_someone_else: "Heard Someone Else",
    i_was_talking_to_someone_else: "I Was Talking to Someone Else",
    no_response_to_this: "Never responded to this",
  },
  good: {
    good: "Good",
    // BELOW ARE LEGACY FIELDS
    medically_accurate: "Medically Accurate",
    smart: "Smart",
    empathetic: "Empathetic",
    funny: "Funny",
    good_rapport: "Good Rapport",
    good_symptom_assessment: "Good Symptom Assessment",
    called_me_mr_mrs: "Called Me Mr/Mrs",
  },
  bad: {
    medically_inaccurate: "Medically Inaccurate",
    non_sequitor: "Non Sequitor",
    not_understood: "Awkward wording",
    pronounced_the_words_wrong: "Pronounced the words wrong",
    bossy_judgemental: "Bossy or Judgemental",
    too_many_questions: "Too Many Questions",
    rude: "Rude",
    ignored_me: "Ignored Me",
    did_not_speak: "Did not speak this at all",
    repeated_sentences: "Repeated at least one sentence",
    missed_words: "Missed speaking at least one word",
    weird_artifacts: "Glitchy audio but still understandable",
    ignored_concerns: "Ignored my concerns",
    sentence_repetition: "Sentence Repetition",
    topic_looping: "Topic Looping",
    kickout_detector_error: "Kickout Detector Error",
    kickout_evaluator_error: "Kickout Evaluator Error",
  },
  tags: {
    rag_should_trigger: "RAG Should Trigger",
    drug_engine_question: "I asked a drug engine question",
    menu_rag: "I asked a question about restaurants or cuisine",
    rag_should_not_trigger: "RAG Should Not Trigger",
  },
};

export const MESSAGE_TAGS_OPTIONS_LIST = Object.keys(MESSAGE_TAGS).reduce(
  (acc, key) => {
    acc[key] = Object.keys(MESSAGE_TAGS[key]).reduce((acc2, key2) => {
      acc2.push({
        value: key2,
        label: MESSAGE_TAGS[key][key2],
      });
      return acc2;
    }, [] as FormOptionType<string>[]);
    return acc;
  },
  {} as Record<string, FormOptionType<string>[]>
);

export const MESSAGE_TAG_USER_LIST: SectionedFormOptionType<string>[] = [
  {
    label: "False Negative",
    options: MESSAGE_TAGS_OPTIONS_LIST.false_negative,
  },
  {
    label: "ASR",
    options: MESSAGE_TAGS_OPTIONS_LIST.asr,
  },
];

export const MESSAGE_TAG_ASSISTANT_LIST: SectionedFormOptionType<string>[] = [
  {
    label: "Main Model",
    options: MESSAGE_TAGS_OPTIONS_LIST.main_model,
  },
  {
    label: "TTS / Noise",
    options: MESSAGE_TAGS_OPTIONS_LIST.tts_noise,
  },
];

export const MESSAGE_TAGS_FLAT: Record<string, string> = Object.values(
  MESSAGE_TAGS
).reduce((acc, curr) => {
  return { ...acc, ...curr };
}, {});

export enum ScenarioStatusEnum {
  DEMO = "demo_portal",
}

export enum CallHandoffMessageEnum {
  INTERCEDE = "intercede",
  END = "end",
}

export enum CallAttemptOutcomeEnum {
  CONNECTED = "connected",
  NOT_CONNECTED = "not_connected",
}

export const CallAttemptOutcomeOptions = enumToOptions<string>(
  CallAttemptOutcomeEnum
);

export enum CallDispositionEnum {
  CALL_COMPLETED = "call_completed",
  CALL_HUNG_UP = "call_hung_up",
  NO_ANSWER = "no_answer",
  FAILED_VERIFICATION = "failed_verification",
  IMMEDIATE_HANG_UP = "immediate_hang_up",
  CALL_FAILED = "call_failed",
}

export const CallDispositionOptions = [
  {
    value: CallDispositionEnum.NO_ANSWER,
    label: "No Answer",
    attemptOutcome: CallAttemptOutcomeEnum.NOT_CONNECTED,
  },
  {
    value: CallDispositionEnum.FAILED_VERIFICATION,
    label: "Failed Verification",
    attemptOutcome: CallAttemptOutcomeEnum.NOT_CONNECTED,
  },
  {
    value: CallDispositionEnum.IMMEDIATE_HANG_UP,
    label: "Immediate Hang Up",
    attemptOutcome: CallAttemptOutcomeEnum.NOT_CONNECTED,
  },
  {
    value: CallDispositionEnum.CALL_HUNG_UP,
    label: "Call Hung Up",
    attemptOutcome: CallAttemptOutcomeEnum.CONNECTED,
  },
  {
    value: CallDispositionEnum.CALL_COMPLETED,
    label: "Call Completed",
    attemptOutcome: CallAttemptOutcomeEnum.CONNECTED,
  },
  {
    value: CallDispositionEnum.CALL_FAILED,
    label: "Call Failed",
    attemptOutcome: CallAttemptOutcomeEnum.NOT_CONNECTED,
  },
];

export enum CallProgressEnum {
  ATTEMPTED = "attempted",
  CONNECTED = "connected",
  IDENTITY = "identity",
  COMPLETED = "completed",
  FAILED = "failed",
}

export const CallProgressOptions = [
  {
    value: CallProgressEnum.ATTEMPTED,
    label: "Attempted",
  },
  {
    value: CallProgressEnum.CONNECTED,
    label: "Connected",
  },
  {
    value: CallProgressEnum.IDENTITY,
    label: "Identity Verified",
  },
  {
    value: CallProgressEnum.COMPLETED,
    label: "Completed",
  },
  {
    value: CallProgressEnum.FAILED,
    label: "Failed",
  },
];

export const ALL_CALL_DISPOSITIONS = enumToList(CallDispositionEnum);

export const CALL_CONNECTED_DISPOSITIONS = [
  CallDispositionEnum.CALL_COMPLETED,
  CallDispositionEnum.CALL_HUNG_UP,
  CallDispositionEnum.FAILED_VERIFICATION,
  CallDispositionEnum.IMMEDIATE_HANG_UP,
];

export const CALL_NOT_CONNECTED_DISPOSITIONS = ALL_CALL_DISPOSITIONS.filter(
  (d) => !CALL_CONNECTED_DISPOSITIONS.includes(d)
);

export const CALL_COMPLETED_DISPOSITIONS = [CallDispositionEnum.CALL_COMPLETED];

export const CALL_NOT_COMPLETED_DISPOSITIONS = ALL_CALL_DISPOSITIONS.filter(
  (d) => !CALL_COMPLETED_DISPOSITIONS.includes(d)
);

export const CALL_IDENTITY_VERIFIED_DISPOSITIONS = [
  CallDispositionEnum.CALL_COMPLETED,
  CallDispositionEnum.CALL_HUNG_UP,
];

export const CALL_IDENTITY_NOT_VERIFIED_DISPOSITIONS =
  ALL_CALL_DISPOSITIONS.filter(
    (d) => !CALL_IDENTITY_VERIFIED_DISPOSITIONS.includes(d)
  );

export enum CallDispositionCallOutcomeEnum {
  SUCCESSFUL = "successful",
  TRANSCRIPT_REVIEW_REQUIRED = "transcript_review_required",
  FOLLOW_UP_REQUIRED = "follow_up_required",
  NO_ACTION = "no_action",
  TRANSFERRED = "transferred",
  TRANSFER_FAILED = "transfer_failed",
  PATIENT_HUNG_UP_DURING_TRANSFER = "patient_hung_up_during_transfer",
  SUPERVISED = "supervised",
}

export const CallDispositionCallOutcomeOptions = [
  {
    value: CallDispositionCallOutcomeEnum.SUCCESSFUL,
    label: "Successful",
    severity: "success",
  },
  {
    value: CallDispositionCallOutcomeEnum.TRANSCRIPT_REVIEW_REQUIRED,
    label: "Transcript Review Required",
    severity: "warning",
  },
  {
    value: CallDispositionCallOutcomeEnum.FOLLOW_UP_REQUIRED,
    label: "Follow Up Required",
    severity: "error",
  },
  {
    value: CallDispositionCallOutcomeEnum.NO_ACTION,
    label: "No Action",
    severity: "info",
  },
  {
    value: CallDispositionCallOutcomeEnum.TRANSFERRED,
    label: "Transferred",
    severity: "info",
  },
  {
    value: CallDispositionCallOutcomeEnum.TRANSFER_FAILED,
    label: "Transfer Failed",
    severity: "error",
  },
  {
    value: CallDispositionCallOutcomeEnum.PATIENT_HUNG_UP_DURING_TRANSFER,
    label: "Patient Hung Up During Transfer",
    severity: "error",
  },
  {
    value: CallDispositionCallOutcomeEnum.SUPERVISED,
    label: "Supervised",
    severity: "info",
  },
];

export enum CallInterjectionEnum {
  CLINICAL_ISSUE = "interjection_clinical_issue",
  AUDIO_ISSUE = "interjection_audio_issue",
  LOOPING = "interjection_looping",
  REPITION = "interjection_repetition",
  ESCALATION = "interjection_escalation",
  CALL_ENDED_ABRUPTLY = "interjection_call_ended_abruptly",
  OTHER = "interjection_other",
}

export const CallInterjectionOptions = [
  {
    value: CallInterjectionEnum.CLINICAL_ISSUE,
    label: "Clinical Issue",
  },
  {
    value: CallInterjectionEnum.AUDIO_ISSUE,
    label: "Audio Issue",
  },
  {
    value: CallInterjectionEnum.LOOPING,
    label: "Looping",
  },
  {
    value: CallInterjectionEnum.REPITION,
    label: "Repetition",
  },
  {
    value: CallInterjectionEnum.ESCALATION,
    label: "Escalation",
  },
  {
    value: CallInterjectionEnum.CALL_ENDED_ABRUPTLY,
    label: "Call Ended Abruptly",
  },
  {
    value: CallInterjectionEnum.OTHER,
    label: "Other",
  },
];

export enum CallOutputTypeEnum {
  SCHEDULING = "scheduling",
  EHR_SUMMARY = "ehr_summary",
  RAPPORT_SUMMARY = "rapport_summary",
  INTERJECTION = "interjection",
  NOTES = "notes",
  KICKOUT = "kickout",
  OFFLINE_ERRORS = "offline_errors",
  COLONOSCOPY_MCM_SUMMARY = "colonoscopy_mcm_summary",
  FORM_FILL = "form_fill",
  MDR_DETECTION = "mdr_detection",
}

export enum CallOutreachStatusEnum {
  DRAFT = "draft",
  PENDING = "pending", // for is_supervised
  SCHEDULED = "scheduled",
  STARTED = "started",
  COMPLETED = "completed",
  RETRYING = "retrying",
  FAILED = "failed",
  ARCHIVED = "archived",
}

export enum CallOutreachChannelEnum {
  TEXT = "text",
  CALL = "call",
  EMAIL = "email",
}

export enum CallKickoutColorEnum {
  WHITE = "white",
  BLUE = "blue",
  GREEN = "green",
  YELLOW = "yellow",
  AMBER = "amber",
  ORANGE = "orange",
  PURPLE = "purple",
  RED = "red",
}

export const CallKickoutColorOptions =
  enumToOptions<CallKickoutColorEnum>(CallKickoutColorEnum);

export enum CallChatTypeEnum {
  LIVE = "live_call",
  DETAIL = "call_detail",
  CALL_REVIEW = "call_review",
}

export enum CallDirectionEnum {
  AGENT_RECEIVES_CALL = "agent_receives_call",
  AGENT_INITIATES_CALL = "agent_initiates_call",
}
