import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import ContentContainer from "~/components/common/ContentContainer";
import LogoFullIcon from "~/components/svgs/LogoFullIcon";
import FormInputTextField from "~/components/common/form/FormInputTextField";
import SnowThemeProvider from "~/themes/SnowThemeProvider";
import CssBaseline from "@mui/material/CssBaseline";
import { getLoginRoute } from "~/api/UserApi";
import { HttpError } from "~/utils/ApiUtils";
import Loading from "~/components/common/Loading";
import Typography from "@mui/material/Typography/Typography";
import Box from "@mui/material/Box/Box";
import Button from "@mui/material/Button/Button";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import FormControl from "@mui/material/FormControl/FormControl";
import Checkbox from "@mui/material/Checkbox/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel/FormControlLabel";

interface LoginForm {
  email: string;
  remember_me: boolean;
}

const LOGIN_ERROR_TITLE = "Login Error";
const LOGIN_ERROR_TEXT =
  "An unknown error occurred, please contact your support team for assistance";

interface SigninViewProps {
  rememberMe?: boolean;
}

const SigninView = ({ rememberMe }: SigninViewProps) => {
  const [showLoadingDialog, setShowLoadingDialog] = useState(false);
  const [showResultDialog, setShowResultDialog] = useState(false);
  const [resultTitle, setResultTitle] = useState(LOGIN_ERROR_TITLE);
  const [resultText, setResultText] = useState(LOGIN_ERROR_TEXT);

  const { control, handleSubmit, watch, formState, setValue } =
    useForm<LoginForm>();

  const [partialCurrentValues, setPartialCurrentValues] =
    useState<Record<string, any>>();

  // REMEMBER ME Feature
  // TODO: should remember your email address
  // useEffect(() => {
  //   const loginUrl = localStorage.getItem(LOGIN_URL_KEY);
  //   if (rememberMe && loginUrl && loginUrl !== "") {
  //     window.location.href = loginUrl;
  //   }
  //   if (!rememberMe) {
  //     localStorage.removeItem(LOGIN_URL_KEY);
  //   }
  // }, [rememberMe]);

  useEffect(() => {
    const subscription = watch((data) => {
      setPartialCurrentValues(data);
    });

    return () => subscription.unsubscribe();
  }, [watch, setPartialCurrentValues, setValue]);

  const handleSkip = async () => {
    const emailInput = partialCurrentValues?.email;
    let response = null;
    try {
      response = await getLoginRoute(emailInput);
    } catch (e) {
      const httpError = e as HttpError;
      console.log(
        `Failed to get login route: ${httpError.statusCode} | message: ${httpError.message}`
      );

      setResultTitle(LOGIN_ERROR_TITLE);
      setResultText(LOGIN_ERROR_TEXT);
      setShowResultDialog(true);
      setShowLoadingDialog(false);
      return;
    } finally {
      setShowLoadingDialog(false);
    }

    if (response?.login_url) {
      window.location.href = response.login_url;
      return;
    }

    setResultTitle(LOGIN_ERROR_TITLE);
    setResultText(LOGIN_ERROR_TEXT);
    setShowResultDialog(true);
    setShowLoadingDialog(false);
  };

  const handleSSO = async () => {
    window.location.href = "/sso-login";
    return;
  };

  const handleLaunch = async () => {
    const emailInput = partialCurrentValues?.email;
    setShowLoadingDialog(true);

    let response = null;
    try {
      response = await getLoginRoute(emailInput);
    } catch (e) {
      const httpError = e as HttpError;
      console.log(
        `Failed to get login route: ${httpError.statusCode} | message: ${httpError.message}`
      );

      setResultTitle(LOGIN_ERROR_TITLE);
      setResultText(LOGIN_ERROR_TEXT);
      setShowResultDialog(true);
      setShowLoadingDialog(false);
      return;
    } finally {
      setShowLoadingDialog(false);
    }

    if (response?.login_url) {
      window.location.href = response.login_url;
      return;
    }

    if (response?.sso) {
      window.location.href = "/sso-login";
      return;
    }

    setResultTitle(LOGIN_ERROR_TITLE);
    setResultText(LOGIN_ERROR_TEXT);
    setShowResultDialog(true);
    setShowLoadingDialog(false);
  };

  const resultDialog = (
    <Box>
      <Typography
        sx={{
          width: "100%",
          pb: 3,
          fontSize: 20,
          fontWeight: "bold",
        }}
      >
        {resultTitle}
      </Typography>
      <Typography
        sx={{
          width: "100%",
          pb: 2,
          fontSize: 16,
          textAlign: "center",
        }}
      >
        {resultText}
      </Typography>
    </Box>
  );

  let formActionContent = (
    <Button
      type="submit"
      form={"login-form"}
      variant="contained"
      color="primary"
      disabled={!formState.isValid}
      sx={{
        width: "250px",
        height: 50,
      }}
    >
      Continue
    </Button>
  );

  if (showLoadingDialog) {
    formActionContent = <Loading />;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const skipLink = (
    <Typography
      sx={{
        width: "100%",
        fontSize: 14,
        mt: "10px",
        color: "#7c7c7c",
        textAlign: "right",
        fontWeight: "bold",
      }}
    >
      <span
        onClick={handleSkip}
        style={{
          cursor: "pointer",
          color: "#40ad38",
          // textDecoration: "underline",
        }}
      >
        Login with Password
      </span>
    </Typography>
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const rememberMeCheckbox = (
    <FormControl
      sx={{
        justifyContent: "center",
        width: "100%",
      }}
    >
      <Controller
        render={({ field: { onChange, value } }) => {
          return (
            <FormControlLabel
              control={<Checkbox onChange={onChange} checked={value} />}
              label={
                <Typography variant="body2" sx={{ pt: "2px", ml: "-4px" }}>
                  Remember Me
                </Typography>
              }
            />
          );
        }}
        control={control}
        name={"remember_me"}
        defaultValue={false}
      />
    </FormControl>
  );

  const altActionsBox = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      {/* {rememberMeCheckbox} */}
      {/* skipLink */}
    </Box>
  );

  const loginForm = (
    <Box>
      <form id={"login-form"} onSubmit={handleSubmit(handleLaunch)}>
        <Typography
          sx={{
            width: "100%",
            pb: 2,
            fontSize: 16,
          }}
        >
          Sign in to the Hippocratic AI Portal
        </Typography>
        <FormInputTextField
          label="Email"
          name="email"
          type="email"
          control={control}
          rules={{
            required: "Required",
          }}
          formControlProps={{
            sx: {
              width: "100%",
              mt: 2,
            },
          }}
        />
        {altActionsBox}
        <Box
          sx={{
            mt: 3,
          }}
        >
          {formActionContent}
        </Box>
      </form>
    </Box>
  );

  return (
    <SnowThemeProvider>
      <CssBaseline />
      <ContentContainer
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: "500px",
            maxWidth: "90%",
            flexShrink: 0,
            border: "1px solid #ededed",
            borderRadius: "15px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            backgroundColor: "#FFF",
            py: 3,
            mt: "-60px",
          }}
        >
          <Box
            sx={{
              my: 2,
            }}
          >
            <LogoFullIcon
              sx={{ width: "300px", height: "60px" }}
              aria-label={"Hippocratic AI"}
            />
          </Box>
          <Box
            sx={{
              borderTop: "1px solid #ededed",
              width: "100%",
              height: "1px",
            }}
          />
          <Box
            sx={{
              p: 2,
              pt: 4,
              width: "360px",
              maxWidth: "95%",
              textAlign: "center",
            }}
          >
            {showResultDialog ? resultDialog : loginForm}
            <Box
              sx={{
                height: "2px",
                width: "100%",
                my: 3,
                mx: "auto",
                backgroundColor: "#f2f2f2",
              }}
            />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box
                onClick={handleSSO}
                sx={{
                  backgroundColor: "white",
                  border: "1px solid #d8d8d8",
                  borderRadius: "5px",
                  // width: "160px",
                  width: "250px",
                  py: "10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                  transition: "0.35s",
                  "&:hover": {
                    background: "#0000000d",
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    pr: 1,
                  }}
                >
                  <VpnKeyIcon
                    sx={{
                      // color: "#a0a0a0",
                      color: "black",
                      pl: 1,
                    }}
                  />
                  <Typography
                    sx={{
                      pl: "4px",
                      fontWeight: 500,
                      fontSize: 16,
                      // color: "#8c8c8c",
                      color: "black",
                    }}
                  >
                    SSO
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </ContentContainer>
    </SnowThemeProvider>
  );
};

export default SigninView;
