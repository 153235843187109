import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import { type SxProps } from "@mui/material";
import useIsNarrow from "~/hooks/useIsNarrow";
import { APP_SUB_HEADER_HEIGHT } from "~/constants/UIConstants";

interface ContentContainerProps {
  children: React.ReactNode;
  fullWidth?: boolean;
  noOverflow?: boolean;
  withSubHeader?: boolean;
  sx?: SxProps;
}

const ContentContainer = ({
  children,
  fullWidth,
  withSubHeader,
  noOverflow,
  sx,
}: ContentContainerProps) => {
  const isNarrow = useIsNarrow("sm");
  const fullHeight = withSubHeader
    ? `calc(100% - ${APP_SUB_HEADER_HEIGHT}px)`
    : "100%";
  if (fullWidth) {
    const noOverflowSx = noOverflow
      ? {
          height: fullHeight,
          overflow: "hidden",
          display: "flex",
          px: isNarrow ? 1 : 5,
          pt: isNarrow ? 2 : 5,
          pb: isNarrow ? 1 : 2,
        }
      : {};
    return (
      <Box
        sx={Object.assign(
          {
            maxWidth: "100%",
            flexGrow: 1,
            px: isNarrow ? 1 : 5,
            pt: isNarrow ? 2 : 5,
            pb: isNarrow ? 1 : 2,
            minHeight: fullHeight,
          },
          noOverflowSx,
          sx
        )}
      >
        {children}
      </Box>
    );
  }
  const noOverflowSx = noOverflow
    ? {
        height: fullHeight,
        overflow: "hidden",
        display: "flex",
        px: isNarrow ? 1 : 5,
        pt: isNarrow ? 2 : 5,
        pb: isNarrow ? 1 : 2,
      }
    : {};
  return (
    <Container
      sx={Object.assign(
        {
          flexGrow: 1,
          py: isNarrow ? 2 : 5,
          px: isNarrow ? 1 : 2,
          minHeight: fullHeight,
        },
        noOverflowSx,
        sx
      )}
      maxWidth={"xl"}
    >
      {children}
    </Container>
  );
};

export default ContentContainer;
