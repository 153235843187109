import { type InputBaseComponentProps } from "@mui/material/InputBase";
import { type InputProps } from "@mui/material/Input";
import { type OutlinedInputProps } from "@mui/material/OutlinedInput";
import { type FilledInputProps } from "@mui/material/FilledInput";
import FormControl, { type FormControlProps } from "@mui/material/FormControl";
import TextField, { type TextFieldProps } from "@mui/material/TextField";
import {
  Controller,
  type Control,
  type RegisterOptions,
} from "react-hook-form";
import { ReactNode } from "react";

interface FormInputTextFieldProps {
  name: string;
  control: Control<any>;
  label?: string;
  defaultValue?: string;
  rules?: RegisterOptions;
  disabled?: boolean;
  formControlProps?: FormControlProps;
  autoFocus?: boolean;
  "data-testid"?: string;
  textfieldProps?: Pick<
    TextFieldProps,
    | "inputRef"
    | "size"
    | "variant"
    | "multiline"
    | "rows"
    | "placeholder"
    | "sx"
    | "InputProps"
    | "type"
    | "minRows"
    | "maxRows"
  >;
  InputProps?:
    | Partial<FilledInputProps>
    | Partial<OutlinedInputProps>
    | Partial<InputProps>
    | undefined;
  inputProps?: InputBaseComponentProps | undefined;
  type?: React.HTMLInputTypeAttribute | undefined;
  helperText?: ReactNode | string;
}

const FormInputTextField = ({
  name,
  control,
  label,
  rules,
  autoFocus,
  disabled,
  defaultValue,
  formControlProps,
  textfieldProps,
  InputProps,
  inputProps,
  type,
  helperText,
  "data-testid": dataTestId,
}: FormInputTextFieldProps) => {
  return (
    <FormControl {...formControlProps}>
      <Controller
        rules={rules}
        render={({ field: { onChange, value } }) => (
          <TextField
            type={type}
            inputProps={inputProps}
            InputProps={InputProps}
            autoFocus={autoFocus}
            onChange={onChange}
            helperText={helperText}
            value={value}
            disabled={disabled}
            label={label}
            data-testid={dataTestId}
            {...textfieldProps}
          />
        )}
        control={control}
        name={name}
        defaultValue={defaultValue ?? ""}
      />
    </FormControl>
  );
};

export default FormInputTextField;
