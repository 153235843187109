import { useContext } from "react";
import { authenticatedFetch, getApiUrl, getRootUrl } from "~/utils/ApiUtils";
import {
  UserType,
  CurrentUserDefaultsType,
  PartnerUserType,
} from "~/types/UserTypes";
import { useQuery, useMutation, UseQueryOptions } from "@tanstack/react-query";
import {
  ListFilterType,
  LoginRouteType,
  MutationOptionsType,
} from "~/types/ApiTypes";
import { AuthenticationContext } from "~/AuthenticationProvider";
import {
  UserTypeEnum,
  UserRoleEnum,
  MFATypeEnum,
} from "~/constants/UserConstants";
import {
  CallUIConfigTranscriptSettingsType,
  CallUIConfigType,
} from "~/types/CallTypes";
import { MarketPlaceUserType } from "~/types/MarketplaceTypes";
import { PartnerSummaryType } from "~/types/PartnerTypes";

// ============== PRE-LOGIN ROUTES ===================

export const getLoginRoute = async (email: string) => {
  const url = `${getRootUrl()}/login_route`;
  console.log("getLoginRoute", url);
  const response = await fetch(url, {
    method: "POST",
    body: JSON.stringify({ email }),
  });
  const loginRouteResponse = (await response.json()) as LoginRouteType;
  return loginRouteResponse;
};

export const getSSOLoginRoute = async (domain: string) => {
  const url = `${getRootUrl()}/sso_login_route`;
  console.log("getSSOLoginRoute", url);
  const response = await fetch(url, {
    method: "POST",
    body: JSON.stringify({ domain }),
  });
  const loginRouteResponse = (await response.json()) as LoginRouteType;
  return loginRouteResponse;
};

// ============== GET CURRENT USER ==============

export const getCurrentUser = async () => {
  const url = `${getApiUrl()}/users/me`;
  console.log("Getting current user", url);
  const response = await authenticatedFetch(url, {
    method: "GET",
  });
  const user = (await response.json()) as UserType;
  if (!user) {
    throw new Error("Invalid response from getCurrentUser.");
  }
  return user;
};

export const useGetCurrentUserQuery = () => {
  return useQuery({
    queryKey: ["currentUser"],
    queryFn: () => {
      return getCurrentUser();
    },
  });
};

// ============== UPDATE CURRENT USER ==============

// TODO: These values are strings because they used to be stored in local storage.
// We should update them to be objects instead.
export interface UpdateCurrentUserType {
  given_name?: string;
  family_name?: string;
  phone_number?: string;
  call_settings?: string;
  transcript_settings?: string;
  welcome_letter?: number;
  filter_settings?: Record<string, any>;
  marketplace_profile?: MarketPlaceUserType;
  current_user_id?: string;
}

export const updateCurrentUser = async (updateUser: UpdateCurrentUserType) => {
  const url = `${getApiUrl()}/users/me`;
  const response = await authenticatedFetch(url, {
    method: "PUT",
    body: JSON.stringify(updateUser),
  });
  const updatedUser = (await response.json()) as UserType;
  if (!updatedUser) {
    throw new Error("Invalid response from updateCurrentUser.");
  }
  return updatedUser;
};

interface MutationUpdateCurrentUserType {
  updateUser: UpdateCurrentUserType;
}

export const useUpdateCurrentUserMutation = <T extends UserType>(
  mutationOptions?: MutationOptionsType<T>
) => {
  const { setUser } = useContext(AuthenticationContext);
  return useMutation({
    mutationFn: ({ updateUser }: MutationUpdateCurrentUserType) => {
      return updateCurrentUser(updateUser);
    },
    onSuccess: (data: UserType) => {
      if (mutationOptions?.onSuccess) {
        mutationOptions?.onSuccess(data as T);
      }
      // User calls that manipulate the user should also update the user in the
      // authentication context.
      setUser(data);
    },
  });
};

// ============== GET CURRENT USER PARTNER USERS ==============

export const getCurrentPartnerUsers = async () => {
  const url = `${getApiUrl()}/users/me/users`;
  const response = await authenticatedFetch(url, {
    method: "GET",
  });
  const partnerUsers = (await response.json()) as PartnerUserType[];
  if (!partnerUsers) {
    throw new Error("Invalid response from getCurrentPartnerUsers.");
  }
  return partnerUsers;
};

export const useGetCurrentPartnerUsersQuery = (
  options?: UseQueryOptions<PartnerUserType[]>
) => {
  return useQuery({
    queryKey: ["currentPartnerUsers"],
    queryFn: () => {
      return getCurrentPartnerUsers();
    },
    ...options,
  });
};

// ============== UPDATE CURRENT ACTIVE USER ==============

export const updateCurrentActiveUser = async (activePartnerId: string) => {
  const url = `${getApiUrl()}/users/me/activate_user`;
  const response = await authenticatedFetch(url, {
    method: "PUT",
    body: JSON.stringify({ active_partner_id: activePartnerId }),
  });
  const updatedUser = (await response.json()) as UserType;
  if (!updatedUser) {
    throw new Error("Invalid response from updateCurrentActiveUser.");
  }
  return updatedUser;
};

interface MutationUpdateCurrentActiveUserType {
  activePartnerId: string;
}

export const useUpdateCurrentActiveUserMutation = <T extends UserType>(
  mutationOptions?: MutationOptionsType<T>
) => {
  return useMutation({
    mutationFn: ({ activePartnerId }: MutationUpdateCurrentActiveUserType) => {
      return updateCurrentActiveUser(activePartnerId);
    },
    onSuccess: (data: UserType) => {
      if (mutationOptions?.onSuccess) {
        mutationOptions?.onSuccess(data as T);
      }
    },
    onError: (error: any) => {
      if (mutationOptions?.onError) {
        mutationOptions?.onError(error);
      }
    },
  });
};

// ============== GET CURRENT USER DEFAULTS (local storage) ==============

export const getCurrentUserDefaults = async () => {
  const userDefaults = localStorage.getItem("userDefaults");
  if (!userDefaults) {
    return null;
  }
  return JSON.parse(userDefaults) as CurrentUserDefaultsType;
};

export const useGetCurrentUserDefaultsQuery = () => {
  return useQuery({
    queryKey: ["currentUserDefaults"],
    queryFn: () => {
      return getCurrentUserDefaults();
    },
  });
};

// ============== UPDATE CURRENT USER DEFAULTS (local storage) ==============

export interface UpdateCurrentUserDefaultsType {
  callSettings?: CallUIConfigType | null;
  transcriptSettings?: CallUIConfigTranscriptSettingsType | null;
}

export const updateCurrentUserDefaults = async (
  updateUser: UpdateCurrentUserDefaultsType | null
) => {
  if (!updateUser) {
    localStorage.removeItem("userDefaults");
    return null;
  } else {
    const existingUserDefaultsStr = localStorage.getItem("userDefaults");
    try {
      const existingUserDefaults = existingUserDefaultsStr
        ? JSON.parse(existingUserDefaultsStr)
        : {};
      const newUserDefaults = { ...existingUserDefaults, ...updateUser };
      const userDefaults = JSON.stringify(newUserDefaults);
      localStorage.setItem("userDefaults", userDefaults);
      return newUserDefaults;
    } catch (error) {
      console.error("Error updating user defaults", error);
      return null;
    }
  }
};

interface MutationUpdateCurrentUserDefaultsType {
  updateUserDefaults: UpdateCurrentUserDefaultsType;
}

export const useUpdateCurrentUserDefaultsMutation = <T extends UserType>(
  mutationOptions?: MutationOptionsType<T>
) => {
  return useMutation({
    mutationFn: ({
      updateUserDefaults,
    }: MutationUpdateCurrentUserDefaultsType) => {
      return updateCurrentUserDefaults(updateUserDefaults);
    },
    onSuccess: (data: CurrentUserDefaultsType | null) => {
      if (mutationOptions?.onSuccess) {
        mutationOptions?.onSuccess(data as T);
      }
    },
  });
};

// ============== UPDATE CURRENT USER NDA ==============

export const updateCurrentUserNDA = async (accepted: boolean) => {
  const url = `${getApiUrl()}/users/me/nda`;
  const response = await authenticatedFetch(url, {
    method: "POST",
    body: JSON.stringify({ nda_accepted: accepted }),
  });
  const user = (await response.json()) as UserType;
  if (!user) {
    throw new Error("Invalid response from updateNDA.");
  }
  return user;
};

interface MutationUpdateCurrentUserNDAType {
  accepted: boolean;
}

export const useUpdateCurrentUserNDAMutation = <T extends UserType>(
  mutationOptions?: MutationOptionsType<T>
) => {
  return useMutation({
    mutationFn: ({ accepted }: MutationUpdateCurrentUserNDAType) => {
      return updateCurrentUserNDA(accepted);
    },
    onSuccess: (data: UserType) => {
      if (mutationOptions?.onSuccess) {
        mutationOptions?.onSuccess(data as T);
      }
    },
    onError: (error: any) => {
      if (mutationOptions?.onError) {
        mutationOptions?.onError(error);
      }
    },
  });
};

// ============== GET USER LIST ==============

export interface UserListFilterType extends ListFilterType {
  email_filter?: string;
  is_reviewer?: boolean;
}

export const getUserList = async (filterObj?: UserListFilterType) => {
  const url = `${getApiUrl()}/users`;
  const searchParams = new URLSearchParams();
  if (filterObj?.limit) {
    searchParams.append("limit", filterObj?.limit.toString());
  }
  if (filterObj?.offset) {
    searchParams.append("offset", filterObj?.offset.toString());
  }
  if (filterObj?.email_filter) {
    searchParams.append("email_filter", filterObj?.email_filter);
  }
  if (filterObj?.is_reviewer) {
    searchParams.append("is_reviewer", "true");
  }
  const urlStr = `${url}?${searchParams.toString()}`;
  const response = await authenticatedFetch(urlStr, {
    method: "GET",
  });
  const payload = (await response.json()) as {
    users: UserType[];
    total_count: number;
  };
  if (!payload) {
    throw new Error("Invalid response from getUserList.");
  }
  return payload;
};

export const useGetUserListQuery = (
  filterObj?: UserListFilterType,
  staleTime?: number
) => {
  return useQuery({
    queryKey: ["users", JSON.stringify(filterObj || {})],
    queryFn: () => {
      return getUserList(filterObj);
    },
    staleTime,
  });
};

// ============== GET USER DETAILS ==============

export const getUserDetails = async (userId: string) => {
  const url = `${getApiUrl()}/users/${userId}`;
  const response = await authenticatedFetch(url, {
    method: "GET",
  });
  const user = (await response.json()) as UserType;
  if (!user) {
    throw new Error("Invalid response from getUser.");
  }
  return user;
};

export const useGetUserDetailsQuery = (userId?: string) => {
  return useQuery({
    queryKey: ["userDetails", userId],
    queryFn: ({ queryKey }) => {
      return getUserDetails(queryKey[1]!);
    },
    enabled: !!userId,
  });
};

// ============== GET USER AVAILABLE PARTNERS ==============

export const getUserAvailablePartners = async (userId: string) => {
  const url = `${getApiUrl()}/users/${userId}/partners`;
  const response = await authenticatedFetch(url, {
    method: "GET",
  });
  const partners = (await response.json()) as PartnerSummaryType[];
  if (!partners) {
    throw new Error("Invalid response from getUserAvailablePartners.");
  }
  return partners;
};

export const useGetUserAvailablePartnersQuery = (
  userId?: string | null,
  options?: UseQueryOptions<PartnerSummaryType[]>
) => {
  return useQuery({
    queryKey: ["userAvailablePartners", userId],
    queryFn: () => {
      return getUserAvailablePartners(userId!);
    },
    enabled: !!userId,
    ...options,
  });
};

// ============== GET USER PARTNER USERS ==============

export const getUserPartnerUsers = async (userId: string) => {
  const url = `${getApiUrl()}/users/${userId}/users`;
  const response = await authenticatedFetch(url, {
    method: "GET",
  });
  const users = (await response.json()) as PartnerUserType[];
  if (!users) {
    throw new Error("Invalid response from getUserPartnerUsers.");
  }
  return users;
};

export const useGetUserPartnerUsersQuery = (
  userId?: string | null,
  options?: UseQueryOptions<PartnerUserType[]>
) => {
  return useQuery({
    queryKey: ["userPartnerUsers", userId],
    queryFn: () => {
      return getUserPartnerUsers(userId!);
    },
    enabled: !!userId,
    ...options,
  });
};

// ============== CREATE OR UPDATE USER PARTNER USERS ==============

export interface CreateOrUpdateUserPartnerUsersType {
  userId: string;
  partnerUsers: {
    partner_id: string;
    partner_user_id?: string;
  }[];
}

export interface CreateOrUpdateUserPartnerUsersResponseType {
  deletion_count: number;
  creation_count: number;
}

export const createOrUpdateUserPartnerUsers = async (
  params: CreateOrUpdateUserPartnerUsersType
) => {
  const url = `${getApiUrl()}/users/${params.userId}/users`;
  const response = await authenticatedFetch(url, {
    method: "PUT",
    body: JSON.stringify({ partner_users: params.partnerUsers }),
  });
  const payload =
    (await response.json()) as CreateOrUpdateUserPartnerUsersResponseType;
  if (!payload) {
    throw new Error("Invalid response from createOrUpdateUserPartnerUsers.");
  }
  return payload;
};

export const useCreateOrUpdateUserPartnerUsersMutation = <
  T extends CreateOrUpdateUserPartnerUsersResponseType
>(
  mutationOptions?: MutationOptionsType<T>
) => {
  return useMutation({
    mutationFn: (params: CreateOrUpdateUserPartnerUsersType) => {
      return createOrUpdateUserPartnerUsers(params);
    },
    onSuccess: (data: CreateOrUpdateUserPartnerUsersResponseType) => {
      if (mutationOptions?.onSuccess) {
        mutationOptions?.onSuccess(data as T);
      }
    },
  });
};

// ============== CREATE USER ==============

export interface CreateUserType {
  email: string;
  phone_number: string;
  given_name: string;
  family_name: string;
  partner_id?: string;
  license_number?: string;
  license_type?: string;
  sub?: string;
  user_type?: UserTypeEnum;
  user_role?: UserRoleEnum;
  specialty?: string;
  current_employer?: string;
  mfa_type?: MFATypeEnum;
  is_reviewer?: boolean;
}

export const createUser = async (user: CreateUserType) => {
  const url = `${getApiUrl()}/users`;
  const response = await authenticatedFetch(url, {
    method: "POST",
    body: JSON.stringify(user),
  });
  const newUser = (await response.json()) as UserType;
  if (!newUser) {
    throw new Error("Invalid response from createUser.");
  }
  return newUser;
};

interface MutationCreateUserType {
  user: CreateUserType;
}

export const useCreateUserMutation = <T extends UserType>(
  mutationOptions?: MutationOptionsType<T>
) => {
  return useMutation({
    mutationFn: ({ user }: MutationCreateUserType) => {
      return createUser(user);
    },
    onSuccess: (data: UserType) => {
      if (mutationOptions?.onSuccess) {
        mutationOptions?.onSuccess(data as T);
      }
    },
    onError: (error: any) => {
      if (mutationOptions?.onError) {
        mutationOptions?.onError(error);
      }
    },
  });
};

// ============== UPDATE USER ==============

export interface AdminUpdateUserType {
  given_name?: string;
  family_name?: string;
  phone_number?: string;
  user_type?: UserTypeEnum;
  user_role?: UserRoleEnum;
  specialty?: string;
  current_employer?: string;
  years_experience?: string | number;
  medical_role?: string;
  sub?: string;
  is_reviewer?: boolean;
  has_script_editor?: boolean;
  is_marketplace_user?: boolean;
  sso_saml_conn?: string;
  can_download_transcripts?: boolean;
}

export const adminUpdateUser = async (
  userId: string,
  updateUserObj: AdminUpdateUserType
) => {
  const url = `${getApiUrl()}/users/${userId}`;
  const response = await authenticatedFetch(url, {
    method: "POST",
    body: JSON.stringify(updateUserObj),
  });
  const updatedUser = (await response.json()) as UserType;
  if (!updatedUser) {
    throw new Error("Invalid response from adminUpdateUser.");
  }
  return updatedUser;
};

interface MutationAdminUpdateUserType {
  userId: string;
  updateUserObj: AdminUpdateUserType;
}

export const useAdminUpdateUserMutation = <T extends UserType>(
  mutationOptions?: MutationOptionsType<T>
) => {
  return useMutation({
    mutationFn: ({ userId, updateUserObj }: MutationAdminUpdateUserType) => {
      return adminUpdateUser(userId, updateUserObj);
    },
    onSuccess: (data: UserType) => {
      if (mutationOptions?.onSuccess) {
        mutationOptions?.onSuccess(data as T);
      }
    },
    onError: (error: any) => {
      if (mutationOptions?.onError) {
        mutationOptions?.onError(error);
      }
    },
  });
};

// ============== DELETE USER ==============

export const deleteUser = async (userId: string) => {
  const url = `${getApiUrl()}/users/${userId}`;
  await authenticatedFetch(url, {
    method: "DELETE",
  });
  return true;
};

export const useDeleteUserMutation = (
  mutationOptions?: MutationOptionsType<void>
) => {
  return useMutation({
    mutationFn: (userId: string) => {
      return deleteUser(userId);
    },
    onSuccess: () => {
      if (mutationOptions?.onSuccess) {
        mutationOptions?.onSuccess();
      }
    },
    onError: (error: any) => {
      if (mutationOptions?.onError) {
        mutationOptions?.onError(error);
      }
    },
  });
};

// ============== DELETE ALL USER CALL SETTINGS ==============

export const deleteAllUserCallSettings = async () => {
  const url = `${getApiUrl()}/users/call-settings`;
  await authenticatedFetch(url, {
    method: "DELETE",
  });
  return true;
};

export const useDeleteAllUserCallSettingsMutation = (
  mutationOptions?: MutationOptionsType<void>
) => {
  return useMutation({
    mutationFn: () => {
      return deleteAllUserCallSettings();
    },
    onSuccess: () => {
      if (mutationOptions?.onSuccess) {
        mutationOptions?.onSuccess();
      }
    },
    onError: (error: any) => {
      if (mutationOptions?.onError) {
        mutationOptions?.onError(error);
      }
    },
  });
};

// ============== RESET USER ==============

export const resetUser = async (userId: string) => {
  const url = `${getApiUrl()}/users/${userId}/reset`;
  await authenticatedFetch(url, {
    method: "POST",
  });
  return true;
};

export const useResetUserMutation = (
  mutationOptions?: MutationOptionsType<void>
) => {
  return useMutation({
    mutationFn: (userId: string) => {
      return resetUser(userId);
    },
    onSuccess: () => {
      if (mutationOptions?.onSuccess) {
        mutationOptions?.onSuccess();
      }
    },
    onError: (error: any) => {
      if (mutationOptions?.onError) {
        mutationOptions?.onError(error);
      }
    },
  });
};

// ============== MARKETPLACE USER PROFILE IMAGE UPLOAD ==============

export const updateCurrentUserProfileImage = async (file: File) => {
  const url = `${getApiUrl()}/users/me/profile-image`;

  const formData = new FormData();
  formData.append("file", file);

  const response = await authenticatedFetch(
    url,
    {
      method: "POST",
      body: formData,
    },
    true
  );
  const updatedUser = (await response.json()) as UserType;
  if (!updatedUser) {
    throw new Error("Invalid response from updateCurrentUserProfileImage.");
  }
  return updatedUser;
};

export const useUpdateCurrentUserProfileImageMutation = <T extends UserType>(
  mutationOptions?: MutationOptionsType<T>
) => {
  const { setUser } = useContext(AuthenticationContext);
  return useMutation({
    mutationFn: (file: File) => {
      return updateCurrentUserProfileImage(file);
    },
    onSuccess: (data: UserType) => {
      if (mutationOptions?.onSuccess) {
        mutationOptions?.onSuccess(data as T);
      }
      // User calls that manipulate the user should also update the user in the
      // authentication context.
      setUser(data);
    },
  });
};

// ========================== GET MARKETPLACE USER PROFILE COMPLETION  =======================

interface MarketplaceProfileCompletionResult {
  completed: boolean;
  missing_fields: string[];
}

export const getMarketplaceProfileCompletion = async () => {
  const response = await authenticatedFetch(
    `${getApiUrl()}/marketplace-profile-completion`,
    {
      method: "GET",
    }
  );
  const payload = (await response.json()) as MarketplaceProfileCompletionResult;
  if (!payload) {
    throw new Error("Invalid response from getMarketplaceProfileCompletion.");
  }
  return payload;
};

export const useGetMarketplaceProfileCompletion = () => {
  return useQuery({
    queryKey: ["completed"],
    queryFn: () => {
      return getMarketplaceProfileCompletion();
    },
  });
};

// ============== UPDATE CURRENT USER APP STORE TOS ==============

export const updateCurrentUserAppStoreTOS = async (accepted: boolean) => {
  const url = `${getApiUrl()}/users/me/app-store-tos`;
  const response = await authenticatedFetch(url, {
    method: "POST",
    body: JSON.stringify({ appstore_tos_accepted: accepted }),
  });
  const user = (await response.json()) as UserType;
  if (!user) {
    throw new Error("Invalid response from updateCurrentUserAppStoreTOS.");
  }
  return user;
};

interface MutationUpdateCurrentUserAppStoreTOSType {
  accepted: boolean;
}

export const useUpdateCurrentUserAppStoreTOSMutation = <T extends UserType>(
  mutationOptions?: MutationOptionsType<T>
) => {
  return useMutation({
    mutationFn: ({ accepted }: MutationUpdateCurrentUserAppStoreTOSType) => {
      return updateCurrentUserAppStoreTOS(accepted);
    },
    onSuccess: (data: UserType) => {
      if (mutationOptions?.onSuccess) {
        mutationOptions?.onSuccess(data as T);
      }
    },
    onError: (error: any) => {
      if (mutationOptions?.onError) {
        mutationOptions?.onError(error);
      }
    },
  });
};
