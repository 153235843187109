import useMediaQuery from "@mui/material/useMediaQuery";
import useTheme from "@mui/material/styles/useTheme";

/**
 * Hook to help determine if the screen is currently smaller than the given size
 *
 * xs, extra-small: 0px
 * sm, small: 600px
 * md, medium: 900px
 * lg, large: 1200px
 * xl, extra-large: 1536px
 *
 * @param sizeKey xs | sm | md | lg | xl
 * @returns whether the screen is currently smaller than the given size
 */

const useIsNarrow = (sizeKey: "xs" | "sm" | "md" | "lg" | "xl") => {
  const theme = useTheme();
  const isNarrow = useMediaQuery(theme.breakpoints.down(sizeKey));
  return isNarrow;
};

export default useIsNarrow;
